<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
</div>
<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 py-20 relative -mt-32">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4">
            <ul class="flex list-none flex-wrap flex-row m-0 shadow">
                <li class="p-0">
                    <a class="text-gray-600 py-4 px-2 sm:px-6 block hover:text-brand-gray focus:outline-none cursor-pointer"
                        (click)="toggleTabs(1)"
                        [ngClass]="{'bg-white': openTab !== 1, 'text-brand-gray border-b-2 font-bold border-brand-green': openTab === 1}">Account
                        Setting</a>
                </li>
                <li class="p-0">
                    <a class="text-gray-600 py-4 px-2 sm:px-6 block hover:text-brand-gray focus:outline-none cursor-pointer"
                        (click)="toggleTabs(2)"
                        [ngClass]="{'bg-white': openTab !== 2, 'text-brand-gray border-b-2 font-bold border-brand-green': openTab === 2}">Change
                        Password</a>
                </li>
            </ul>
            <div class="content py-5">
                <div class="tab-content tab-space">
                    <div class="" [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">
                        <div class="">
                            <div *ngIf="showShareopinion">
                                <div class="w-full" x-transition:enter="transition transform ease-out duration-300"
                                    x-transition:enter-start="opacity-0 translate-y-1"
                                    x-transition:enter-end="opacity-100 translate-y-0"
                                    x-transition:leave="transition transform ease-in duration-300"
                                    x-transition:leave-start="opacity-100 translate-y-0"
                                    x-transition:leave-end="opacity-0 translate-y-1">
                                    <div class="bg-blue-300 border-gray-300 border p-3 flex  shadow-lg rounded-md">
                                        <div class="flex-1 space-y-1">
                                            <p class="text-sm text-white">If you are not satisfiled with our portal or
                                                you have a problem with it - <a
                                                    class="cursor-pointer text-black underline"
                                                    (click)="toggleShareOpinionModalModal()">please share your opinion
                                                    with us.</a> Help us to be better for you.</p>
                                        </div>
                                        <svg class="flex-shrink-0 h-5 w-5 text-white cursor-pointer"
                                            (click)=" showShareopinion=false" stroke="currentColor" viewBox="0 0 20 20">
                                            <path stroke-width="1.2"
                                                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <form #accountsettingForm="ngForm" class="w-full">
                                <div class="grid md:grid-cols-2 gap-4">
                                    <div class="space-y-6">
                                        <div>
                                            <label class="" for="grid-userName">Username</label>
                                            <input readonly type="text" #userName="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="userName" name="userName"
                                                [(ngModel)]="accountsettingData.userName">
                                        </div>
                                        <div>
                                            <label class="" for="grid-first-name">First name</label>
                                            <input type="text" #firstName="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your first name so we can be polite when we email you!"
                                                name="firstName" [(ngModel)]="accountsettingData.firstName">
                                        </div>
                                        <div>
                                            <label class="" for="grid-first-name">Last name</label>
                                            <input type="text" #lastName="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your last name so we can be polite when we email you!"
                                                name="lastName" [(ngModel)]="accountsettingData.lastName">
                                        </div>
                                        <div>
                                            <label class="" for="grid-first-name">Business Email <span
                                                    class=" text-red-500 ">*</span></label>
                                            <input type="email" #businessEmail="ngModel" required
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your business e-mail" name="businessEmail"
                                                [(ngModel)]="accountsettingData.businessEmail"
                                                [ngClass]="{'red-border-class': businessEmail.errors && (businessEmail.dirty || businessEmail.touched)}"
                                                [email]="true">
                                            <div
                                                *ngIf="businessEmail.errors && (businessEmail.dirty || businessEmail.touched)">
                                                <div [hidden]="!businessEmail.errors.required"
                                                    class="text-red-500 text-sm">
                                                    Please enter valid Email.
                                                </div>
                                                <div *ngIf="!businessEmail.errors.required">
                                                    <div [hidden]="!businessEmail.invalid && !businessEmail.errors.required"
                                                        class="text-red-500 text-sm">
                                                        Invalid business email.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <label class="" for="grid-first-name">Personal Email</label>
                                            <input type="email" #personalEmail="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your personal e-mail" name="personalEmail"
                                                [(ngModel)]="accountsettingData.personalEmail"
                                                [ngClass]="{'red-border-class': personalEmail.errors && (personalEmail.dirty || personalEmail.touched)}"
                                                [email]="true">

                                            <div
                                                *ngIf="personalEmail.errors && (personalEmail.dirty || personalEmail.touched)">
                                                <div [hidden]="!personalEmail.invalid && !personalEmail.errors.required"
                                                    class="text-red-500 text-sm">
                                                    Invalid email.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="space-y-6">
                                        <div>
                                            <label class="" for="grid-last-name">Job title</label>
                                            <input type="text" #jobTitle="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your job title" name="jobTitle"
                                                [(ngModel)]="accountsettingData.jobTitle">
                                        </div>
                                        <div>
                                            <label class="" for="grid-last-name">Functional area <span
                                                    class=" text-red-500 ">*</span></label>
                                            <div class="relative mt-2">
                                                <select #functionalAreaId="ngModel" aria-placeholder="hghj"
                                                    (change)='onOptionsSelected($event)' required aria-selected="true"
                                                    class="appearance-none border w-full px-4 py-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                    name="functionalAreaId"
                                                    [(ngModel)]="accountsettingData.functionalAreaId"
                                                    [ngClass]="{'red-border-class': functionalAreaId.errors && (functionalAreaId.dirty || functionalAreaId.touched)}">
                                                    <option value="">Choose your Functional Area</option>
                                                    <option *ngFor="let areas of lstFunctionaArea"
                                                        [value]="areas.value"> {{areas.text}} </option>
                                                </select>
                                                <div
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="functionalAreaId.errors && (functionalAreaId.dirty || functionalAreaId.touched)">
                                                <div [hidden]="!functionalAreaId.errors.required"
                                                    class="text-red-500 text-sm">
                                                    Please select Functional Area.
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div *ngIf="showotherFunctionalArea==true" class="form-group">
                                                <label for="Username" class=" block ">Other functional area<span
                                                        class=" text-red-500 ">*</span></label>
                                                <input type="text" #otherFunctionalArea="ngModel" required
                                                    class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-indigo-600 rounded-md"
                                                    placeholder="Your functional area" name="otherFunctionalArea"
                                                    [(ngModel)]="accountsettingData.otherFunctionalArea"
                                                    [ngClass]="{'red-border-class': otherFunctionalArea.errors && (otherFunctionalArea.dirty || otherFunctionalArea.touched)}">
                                                <div
                                                    *ngIf="otherFunctionalArea.errors && (otherFunctionalArea.dirty || otherFunctionalArea.touched)">
                                                    <div [hidden]="!otherFunctionalArea.errors.required"
                                                        class="text-red-500 text-sm">
                                                        Please enter Functional Area description.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label class="" for="grid-last-name">Industry <span
                                                    class=" text-red-500 ">*</span></label>
                                            <div class="relative mt-2">
                                                <select #industryId="ngModel"
                                                    (change)='onOptionsIndustrySelected($event)' required
                                                    aria-selected="true"
                                                    class="appearance-none border w-full px-4 py-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                    name="industryId" [(ngModel)]="accountsettingData.industryId"
                                                    [ngClass]="{'red-border-class': industryId.errors && (industryId.dirty || industryId.touched)}">
                                                    <option value="" class="text-opacity-black-10 " selected>Choose your
                                                        Industry </option>
                                                    <option *ngFor="let industry of lstIndustry"
                                                        [value]="industry.value"> {{industry.text}} </option>
                                                </select>
                                                <div
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div *ngIf="industryId.errors && (industryId.dirty || industryId.touched)">
                                                <div [hidden]="!industryId.errors.required"
                                                    class="text-red-500 text-sm">
                                                    Please select Industry.
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div *ngIf="showotherIndustry==true" class="form-group">
                                                <label for="Username" class=" block ">Other industry<span
                                                        class=" text-red-500 ">*</span></label>
                                                <input type="text" #otherIndustry="ngModel" required
                                                    class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-indigo-600 rounded-md"
                                                    placeholder="Your industry" name="otherIndustry"
                                                    [(ngModel)]="accountsettingData.otherIndustry"
                                                    [ngClass]="{'red-border-class': otherIndustry.errors && (otherIndustry.dirty || otherIndustry.touched)}">
                                                <div
                                                    *ngIf="otherIndustry.errors && (otherIndustry.dirty || otherIndustry.touched)">
                                                    <div [hidden]="!otherIndustry.errors.required"
                                                        class="text-red-500 text-sm">
                                                        Please enter Industry description.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label class="" for="grid-last-name">Country</label>
                                            <input type="text" #country="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your country" name="country"
                                                [(ngModel)]="accountsettingData.country">
                                        </div>
                                        <div>
                                            <label class="" for="grid-last-name">Company name</label>
                                            <input type="text" #companyName="ngModel"
                                                class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                                placeholder="Your company" name="companyName"
                                                [(ngModel)]="accountsettingData.companyName">
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-none md:flex justify-between mt-5 text-center">
                                    <div class="mb-3">
                                        <button
                                            class='modal-open bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center'
                                            *ngIf="!accountsettingData.isDeleteRequested"
                                            (click)="toggleRequestDeleteModal()">Request Delete</button>
                                        <button
                                            class="bg-red-500 hover:bg-red-600 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                                            *ngIf="accountsettingData.isDeleteRequested"
                                            (click)="toggleCancelDeleteRequestModal()">Cancel Account Delete</button>

                                        <button
                                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center ml-3">
                                            <a (click)="setTitle('home')" class="inline-block" routerLink="/home"
                                                routerLinkActive="active">Cancel</a>
                                        </button>
                                    </div>
                                    <button
                                        class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 mb-3 rounded inline-flex items-center "
                                        [disabled]="!accountsettingForm.valid"
                                        [ngClass]="{'disabledbtn': !accountsettingForm.valid }"
                                        (click)="updateAccountData()"> Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}">
                        <div class="max-w-md">
                            <form #changepasswordForm="ngForm" class="w-full">
                                <div class="form-group">
                                    <label class="" for="grid-first-name">Old Password <span
                                            class=" text-red-500 ">*</span></label>
                                    <input type="password" #oldPassword="ngModel" required
                                        class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                        placeholder="Your current password" name="oldPassword"
                                        [(ngModel)]="changePassData.oldPassword"
                                        [ngClass]="{'red-border-class': oldPassword.errors && (oldPassword.dirty || oldPassword.touched)}">
                                    <div *ngIf="oldPassword.errors && (oldPassword.dirty || oldPassword.touched)">
                                        <div [hidden]="!oldPassword.errors.required" class="text-red-500 text-sm -mt-3">
                                            Please enter old password.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="" for="grid-first-name">New Password <span
                                            class=" text-red-500 ">*</span></label>
                                    <input type="password" name="newPassword" #newPassword="ngModel" required
                                        minlength="6" maxlength="100"
                                        class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                        placeholder="Your new password" name="newPassword"
                                        [(ngModel)]="changePassData.newPassword"
                                        [ngClass]="{'red-border-class': newPassword.errors && (newPassword.dirty || newPassword.touched)}">
                                    <div *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched)">
                                        <div [hidden]="!newPassword.errors.required" class="text-red-500 text-sm -mt-3">
                                            Please enter new password.
                                        </div>
                                        <div *ngIf="newPassword.errors?.minlength || newPassword.errors?.maxlength"
                                            class="text-red-500 text-sm -mt-3">Password length must be between 6 and 100
                                            characters.</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="" for="grid-first-name">Re-Password <span
                                            class=" text-red-500 ">*</span></label>
                                    <input appConfirmEqualValidator="newPassword" type="password"
                                        #confirmPassword="ngModel" required minlength="6" maxlength="100"
                                        class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                        placeholder="Repeat new password" name="confirmPassword"
                                        [(ngModel)]="changePassData.confirmPassword"
                                        [ngClass]="{'red-border-class': confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)}">
                                    <div
                                        *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)">
                                        <div [hidden]="!confirmPassword.errors.required"
                                            class="text-red-500 text-sm -mt-3">
                                            Please repeat new password.
                                        </div>

                                        <div *ngIf="confirmPassword.errors?.notEqual" class="text-red-500 -mt-3">
                                            Password and Re-password must match.</div>

                                    </div>
                                </div>
                                <div class="flex justify-between">
                                    <button (click)="toggleTabs(1)" id="btnCancelChangePasswd"
                                        class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center">Cancel</button>
                                    <button
                                        class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                        [disabled]="!changepasswordForm.valid || clicked"
                                        [ngClass]="{'disabledbtn': !changepasswordForm.valid }"
                                        (click)="changePassword();clicked=true"> Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- request-delete-modal-->
<div *ngIf="showRequestDeleteModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-center w-full">
                <p class="text-gray-900 font-medium text-lg ">Warning!</p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    (click)="toggleRequestDeleteModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <div class="my-5 pt-4">
                <form #deleteAccount="ngForm" class="w-full">
                    <div class="">
                        Are you sure you want to delete your Contentreads account? It will be deleted in three days
                        without the possibility to recover it.
                    </div>
                    <div class="flex justify-between mt-5">
                        <button
                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                            (click)="toggleRequestDeleteModal()">Cancel</button>
                        <button
                            class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="confirmDelete()">Request delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showRequestDeleteModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<!-- cancel delete request-->
<div *ngIf="showCancelDeleteRequestModal"
    class="bgblur fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div
        class="border border-blue-500 shadow-lg modal-container bg-white w-4/12 md:max-w-11/12 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
        <div class="modal-content py-4 text-left px-6">
            <!--Title-->
            <div class="flex justify-between items-center pb-10 text-center ">
                <p class="text-2xl text-red-500 text-center mx-auto">Warning!</p>
                <div class="modal-close cursor-pointer z-50" (click)="toggleCancelDeleteRequestModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <hr />
            <form #deleteAccount="ngForm" class="w-full">
                <div class="flex justify-center pb-10 pt-10">
                    <div class="">
                        Are you sure you want to cancel your delete request?
                    </div>
                </div>
                <div class="flex justify-end pt-2 space-x-4">
                    <button class="textblue rounded-lg p-2 border-2 blueborder"
                        (click)="toggleCancelDeleteRequestModal()">Close</button>
                    <button
                        class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                        (click)="confirmCancelDelete()">Cancel Request</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div *ngIf="showCancelDeleteRequestModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<!-- share opinion-->
<div *ngIf="showShareOpinionModal"
    class="bgblur fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div
        class="border border-blue-500 shadow-lg modal-container bg-white w-4/12 md:max-w-11/12 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
        <div class="modal-content py-4 text-left px-6">
            <!--Title-->
            <div class="flex justify-between items-center pb-10 text-center ">
                <p class="text-2xl textblue text-center mx-auto">Need Help!</p>
                <div class="modal-close cursor-pointer z-50" (click)="toggleShareOpinionModalModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <hr />
            <form #shareopinion="ngForm" class="w-full">
                <div class="form-group pt-10 pb-10">
                    <label for="help" class=" block ">How can Contentreads help?</label>
                    <textarea #customText="ngModel" required
                        class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                        placeholder="Please enter your support needs" name="customText"
                        [ngClass]="{'red-border-class': customText.errors && (customText.dirty || customText.touched)}"
                        ngModel></textarea>
                    <div *ngIf="customText.errors && (customText.dirty || customText.touched)">
                        <div [hidden]="!customText.errors.required" class="text-red-500 text-sm">
                            Please enter your support needs
                        </div>
                    </div>
                </div>
                <div class="flex justify-end pt-2 space-x-4 pt-10">
                    <button class="currentColor btnblue p-2 rounded-lg text-white" [disabled]="!shareopinion.valid"
                        [ngClass]="{'disabledbtn': !shareopinion.valid }"
                        (click)="sendRequestHelpInfoByEmail(shareopinion)">Send Email</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div *ngIf="showShareOpinionModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
<app-pagefooter></app-pagefooter>