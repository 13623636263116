import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/seo.service';
import { ApicallService } from 'src/app/service/apicall.service';
@Component({
  selector: 'app-joinindustolutions',
  templateUrl: './joinindustolutions.component.html',
  styleUrls: ['./joinindustolutions.component.scss']
})
export class JoinindustolutionsComponent implements OnInit {
  public constructor(private service:ApicallService,private seo:SeoService) { }
  domainname : any;
  ngOnInit(): void { 
    

  }

}
