import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { analyticsWriteModel } from 'src/app/common/searchparams';
import { ApicallService } from '../service/apicall.service';
import { SeoService } from '../seo.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit 
{
  public apiurl;
  domainname:any;
  userIp: any;
  hostname :string;
  public isLoggedIn;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname:string;
  public aboutContent:string;
  constructor(private seo:SeoService,private service:ApicallService) { }
  ngOnInit(): void {
    this.apiurl= environment.apiURL;
    this.domainname = this.service.getHostname();
    this.hostname= this.domainname;    

    if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
    else
      this.isLoggedIn=false;
      this.service.getUserIP().subscribe(data =>{      
        this.userIp = data['ip'];
        if(this.isLoggedIn)
        {
          this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
          this.analyticsWriteParam.stateUrl=this.componentname;
          this.analyticsWriteParam.stateName=this.componentname;
          this.analyticsWriteParam.domain=this.domainname;
          this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp,this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
          .subscribe(data=> { },error =>{ }); 
        } 
      });
      this.service.getAboutusContent(this.domainname.split('.',1)).subscribe(data =>{  
        this.aboutContent=data;
      });
         
       
  }
}
