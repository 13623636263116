import { Component, OnInit , ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser'; 
import { Router } from '@angular/router';
import { FunctionalArea } from '../models/functionalarea';
import { IndustryModel } from '../models/industrymodel';
import { AccountSettingModel, ChangePasswordModel, ShareInfoModel } from '../models/usermodel';
import { ApicallService } from '../service/apicall.service';
import { ToastrService ,ToastContainerDirective} from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { environment } from 'src/environments/environment';
import { analyticsWriteModel } from '../common/searchparams';
import { SeoService } from '../seo.service';
@Component({
  selector: 'app-accountsettings',
  templateUrl: './accountsettings.component.html',
  styleUrls: ['./accountsettings.component.scss']
})
export class AccountsettingsComponent implements OnInit {
  public changePassData = new ChangePasswordModel();
  public accountsettingData = new AccountSettingModel();
  public shareInfo = new ShareInfoModel();
  lstFunctionaArea : FunctionalArea[];
  lstIndustry : IndustryModel[];  
  domainname :any;
  userIp :any;
  showShareopinion:boolean=false;  
  public isLoggedIn;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname:string;
  clicked=false;
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  constructor(private titleService: Title, private service:ApicallService,private router:Router, private toastr:ToastrService,private seo:SeoService) { } 

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit(): void 
  {   
    this.domainname = this.service.getHostname(); 
    
    if(sessionStorage.getItem('sessionuserId')!=null)
    {
	    this.isLoggedIn=true;
    }
    else
    {
	    this.isLoggedIn=false;
      this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]); 
    } 
    //get details of logged user account    
    this.service.getAccountDetails(sessionStorage.getItem('sessionuserId'))
    .subscribe(data =>
    {          
      this.lstFunctionaArea = data['functionalAreas'];
      this.lstIndustry = data['industries'];  
      this.accountsettingData=data;
      this.changePassData=data;
      
      if(data['industryId'] == null)          {  
        this.showotherIndustry=true;
        this.accountsettingData.industryId='Other';
        this.changePassData.industryId='Other';
      }
      else
      {
        this.showotherIndustry=false;
      }
      if(data['functionalAreaId']==null)
      {                                        
        this.showotherFunctionalArea=true;
        this.accountsettingData.functionalAreaId='Other';
        this.changePassData.functionalAreaId='Other';
      }
      else
      {
        this.showotherFunctionalArea=false;
      } 
    }
    ,error => {  
      this.router.navigate(['/login']) ;   
    });   
    this.service.getUserIP().subscribe(data =>{      
      this.userIp = data['ip'];
      if(this.isLoggedIn)
      {         
        this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
        this.analyticsWriteParam.stateUrl=this.componentname;
        this.analyticsWriteParam.stateName=this.componentname;
        this.analyticsWriteParam.domain=this.domainname;
        this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp, this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
        .subscribe(data=> { },error =>{ }); 
      }
    });
     
  }

  openTab = 1;
  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

  showRequestDeleteModal = false;
  toggleRequestDeleteModal(){
    this.showRequestDeleteModal = !this.showRequestDeleteModal;
  }  

  showCancelDeleteRequestModal = false;
  toggleCancelDeleteRequestModal(){
    this.showCancelDeleteRequestModal = !this.showCancelDeleteRequestModal;
  }

  showShareOpinionModal = false;
  toggleShareOpinionModalModal(){
    this.showShareOpinionModal = !this.showShareOpinionModal;
  }

  changePassword()
  {    
    this.changePassData.domain= this.domainname;
    this.changePassData.userid= sessionStorage.getItem('sessionuserId')??"";  
    if(this.changePassData.functionalAreaId?.toLowerCase()=="other")
      this.changePassData.functionalAreaId=null;
    if(this.changePassData.industryId?.toLowerCase()=="other")
      this.changePassData.industryId=null;
      //change password
      this.service.changePassword(this.changePassData)
      .subscribe(data =>{          
        this.toastr.success("Password updated successfully. Please login again","Success",{timeOut:3000, progressBar:true});   
        this.router.navigate(['/login']) ;//On change password redirect to login page
      },
      error => {
        this.clicked=false;
        if(error.error.length>0){
          this.toastr.error(error.error[0],"Error",{timeOut:3000, progressBar:true});
        }
        else if(error.error.message!="undefined"){
          this.toastr.error(error.error.message,"Error",{timeOut:3000, progressBar:true});
        }
        else{
          this.router.navigate(['/login']) ;
        }
      }); 
  }

  updateAccountData()
  {
    this.accountsettingData.domain= this.domainname;
    if(this.accountsettingData.functionalAreaId?.toLowerCase()=="other")
      this.accountsettingData.functionalAreaId=null;
    if(this.accountsettingData.industryId?.toLowerCase()=="other")
      this.accountsettingData.industryId=null;
    this.accountsettingData.fullName=this.accountsettingData.firstName+" "+this.accountsettingData.lastName;
    this.accountsettingData.userid= sessionStorage.getItem('sessionuserId')??""; 
    //update account details
    this.service.updateAccountDetails(this.accountsettingData)
    .subscribe(data =>{   
      this.toastr.success("Account updated successfully","Success",{timeOut:3000, progressBar:true});   
      setTimeout(function(){
        window.location.reload();
      }, 5000);
    },
    error => {  
      this.toastr.error("Business Email/Personal Email address already taken","Error",{timeOut:3000, progressBar:true});    
    });  
  }

  showotherIndustry = false;  
  onOptionsIndustrySelected(event)
  {
    let value = event.target.value;
    if(value.toLowerCase( )=="other")
    {
      this.showotherIndustry = true;
    }
   else
   {
    this.showotherIndustry = false;
   }
}

showotherFunctionalArea = false;  
onOptionsSelected(event)
{
  let value = event.target.value;
  if(value.toLowerCase( )=="other")
  {
    this.showotherFunctionalArea = true;
  }
  else
  {
  this.showotherFunctionalArea = false;
  }
}

confirmDelete()
{ 
  //api call to delete account
  this.service.confirmDeleteRequest(sessionStorage.getItem('sessionuserId'),this.domainname)
  .subscribe(data =>
    {         
      this.showShareopinion=true;       
      this.showRequestDeleteModal = false;
      this.toastr.warning("Request was created. Your account will be deleted in three days.","",{timeOut:3000,
      progressBar:true});
      setTimeout(function(){
        window.location.reload();
      }, 5000);
    },
    error => {              
      this.showRequestDeleteModal = false;
      window.location.href='/login;redirectUri='+location.pathname.substr(1);  
    });          
}

confirmCancelDelete()
{
  //api call to cancel delete account request
  this.service.cancelDeleteAccountRequest(sessionStorage.getItem('sessionuserId'),this.domainname)
  .subscribe(
    data =>{  
      this.showCancelDeleteRequestModal = false;           
      this.toastr.success("Request delete cancelled","Success",{timeOut:3000, progressBar:true});  
      setTimeout(function(){
        window.location.reload();
      }, 5000);
    },
  error => {   
    this.showCancelDeleteRequestModal = false;
    this.router.navigate(['/login']) 
  }); 
}   

sendRequestHelpInfoByEmail(shareopinion:NgForm)
{ 
  this.shareInfo.customText=shareopinion.value.customText;
  this.shareInfo.emailTo=environment.emailTo;
  this.shareInfo.isHelpMessage=true;
  this.shareInfo.sourcePath="/accountSettings/details";
  this.shareInfo.subject="Need Help?";
  this.shareInfo.userid= sessionStorage.getItem('sessionuserId')??"";
  this.service.sendRequestHelpInfoByEmail(this.shareInfo)
  .subscribe
  (data =>{   
    this.showShareOpinionModal = false;     
    this.toastr.success("Sent email successfully","Success",{timeOut:3000, progressBar:true});  
  },
  error => {  
    this.showShareOpinionModal = false;    
    this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);  
  });         
}
}
