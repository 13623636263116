import { Component, OnInit,Input  } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ApicallService } from '../service/apicall.service';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { addCsToMyIndustolutionsModel, analyticsWriteDownloadCaseStudyModel, analyticsWriteModel, CaseStudyViewSource, downloadCaseStudyModel, pdfDownloadModel } from '../common/searchparams';
import { PersonalSolutionModel } from '../models/solutionsetsmodel';
import { Meta,MetaDefinition ,Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import { FuncsService } from '../service/funcs.service';

@Component({
  selector: 'app-casestudy',
  templateUrl: './casestudy.component.html',
  styleUrls: ['./casestudy.component.scss']
})
export class CasestudyComponent implements OnInit {
public param;
public casedata;
public relatedCaseStudiesLength;
public humanReadableId;
public titleNormalized;
public isLoggedIn;
public blobStorageUrl:string;
domainname :any;
userIp :any;
microSiteContent:any;
solutionSetList : any;
public personalSolutionData = new PersonalSolutionModel();
public pdfDownloadparam = new pdfDownloadModel();
public analyticsWriteParam = new analyticsWriteModel();
public analyticsWriteDownloadCaseStudyParam = new analyticsWriteDownloadCaseStudyModel;
public componentname:string;
videourl:any;
pdfLink;
pdfSrc;
public cookieTimer;
public formToken;
public tokenCookieName = "downloadToken";
public attempts = 60;
public parts;
public mainform ;
public mainformpdf;
public form ;
public formpdf;
public casedetailsurl;
public casedetailimage;
public casedetailtitle;
public casedetaildescription;
public token:string;
//for case study download 
public caseStudyParam = new downloadCaseStudyModel();  
public addCsToMyIndustolutionsParam = new addCsToMyIndustolutionsModel

@Input() childMessage: string;
  public constructor(private metaService:Meta, private titleService:Title,private route:ActivatedRoute,private router:Router,private service:ApicallService,private _location: Location,private toastr:ToastrService ,private seo:SeoService, public funcs: FuncsService) { 
    this.titleService.setTitle("Case Study");
  }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 4,"infinite": false};
  ngOnInit(): void {
    
    this.route.paramMap.subscribe(data=>{       
      this.param = data.get('title');  
      });

      if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;   
      this.domainname = this.service.getHostname();    
      this.token="";
      this.route.queryParams
      .subscribe(params => {
          this.token=params.token; 
        }
      ); 
     
      this.casedetailsurl= this.service.getHostNameWithProtocol()+"/casestudy/"+this.param;
      // for manage Case Study Details page content
      this.service.getMicrositeContents("Case Study Details",this.domainname)
      .subscribe(data=> { 
        this.microSiteContent=data;   
        }
        ,error =>{ });  

       // GetCaseStudyView         
       let paramArray =  this.param.split("_");   
       this.humanReadableId=paramArray[1];
       this.service.getCaseStudyView(paramArray[1],this.domainname,sessionStorage.getItem('sessionuserId')??null)  //humanReadableId
       .subscribe(data => {  
         
       this.casedata = data; 
       this.blobStorageUrl = data['blobStorageUrl'];
       this.relatedCaseStudiesLength= data['relatedCaseStudies'].length;  
       this.titleService.setTitle(data['simpleTitle']);    
       this.casedetailimage = data['imageUrl']
       this.casedetailtitle = data['simpleTitle'];   
       this.casedetaildescription = data['contentIndexed'];  
      
        this.seo.generateTags({
        seoDescription:this.casedetailtitle,
        seoKeyword:this.casedetailtitle,
        url: this.casedetailsurl,
        title: this.casedetailtitle.slice(0,75), 
        description: this.casedetaildescription.slice(0,200) ,
        image: this.casedetailimage
      });


       }
       ,(error) => { 
        this.toastr.error("Invalid Case Study","",{timeOut:3000,progressBar:true});                        
             
      });    
      this.service.getUserIP().subscribe(data =>{      
        this.userIp = data['ip']; 
        if(this.isLoggedIn)
        {
          this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
          this.analyticsWriteParam.stateUrl=this.componentname;
          this.analyticsWriteParam.stateName=this.componentname;
          this.analyticsWriteParam.domain=this.domainname;
          this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp,this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
          .subscribe(data=> { },error =>{ }); 
       
        }
      });
      
      // dropdown list (personal solution set)
       this.loadSolutionSets();
  }

  showJoinContentreeModal = false;
  toggleJoinContentreeModal(){
    this.showJoinContentreeModal = !this.showJoinContentreeModal;
  }
  openRegister()
  {
    window.location.href='/register?redirectUri='+location.pathname.substr(1).replace("/","||") ; 
  }
  goBack()
  {
    this._location.back();
  }

  openCaseStudy(caseStudyId)
  { 
      //api to get humanReadableId and titleNormalized   
      this.service.getCaseStudyUrlParts(caseStudyId)    
      .subscribe(data => {
                this.humanReadableId = data['humanReadableId'];
                this.titleNormalized =  data['titleNormalized'];
          
                //checkExistence      
                this.service.checkExistence(this.humanReadableId) 
                .subscribe(data => {
                  this.router.navigate(['casestudy',this.titleNormalized+"_"+this.humanReadableId]).then(() => {window.location.reload();});
                }
                ,(error) => {                   
                });        
        }
        ,(error) => {           
        }); 
  }

  openFancyboxPdf(caseStudyId,caseStudyTitle)
  {  
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined" && (this.token!="" && this.token!="undefined"))
      window.location.href='/login;redirectUri='+location.pathname.substr(1);  
    else
    {
       
      if(this.isLoggedIn || (this.token!="" && typeof(this.token)!="undefined"))
      {
        this.downloadCaseStudyPdf(caseStudyId,caseStudyTitle)                          
      }     
      else
      {
        this.toggleJoinContentreeModal();
      }
    }
  }

  openFancyboxVideoCs(videoUrl,caseStudyId)
  {
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.toggleJoinContentreeModal();
    else
    {
      if(this.isLoggedIn)
      {
        //check video can play  
       
            //video play
            this.videourl=videoUrl;
            this.showVideoCsModal = true;     
       
      }
      else
      {
        this.toggleJoinContentreeModal();
      }
    }
  }

  showVideoCsModal = false;
  toggleVideoCsModal(){
    this.videourl="";
    this.showVideoCsModal = !this.showVideoCsModal;
  }
  hideSolutionSolutionList(){
    let targetElement = document.getElementsByName("divSolutionList");
    targetElement?.forEach((child) => { child.classList.add("hidden"); }) ;

    let targetElementSocialShare = document.getElementsByName("divSocialShare");
    targetElementSocialShare?.forEach((child) => { child.classList.add("hidden"); }) ;
  }
  hideSolutionSolutionListForLeftColumn(){
    this.showSolutionListLeft=false;
    this.showShareSocialList=false;
  }
  /*add and create case study start*/
  showCreatePersonalSolutionSetModal = false;
  openCreatePersonalSolutionSetModal(caseStudyId){    
    this.personalSolutionData.CaseStudyId=caseStudyId;
    this.showCreatePersonalSolutionSetModal = !this.showCreatePersonalSolutionSetModal;
  }
  
  closeCreatePersonalSolutionSetModal(){  
    this.showCreatePersonalSolutionSetModal = !this.showCreatePersonalSolutionSetModal;
  }

  createWithCaseStudyForMicrosites()
  { 
    let casestudyid; 
    this.personalSolutionData.UserId= sessionStorage.getItem('sessionuserId')??"";
    this.personalSolutionData.Domain=this.domainname;
    this.service.createWithCaseStudyForMicrosites(this.personalSolutionData) 
    .subscribe(data => { 
      casestudyid=data['body'].id;
      this.showCreatePersonalSolutionSetModal=false;
      this.toastr.success("Your Solution has been saved to the new Solution Set","",{timeOut:3000,progressBar:true});                        
      this.loadSolutionSets();
      if(this.isLoggedIn)
      {        
        this.addCsToMyIndustolutionsParam.id=casestudyid;
        this.addCsToMyIndustolutionsParam.source="3";
        this.addCsToMyIndustolutionsParam.domain=this.domainname;
        this.addCsToMyIndustolutionsParam.userIP=this.userIp;
        this.addCsToMyIndustolutionsParam.userId=sessionStorage.getItem('sessionuserId')??'';
        //analytics write for analyticsWriteCaseStudySearch
        this.service.addCsToMyIndustolutions(this.addCsToMyIndustolutionsParam)
        .subscribe(data=> {this.personalSolutionData.Name=""; },error =>{ }); 
      }
    }
    ,(error) => {  
      if(error.error.length>0){
        this.toastr.error(error.error[0],"Error",{timeOut:3000, progressBar:true});
      }
      else if(error.error.message!="undefined"){
        this.toastr.error(error.error.message,"Error",{timeOut:3000, progressBar:true});
      }
      else{
        window.location.href='/login;redirectUri='+location.pathname.substr(1);
      }
    });  

  }

  showSolutionListLeft=false; 
  toggleSolutionListLeft()
  {
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.toggleJoinContentreeModal();
    else
    {
      if(this.isLoggedIn)
      {  
      this.showSolutionListLeft = !this.showSolutionListLeft;
      }
      else
      {
        this.showSolutionListLeft=false;
        this.toggleJoinContentreeModal();
      }
    }
  }


  toggleSolutionList(id)
  {
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.toggleJoinContentreeModal();
    else
    {
      if(this.isLoggedIn)
      {  
        let targetElement = document.getElementById(id);
        targetElement?.classList.remove("hidden"); 
      }
      else
      {
        this.toggleJoinContentreeModal();
      }
    }
  }

  addCaseStudyToSolutionSet(caseStudyId,solutionSetId)
  {
    this.personalSolutionData.UserId= sessionStorage.getItem('sessionuserId')??"";
    this.personalSolutionData.Domain=this.domainname;
    this.personalSolutionData.CaseStudyId=caseStudyId;
    this.personalSolutionData.SolutionSetId=solutionSetId;
    this.service.addCaseStudyToSolutionSetForMicrosites(this.personalSolutionData) 
    .subscribe(data => { 
     this.toastr.success("Your case study has been saved to the Solution Set","",{timeOut:3000,progressBar:true});                  
     this.loadSolutionSets();

      if(this.isLoggedIn)
      {
        this.addCsToMyIndustolutionsParam.id=caseStudyId;
        this.addCsToMyIndustolutionsParam.source="3";
        this.addCsToMyIndustolutionsParam.domain=this.domainname;
        this.addCsToMyIndustolutionsParam.userIP=this.userIp;
        this.addCsToMyIndustolutionsParam.userId=sessionStorage.getItem('sessionuserId')??'';
        //analytics write for analyticsWriteCaseStudySearch
        this.service.addCsToMyIndustolutions(this.addCsToMyIndustolutionsParam)
        .subscribe(data=> { },error =>{ }); 
      }

    }
    ,(error) => {  
      if(error.error.length>0 || error.error.message!="undefined"){
        this.toastr.error("Case Study has already been added in this Personal Solution Set.","Error",{timeOut:3000, progressBar:true});
      }
      else if(error.error.message=="undefined"){
        this.toastr.error(error.error.message,"Error",{timeOut:3000, progressBar:true});
      }
      else{
        window.location.href='/login;redirectUri='+location.pathname.substr(1);
      }
    });  
  }


  loadSolutionSets()
  { 
    if(this.isLoggedIn)
      {
    this.service.getSolutionSetList(sessionStorage.getItem('sessionuserId')) 
    .subscribe(data => {
      this.solutionSetList= data['body'];          
    }
    ,(error) => {
      
    }); 
  } 
  }
/* add and create case study end */


  /* for download start*/
  
  async candownloadFile(caseStudyId,caseStudyTitle,event,videoUrl)
  {
      if(this.token!="" && typeof(this.token)!="undefined"){
        this.service.canDownloadAnonymousForMicrosite(caseStudyId,this.token,this.domainname)
    .subscribe(data => {
      let strStatus=data['body']; 
      if(strStatus==true)
        { 
            if(event=="download" ){
              this.downloadCaseStudy(caseStudyId,caseStudyTitle); 
            }
            else if(event=="view"){
              this.openFancyboxPdf(caseStudyId,caseStudyTitle);
            }
            else{
              this.toggleJoinContentreeModal();
            }
          }
          else{
            this.toggleJoinContentreeModal();
          }
        }),error =>{window.location.href='/login;redirectUri='+location.pathname.substr(1);};
      }
      else if(this.isLoggedIn)
      {
    this.service.canDownloadForMicrosite(sessionStorage.getItem('sessionuserId'),caseStudyId)
    .subscribe(data => {
      let strStatus=data['body']; 
      if(strStatus==true)
      { 
        if(event=="download" || event=="downloadmore"){
        this.downloadCaseStudy(caseStudyId,caseStudyTitle); 
        }
        if(event=="view"){
          this.openFancyboxPdf(caseStudyId,caseStudyTitle);
        }
        if(event=="video"){
          this.openFancyboxVideoCs(videoUrl,caseStudyId) 
        }
      }
      else{
        this.toastr.error("You've exceeded download limit for case studies","",{timeOut:3000,progressBar:true}); 

      }

    }
    ,error =>{window.location.href='/login;redirectUri='+location.pathname.substr(1);}); 
                       
      }     
      else
      {
        this.toggleJoinContentreeModal();
      }
    

  }
  
  downloadCaseStudy(casestudyId,caseStudyTitle)
  {   
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined" && (this.token!="" && this.token!="undefined"))
      this.toggleJoinContentreeModal();
    else
    {
      
      if(this.isLoggedIn || (this.token!="" && typeof(this.token)!="undefined"))
      {
        
        this.caseStudyParam.caseStudyId= casestudyId;
        this.caseStudyParam.caseStudyTitle= caseStudyTitle;
        this.caseStudyParam.userId = sessionStorage.getItem('sessionuserId')??"";
        this.caseStudyParam.domain= this.domainname;
        this.caseStudyParam.type="0";
        this.caseStudyParam.downloadToken = ""; 
        this.caseStudyParam.token=this.token;
        if(this.token!="" && typeof(this.token)!="undefined"){
          
          this.promtToSaveFile(environment.apiURL+'/api/files/downloadanonymousforMicrosites');
        }
        else{
        this.promtToSaveFile(environment.apiURL+'/api/files/downloadforMicrosites');
        }

        //analytics write for download case study
        this.analyticsWriteDownloadCaseStudyParam.id=casestudyId;
        this.analyticsWriteDownloadCaseStudyParam.source= CaseStudyViewSource.Other;
        this.analyticsWriteDownloadCaseStudyParam.domain=this.domainname;    
        this.analyticsWriteDownloadCaseStudyParam.userIP=this.userIp;
        this.analyticsWriteDownloadCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
        this.service.analyticsWriteDownloadCaseStudy(this.analyticsWriteDownloadCaseStudyParam)
        .subscribe(data=> {
        },error =>{
          this.router.navigate(['/login']) ;
         });
        
      }
      else
      {
        this.toggleJoinContentreeModal();
      }
    }
  }

  promtToSaveFile(url) {   
    this.mainform = this.createForm(url);    
    this.mainform.appendChild(this.createInput("accessToken", sessionStorage.getItem('userToken')));
    this.mainform.appendChild(this.createInput("url", url));    
    this.mainform.appendChild(this.createInput("caseStudyId", this.caseStudyParam.caseStudyId));
    this.mainform.appendChild(this.createInput("downloadToken", ""));
    this.mainform.appendChild(this.createInput("type", this.caseStudyParam.type));
    this.mainform.appendChild(this.createInput("userId", this.caseStudyParam.userId));
    this.mainform.appendChild(this.createInput("domain", this.caseStudyParam.domain)); 
    this.mainform.appendChild(this.createInput("token", this.token));     
    this.mainform.appendChild(this.createInput("caseStudyTitle", this.caseStudyParam.caseStudyTitle)); 
    const nodenative = document.createElement('script');
    nodenative.src ='assets/jquerynative.js';
    nodenative.type = 'text/javascript';
    nodenative.async = false;  
    this.mainform.appendChild(nodenative);
    const node = document.createElement('script');
    node.src ='assets/load.js';
    node.type = 'text/javascript';
    node.async = false;  
    this.mainform.appendChild(node);
  
    setTimeout(() => {
      this.mainform.parentNode.removeChild(this.mainform);
    }, 1000); 
    return false;
  }

createForm(url) {  
  this.form = document.createElement("form");
  var formname=this.caseStudyParam.caseStudyId;
  this.form.name=formname; 
  this.form.id = formname;
  this.form.style.display = "none";
  this.form.action = url;
  this.form.method = "POST"; 
  document.body.appendChild(this.form);
  return this.form;
}

createInput(name, value) {
  var input = document.createElement("input");
  input.name = name;
  input.value = value;
  return input;
}

checkFileLoaded() {
  var token = this.getCookieValue(this.tokenCookieName);

  if ((token === this.formToken) || (this.attempts === 0)) {
    this.clearLoading();
      return;
  }
  this.attempts--;
}

getCookieValue(name) {
  this.parts = document.cookie.split(name + "=");
  return this.parts.length === 2 ? this.parts.pop().split(";").shift() : null;
}
  clearLoading() {
  clearInterval(this.cookieTimer);
  this.expireCookie(this.tokenCookieName);
  this.attempts = 60;
  }

 expireCookie(cName) {
  document.cookie = encodeURIComponent(cName) + "=deleted; expires=" + new Date(0).toUTCString();
  }
  /* for download end*/

  /*  view more -pdf download start */
  downloadCaseStudyPdf(casestudyId,caseStudyTitle)
  { 
      this.pdfDownloadparam.humanReadableId=this.humanReadableId.toString();
      this.pdfDownloadparam.caseStudyId= casestudyId;
      this.pdfDownloadparam.caseStudyTitle= caseStudyTitle;
      this.pdfDownloadparam.userId = sessionStorage.getItem('sessionuserId')??"";
      this.pdfDownloadparam.domain= this.domainname;
      this.pdfDownloadparam.token=this.token;
      if(this.token!="" && typeof(this.token)!="undefined"){
        
        this.promtToSaveFilePdf(environment.apiURL+'/api/files/FileAnonymousForMicrositesByhId');
      }
      else{
        this.promtToSaveFilePdf(environment.apiURL+'/api/files/FileForMicrositesByhId'); 
      }
      

      this.analyticsWriteDownloadCaseStudyParam.id=casestudyId;
      this.analyticsWriteDownloadCaseStudyParam.source= CaseStudyViewSource.Other;
      this.analyticsWriteDownloadCaseStudyParam.domain=this.domainname;    
      this.analyticsWriteDownloadCaseStudyParam.userIP=this.userIp;
      this.analyticsWriteDownloadCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
      this.service.analyticsWriteDownloadCaseStudy(this.analyticsWriteDownloadCaseStudyParam)
      .subscribe(data=> {
      },error =>{this.router.navigate(['/login']) ; }); 
  }

  promtToSaveFilePdf(url) {
    this.mainformpdf = this.createFormPdf(url,this.pdfDownloadparam.humanReadableId);
    let formToken = (new Date()).getTime().toString();
    this.mainformpdf.appendChild(this.createInputPdf("accessToken", sessionStorage.getItem('userToken')));
    this.mainformpdf.appendChild(this.createInputPdf("url", url));    
    this.mainformpdf.appendChild(this.createInputPdf("id",this.pdfDownloadparam.humanReadableId));
    this.mainformpdf.appendChild(this.createInputPdf("userid", this.pdfDownloadparam.userId));
    this.mainformpdf.appendChild(this.createInputPdf("domain", this.pdfDownloadparam.domain));      
    this.mainformpdf.appendChild(this.createInputPdf("caseStudyTitle", this.pdfDownloadparam.caseStudyTitle)); 
    this.mainformpdf.appendChild(this.createInputPdf("token", this.pdfDownloadparam.token)); 
    const nodenative = document.createElement('script');
    nodenative.src ='assets/jquerynative.js';
    nodenative.type = 'text/javascript';
    nodenative.async = false;  
    this.mainformpdf.appendChild(nodenative);
    const node = document.createElement('script');
    node.src ='assets/pdfload.js';
    node.type = 'text/javascript';
    node.async = false;  
   
    this.mainformpdf.appendChild(node);
    setTimeout(() => {
      this.mainformpdf.parentNode.removeChild(this.mainformpdf);
    }, 1000);   
  }

createFormPdf(url,formid) {  
  this.formpdf = document.createElement("form");
  var formname="form"+formid; 
  this.formpdf.name=formname; 
  this.formpdf.id = formname; 
  this.formpdf.style.display = "none";
  this.formpdf.action = url;
  this.formpdf.method = "POST"; 
  document.body.appendChild(this.formpdf);
  return this.formpdf;
}

createInputPdf(name, value) {
    var input = document.createElement("input");
  input.name = name;
  input.value = value;
  return input;
}

checkFileLoadedPdf() { 
  var token = this.getCookieValuePdf(this.tokenCookieName);

  if ((token === this.formToken) || (this.attempts === 0)) {
    this.clearLoading();
      return;
  }
  this.attempts--;
}

getCookieValuePdf(name) { 
  this.parts = document.cookie.split(name + "=");
  return this.parts.length === 2 ? this.parts.pop().split(";").shift() : null;
}
  clearLoadingPdf() {   
  clearInterval(this.cookieTimer);
  this.expireCookiePdf(this.tokenCookieName);
  this.attempts = 60;
  }

 expireCookiePdf(cName) {  
  document.cookie = encodeURIComponent(cName) + "=deleted; expires=" + new Date(0).toUTCString();
  }
  /* view more - pdf download end */ 

  public showShareSocial=false; 
  public showShareSocialList=false; 
  btnShareClick(dName = 'list')
  {  
    
    this.showShareSocialList = !this.showShareSocialList;  
    
  }
  listshareClick(id){
    let targetElement = document.getElementById(id);
    targetElement?.classList.remove("hidden");  
  }
}
