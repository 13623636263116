<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10 w-full max-w-7xl mx-auto px-8 pt-8">
        <section class="text-white body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <h1 class="text-white text-2xl md:text-6xl font-semibold uppercase break-words">GDPR Data
                    Protection Policy</h1>
            </div>
        </section>
    </div>
</div>
<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4">
            <div><a (click)="scrollToElement(requestFormsection)"
                    class="cursor-pointer underline mb-5 text-brand-green inline-block">Jump to data request form ></a>
            </div>
            <h2 class="text-xl mb-3 font-bold">1. Introduction</h2>
            <p class="mb-2">1.1 Background to the General Data Protection Regulation (‘GDPR’)</p>
            <p class="mb-2">
                The General Data Protection Regulation 2016 replaces the EU Data Protection Directive of 1995 and
                supersedes the laws of individual Member States that were developed in compliance with the Data
                Protection Directive 95/46/EC. Its purpose is to protect the “rights and freedoms” of natural persons
                (i.e. living individuals) and to ensure that personal data is not processed without their knowledge,
                and, wherever possible, that it is processed with their consent.
                The GDPR is a European Regulation (law) that applies to any organisation (regardless of global location)
                if it processes personal data of individuals in the EU.
            </p>
            <p class="mb-2">Specifically, the GDPR requires Contentreads:</p>

            <ul>
                <li>Process individuals’ personal data in a secure and transparent manner (i.e. they are aware of why
                    and how Contentreads is processing their data, how long it is kept for, who it is shared with and
                    where it is sent)</li>
                <li>Uphold the legal rights of individuals regarding their personal data (Section 5)</li>
                <li>Adhere to the principles set out in Section 4</li>
            </ul>

            <h2 class="text-xl mb-3 font-bold">2. Policy statement</h2>

            <p class="mb-2">2.1 The Board of Directors and management of Contentreads are committed to compliance with
                all relevant EU and Member State laws in respect of personal data, and the protection of the “rights and
                freedoms” of individuals whose information Contentreads collects and processes in accordance with the
                General Data Protection Regulation (GDPR).</p>
            <p class="mb-2">2.2 Compliance with the GDPR is described by this policy and other relevant policies, along
                with connected processes and procedures.</p>
            <p class="mb-2">2.3 The GDPR and this policy shall apply to all Contentreads personal data processing
                functions, including those performed on customers’, clients’, employees’, suppliers’ and partners’
                personal data, and any other personal data the organization processes from any source (i.e. from the
                individuals themselves or a 3rd party).</p>
            <p class="mb-2">2.4 Contentreads Data Protection Officer is responsible for:</p>
            <p class="mb-2">2.4.1 Reviewing annually personal data processing in the light of any changes to
                Contentreads activities and</p>
            <p class="mb-2">2.4.2 to any additional requirements identified by means of data protection impact
                assessments.</p>
            <p class="mb-2">2.5 This policy applies to all employees of Contentreads and third-party suppliers. Any
                breach of the GDPR will be dealt with under Contentreads disciplinary policy.</p>
            <p class="mb-2">2.5.1 Breaches of the GDPR may subject Contentreads to administrative fines and/or
                litigation. This guidance also highlights the fact that certain EU member states may be willing to
                consider custodial sentences for breaches of the GDPR.</p>
            <p class="mb-2">2.6 Partners and any third parties working with or for Contentreads, and who have or may
                have access to personal data, will be expected to have read, understood and to comply with this policy.
                No third party may access personal data held by Contentreads without having first entered into a data
                confidentiality agreement which imposes on the third-party obligations no less onerous than those to
                which Contentreads is committed, and which gives Contentreads the right to audit compliance with the
                agreement.</p>
            <h2 class="text-xl mb-3 font-bold">3. Responsibilities and roles under the General Data Protection
                Regulation
            </h2>
            <p class="mb-2">3.1 Contentreads is a data controller and processor under the GDPR. In certain cases, it may
                be a joint controller regarding subsidiaries or 3rd parties.</p>
            <p class="mb-2">3.2 The Contentreads Board and all those in managerial or supervisory roles throughout
                Contentreads are responsible for developing and encouraging good information handling practices and
                responsibilities are set out in individual job descriptions.</p>
            <p class="mb-2">3.3 The Data Protection Officer is a member of the senior management team, is accountable
                only to the Board of Directors in matters pertaining to the management of personal data within
                Contentreads and for ensuring that compliance with data protection legislation and good practice can be
                demonstrated. This accountability includes:</p>
            <p class="mb-2">3.3.1 development and implementation of the GDPR obligations as required by this policy; and
            </p>
            <p class="mb-2">3.3.2 security and risk management in relation to compliance with the policy.</p>
            <p class="mb-2">3.4 The Data Protection Officer, appointed by Contentreads Board of Directors in accordance
                with the requirements of the GDPR (Articles 37-39), has been appointed to take responsibility for
                Contentreads compliance with this policy and, in particular, has direct responsibility for ensuring that
                Contentreads complies with the GDPR.</p>
            <p class="mb-2">3.5 The Data Protection Officer is the first point of call for employees seeking
                clarification on any aspect of data protection compliance.</p>
            <p class="mb-2">3.6 Compliance with data protection law is the responsibility of all employees of
                Contentreads who process personal data.</p>
            <p class="mb-2">3.7 Employees of Contentreads are responsible for ensuring that any personal data about them
                and supplied by them to Contentreads is accurate and up-to-date.</p>
            <h2 class="text-xl mb-3 font-bold">4. Data protection principles</h2>
            <p class="mb-2">All processing of personal data must be conducted in accordance with the data protection
                principles as set out in Article 5 of the GDPR and listed below. Contentreads policies and procedures
                are designed to ensure compliance with these principles.</p>
            <p class="mb-2">4.1 Personal data must be processed lawfully, fairly and transparently Lawful – identify a
                lawful basis before you can process personal data. These are often referred to as the “conditions for
                processing”, for example consent. Fairly – in order for processing to be fair, the data controller has
                to make certain information available to the data subjects as practicable. This applies whether the
                personal data was obtained directly from the data subjects or from other sources. The GDPR has increased
                requirements about what information should be available to data subjects, which is covered in the
                ‘Transparency’ requirement.</p>
            <p class="mb-2">Transparently – the GDPR includes rules on giving privacy information to data subjects in
                Articles 12, 13 and 14. These are detailed and specific, placing an emphasis on making privacy notices
                understandable and accessible. Information must be communicated to the data subject in an intelligible
                form using clear and plain language.</p>
            <p class="mb-2">The specific information that must be provided to the data subject must, as a minimum,
                include:</p>
            <p class="mb-2">4.1.1 the identity and the contact details of the controller and, if any, of the
                controller’s representative;</p>
            <p class="mb-2">4.1.2 the contact details of the Data Protection Officer;</p>
            <p class="mb-2">4.1.3 the purposes of the processing for which the personal data are intended as well as the
                legal basis for the processing;</p>
            <p class="mb-2">4.1.4 the period for which the personal data will be stored;</p>
            <p class="mb-2">4.1.5 the existence of the rights to request access, rectification, erasure or to object to
                the processing, and the conditions (or lack of) relating to exercising these rights, such as whether the
                lawfulness of previous processing will be affected;</p>
            <p class="mb-2">4.1.6 the categories of personal data concerned;</p>
            <p class="mb-2">4.1.7 the recipients or categories of recipients of the personal data, where applicable;</p>
            <p class="mb-2">4.1.8 where applicable, that the controller intends to transfer personal data to a recipient
                in a third country and the level of protection afforded to the data;</p>
            <p class="mb-2">4.1.9 any further information necessary to guarantee fair processing.</p>
            <p class="mb-2">4.2 Personal data can only be collected for specific, explicit and legitimate purposes</p>
            <p class="mb-2">Personal data obtained for specified purposes must not be used for a purpose that is
                incompatible with the original purpose for which they were collected. The Contentreads Privacy Policy
                describes these purposes in detail.</p>
            <p class="mb-2">4.3 Personal data must be adequate, relevant and limited to what is necessary for processing
            </p>
            <p class="mb-2">4.3.1 The Data Protection Officer is responsible for ensuring that Contentreads does not
                collect information that is not – necessary for the purpose for which it is obtained.</p>
            <p class="mb-2">4.3.2 All data collection forms (electronic or paper-based), including data collection
                requirements in new information systems, must – link to a privacy statement or ‘notification’ that is
                specific to type of processing that will be undertaken and approved by the Data Protection Officer.</p>
            <p class="mb-2">4.3.3 The Data Protection Officer shall, on an annual basis, review all data collection
                methods to ensure that collected data continues to be adequate, relevant and not excessive.</p>
            <p class="mb-2">4.3.4 The Data Protection Office shall be responsible for ensuring that a review is
                undertaken whenever there is a risk to the ‘rights and freedoms’ of the individual.</p>
            <p class="mb-2">4.4 Personal data must be accurate and kept up to date with every effort to erase or rectify
                without delay.</p>
            <p class="mb-2">4.4.1 Data that is stored by the data controller must be reviewed and updated as necessary.
                No data should be kept unless it is reasonable to assume that it is accurate.</p>
            <p class="mb-2">4.4.2 The Data Protection Officer shall be responsible for ensuring that all staff are
                trained in the importance of collecting accurate data and maintaining it.</p>
            <p class="mb-2">4.4.3 It is also the responsibility of the data subject to ensure that data held by
                Contentreads is accurate and up to date. Completion of a registration or application form by a data
                subject will include a statement that the data contained therein is accurate at the date of submission.
            </p>
            <p class="mb-2">4.4.4 Employees shall notify Contentreads of any changes in circumstance to enable personal
                records to be updated accordingly. It is the responsibility of Contentreads to ensure that any
                notification regarding change of circumstances is recorded and acted upon.</p>
            <p class="mb-2">4.4.5 The Data Protection Officer shall be responsible for ensuring that appropriate
                procedures and policies are created and maintained to support all staff to keep personal data accurate
                and up to date, considering the volume of data collected, the speed with which it might change and any
                other relevant factors.</p>
            <p class="mb-2">4.4.6 On at least an annual basis, the Data Protection Officer will review the retention
                dates of all the personal data processed by Contentreads and will identify any data that is no longer
                required in the context of the stated purpose. This data will be securely deleted/destroyed.</p>
            <p class="mb-2">4.4.7 The Data Protection Officer is responsible for responding to requests for
                rectification from data subjects within one month. This can be extended to a further two months for
                complex requests. If Contentreads decides not to comply with the request, the Data Protection Officer
                shall respond to the data subject to explain its reasoning and inform them of their right to complain to
                the supervisory authority and seek judicial remedy.</p>
            <p class="mb-2">4.4.8 Data Protection Officer is responsible for making appropriate arrangements that, where
                third-party organizations may have been passed inaccurate or out-of-date personal data, to inform them
                that the information is inaccurate and/or out of date and is not to be used to inform decisions about
                the individuals concerned; and for passing any correction to the personal data to the third party where
                this is required.</p>
            <p class="mb-2">4.5 Personal data must be kept in a form such that the data subject can be identified only
                as long as is necessary for processing.</p>
            <p class="mb-2">4.5.1 Where personal data is retained beyond the processing date, it shall be encrypted to
                protect the identity of the data subject in the event of a data breach.</p>
            <p class="mb-2">4.5.2 Personal data shall be retained in line with GDPR and, once its retention date is
                passed, it must be securely destroyed. </p>
            <p class="mb-2">4.5.3 Data Protection Officer must specifically approve any data retention that exceeds the
                retention periods and must ensure that the justification is clearly identified and in line with the
                requirements of the data protection law. This approval must be written.</p>
            <p class="mb-2">4.6 Personal data must be processed in a manner that ensures the appropriate security</p>
            <p class="mb-2">
                The Data Protection Officer will carry out a risk assessment considering all the circumstances of
                Contentreads controlling or processing operations.
                In determining appropriateness, the Data Protection Officer shall consider the extent of possible
                damage, loss or distress that might be suffered by individuals (e.g. staff or customers) if a security
                breach occurs, the effect of any security breach on Contentreads itself, and any likely reputational
                damage including the possible loss of customer trust and cost of remediation.
            </p>
            <p class="mb-2">
                When assessing appropriate organizational measures the Data Protection Officer shall consider the
                following:
            </p>
            <ul>
                <li>The appropriate training levels throughout Contentreads;</li>
                <li>Measures that consider the reliability of employees (such as references etc.);</li>
                <li>The inclusion of data protection and related obligations in employment contracts;</li>
                <li>Identification of disciplinary measures for data breaches;</li>
                <li>Physical access controls to electronic and paper-based records containing personal data;</li>
                <li>Adoption of a clear desk policy;</li>
                <li>Storing of paper-based data in lockable fire-proof cabinets;</li>
                <li>Restricting the use of portable electronic devices outside of the workplace;</li>
                <li>Restricting the use of employee’s own personal devices being used in the workplace;</li>
                <li>Adopting clear rules about passwords;</li>
                <li>Making regular backups of personal data and storing the media off-site;</li>
                <li>The imposition of contractual obligations on the importing organizations to take appropriate
                    security measures when transferring data outside the EEA.</li>
            </ul>

            <p class="mb-2">
                These controls have been selected on the basis of identified risks to personal data, and the potential
                for damage or distress to individuals whose data is processed by Contentreads.
            </p>
            <p class="mb-2">4.7 The controller must be able to demonstrate compliance with the GDPR’s other principles
                (accountability)</p>
            <p class="mb-2">The GDPR includes provisions that promote accountability and governance. These complement
                the GDPR’s transparency requirements. The accountability principle in Article 5(2) requires
                demonstration of compliance with the principles and states explicitly whose responsibility this is.</p>
            <p class="mb-2">Contentreads shall demonstrate compliance with the data protection principles by
                implementing, amongst others, data protection policies, adhering to codes of conduct, implementing
                technical and organisational measures, as well as adopting techniques such as data protection by design
                and default, DPIAs, breach notification procedures, records and processing and associated notifications
                and incident response plans.</p>
            <h2 class="text-xl mb-3 font-bold">5. Data subjects’ rights</h2>
            <p class="mb-2">5.1 Data subjects have the following legal rights regarding their personal data processed by
                Contentreads:</p>
            <p class="mb-2">5.1.1 Confirmation as to whether their personal data is processed and if so the:</p>
            <p class="mb-2">5.1.1.1 Purposes of processing</p>
            <p class="mb-2">5.1.1.2 Categories of data being processed (if their personal data was provided by a 3rd
                party)</p>
            <p class="mb-2">5.1.1.3 Recipients of data disclosed by controller, or categories of recipient</p>
            <p class="mb-2">5.1.1.4 In particular, recipients (excluding categories) in third countries</p>
            <p class="mb-2">5.1.1.5 Intended retention period (or, if not possible, criteria)</p>
            <p class="mb-2">5.1.1.6 Rights of Rectification, Erasure, Restriction, and Objection</p>
            <p class="mb-2">5.1.1.7 Right to complain to regulators</p>
            <p class="mb-2">5.1.1.8 Any(all) available information as to the sources immediately providing the data
                (where not the data subject)</p>
            <p class="mb-2">5.1.2 Rectification of inaccurate personal data and incomplete personal data completed, both
                without undue delay.</p>
            <p class="mb-2">5.1.3 To be notified if Contentreads communicates any rectification, erasure or restriction
                of processing to any 3rd party where Contentreads has disclosed the individual’s personal data</p>
            <p class="mb-2">5.1.4 To have their personal data erased if:</p>
            <p class="mb-2">5.1.4.1 It is no longer needed for the purposes for which it was collected or processed</p>
            <p class="mb-2">5.1.4.2 Consent is withdrawn and here is no other legal ground for processing it</p>
            <p class="mb-2">5.1.4.3 The data subject objects to processing and there are no other legitimate grounds for
                processing it</p>
            <p class="mb-2">5.1.4.4 Their personal data have been unlawfully processed</p>
            <p class="mb-2">5.1.4.5 Their personal data have to be erased for compliance with a legal obligation</p>
            <p class="mb-2">5.1.4.6 Their personal data have been collected for information society services offered to
                a child.</p>
            <p class="mb-2">5.1.5 To restrict processing where:</p>
            <p class="mb-2">5.1.5.1 The accuracy of the personal data is contested</p>
            <p class="mb-2">5.1.5.2 Processing in unlawful</p>
            <p class="mb-2">5.1.5.3 The personal data is held for longer than the original purpose required.</p>
            <p class="mb-2">5.1.6 To be informed about the mechanics (including meaningful information about the logic
                involved) of automated decision-taking process and to obtain human intervention.</p>
            <p class="mb-2">5.1.7 To receive compensation for material or non-material damage suffered as a result of an
                infringement of the GDPR</p>
            <p class="mb-2">5.1.8 To request the supervisory authority to assess whether any provision of the GDPR has
                been contravened.</p>
            <p class="mb-2">5.1.9 To have personal data provided to them in a structured, commonly used and
                machine-readable format, and the right to have that data transmitted to another controller.</p>
            <p class="mb-2">5.1.10 To object to any automated decision-making or profiling that is not based on their
                consent or which is not necessary for contractual purposes</p>
            <p class="mb-2">5.1.11 To object to their personal data being used for the purposes of direct marketing</p>
            <p class="mb-2">5.2 Contentreads ensures that data subjects may exercise these rights:</p>
            <p class="mb-2">5.2.1 Data subjects may make data access requests and Contentreads will ensure that its
                response to the data access request complies with the requirements of the GDPR.</p>
            <p class="mb-2">5.2.2 Data subjects have the right to complain to Contentreads or a supervisory authority
                (e.g. the ICO) related to the processing of their personal data, the handling of a request from a data
                subject and appeals from a data subject on how complaints have been handled.</p>
            <h2 class="text-xl mb-3 font-bold">6. Consent</h2>
            <p class="mb-2">6.1 Contentreads understands ‘consent’ to mean that it has been explicitly and freely given,
                and a specific, informed and unambiguous indication of the data subject’s wishes that, by statement or
                by a clear affirmative action, signifies agreement to the processing of personal data relating to him or
                her.</p>
            <p class="mb-2">6.2 The data subject can withdraw their consent at any time.</p>
            <p class="mb-2">6.3 Contentreads understands ‘consent’ to mean that the data subject has been fully informed
                of the intended processing and has signified their agreement, while in a fit state of mind to do so and
                without pressure being exerted upon them. Consent obtained under duress or on the basis of misleading
                information will not be a valid basis for processing.</p>
            <p class="mb-2">6.4 There must be some active communication between the parties to demonstrate active
                consent.</p>
            <p class="mb-2">6.5 Consent cannot be inferred from non-response to a communication.</p>
            <p class="mb-2">6.6 Contentreads must be able to demonstrate that consent was obtained for the processing
                operation.</p>
            <p class="mb-2">6.7 For sensitive data, explicit written consent of data subjects must be obtained unless an
                alternative legitimate basis for processing exists.</p>
            <p class="mb-2">6.8 In most instances, consent to process personal and sensitive data is obtained routinely
                by Contentreads using standard consent processes.</p>
            <p class="mb-2">6.9 Contentreads shall record all consent provision and withdrawal at the same level of
                granularity as described above</p>
            <p class="mb-2">6.10 Contentreads shall seek advice of the DPO if:</p>
            <p class="mb-2">6.10.1 The purpose for processing data based on consent changes – consent must always be
                specific and informed</p>
            <p class="mb-2">6.10.2 Consent and other lawful means of processing are considered for the same personal
                data.</p>
            <h2 class="text-xl mb-3 font-bold">7. Security of data</h2>
            <p class="mb-2">7.1 All employees are responsible for ensuring that any personal data that Contentreads
                holds and for which they are responsible, is kept securely and is not under any conditions disclosed to
                any third party unless that third party has been specifically authorized by Contentreads to receive that
                information and has entered into a confidentiality agreement and the data subject notified at least of
                the category of recipient of their personal data</p>
            <p class="mb-2">
                7.2 All personal data shall be accessible only to those who need to use it. All personal data should be
                treated with the highest security and must be kept:
            </p>
            <ul>
                <li>in a lockable room with controlled access; and/or</li>
                <li>in a locked drawer or filing cabinet; and/or</li>
                <li>if computerized, password protected.</li>
                <li>stored on (removable) computer media which are encrypted.</li>

            </ul>

            <p class="mb-2">7.3 Care must be taken to ensure that all screens and terminals are not visible except to
                authorized employees of Contentreads . </p>
            <p class="mb-2">7.4 Manual records shall not be left where they can be accessed by unauthorized personnel
                and may not be removed from business premises without explicit authorization. As soon as manual records
                are no longer required, they must be removed from secure archiving and shredded.</p>
            <p class="mb-2">7.5 Manual records that have reached their retention date are to be shredded and disposed of
                as ‘confidential waste’. Hard drives of redundant PCs are to be removed and immediately destroyed before
                disposal.</p>
            <p class="mb-2">7.6 Processing of personal data ‘off-site’ presents a potentially greater risk of loss,
                theft or damage to personal data. Staff must be specifically authorized to process data off-site.</p>
            <h2 class="text-xl mb-3 font-bold">8. Disclosure of data</h2>
            <p class="mb-2">8.1 Contentreads shall ensure that personal data is not disclosed to unauthorized third
                parties which includes family members, friends, government bodies, and in certain circumstances, law
                enforcement agencies. All employees shall exercise caution when asked to disclose personal data held on
                another individual to a third party. It is important to bear in mind if disclosure of the information is
                relevant to, and necessary for, the conduct of Contentreads business. If any request is suspicious this
                should be raised with the DPO without undue delay before disclosure.</p>
            <p class="mb-2">8.2 All requests to provide data for one of these reasons must be supported by appropriate
                approvals and all such disclosures must be specifically authorized by the Data Protection Officer. </p>
            <h2 class="text-xl mb-3 font-bold">9. Retention and disposal of data</h2>
            <p class="mb-2">9.1 Contentreads shall not keep personal data in a form that permits identification of data
                subjects for longer a period than is necessary, in relation to the purpose(s) for which the data was
                originally collected.</p>
            <p class="mb-2">9.2 Contentreads may store data for longer periods if the personal data will be processed
                solely for archiving purposes in the public interest, scientific or historical research purposes or
                statistical purposes, subject to the implementation of appropriate technical and organizational measures
                to safeguard the rights and freedoms of the data subject.</p>
            <p class="mb-2">9.3 The retention period for each category of personal data will be determined with
                reference to statutory obligations Contentreads is required to meet to retain the data.</p>
            <p class="mb-2">9.4 Personal data must be disposed of securely in accordance with the sixth principle of the
                GDPR – processed in an appropriate manner to maintain security, thereby protecting the “rights and
                freedoms” of data subjects.</p>
            <h2 class="text-xl mb-3 font-bold">10. Data transfers</h2>
            <p class="mb-2">10.1 All exports of data from within the European Economic Area (EEA) to non-European
                Economic Area countries (referred to in the GDPR as ‘third countries’) are unlawful unless there is an
                appropriate “level of protection for the fundamental rights of the data subjects”.</p>
            <p class="mb-2">The transfer of personal data outside of the EEA is prohibited unless one or more of the
                specified safeguards, or exceptions, apply:</p>
            <p class="mb-2">10.1.1 An adequacy decision</p>
            <p class="mb-2">The European Commission can and does assess third countries, a territory and/or specific
                sectors within third countries to assess whether there is an appropriate level of protection for the
                rights and freedoms of natural persons. In these instances, no authorization is required.</p>
            <p class="mb-2">Countries that are members of the European Economic Area (EEA) but not of the EU are
                accepted as having met the conditions for an adequacy decision.</p>
            <p class="mb-2">
                A list of countries that currently satisfy the adequacy requirements of the Commission are published in
                the <a
                    href="http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm">Official
                    Journal of the European Union</a>.
            </p>
            <p class="mb-2">10.1.2 Privacy Shield</p>
            <p class="mb-2">If Contentreads wishes to transfer personal data from the EU to an organization in the
                United States it should check that the organization is signed up with the Privacy Shield framework at
                the U.S. Department of Commerce. The obligation applying to companies under the Privacy Shield are
                contained in the “Privacy Principles”. The US DOC is responsible for managing and administering the
                Privacy Shield and ensuring that companies live up to their commitments. In order to be able to certify,
                companies must have a privacy policy in line with the Privacy Principles e.g. use, store and further
                transfer the personal data according to a strong set of data protection rules and safeguards. The
                protection given to the personal data applies regardless of whether the personal data is related to an
                EU resident or not. Organizations must renew their “membership” to the Privacy Shield on an annual
                basis. If they do not, they can no longer receive and use personal data from the EU under that
                framework.</p>
            <p class="mb-2">Assessment of adequacy by the data controller</p>
            <p class="mb-2">
                In making an assessment of adequacy, the exporting controller should take account of the following
                factors:
            </p>
            <ul>
                <li>• the nature of the information being transferred;</li>
                <li>• the country or territory of the origin, and final destination, of the information;</li>
                <li>• how the information will be used and for how long;</li>
                <li>• the laws and practices of the country of the transferee, including relevant codes of practice and
                    international obligations; and</li>
                <li>• the security measures that are to be taken as regards the data in the overseas location.</li>
            </ul>

            <p class="mb-2">10.1.3 Binding corporate rules</p>
            <p class="mb-2">Contentreads may adopt approved binding corporate rules for the transfer of data outside the
                EU. This requires submission to the relevant supervisory authority for approval of the rules that
                Contentreads is seeking to rely upon.</p>
            <p class="mb-2">10.1.4 Model contract clauses</p>
            <p class="mb-2">Contentreads may adopt approved model contract clauses for the transfer of data outside of
                the EEA. If Contentreads adopts the model contract clauses approved by the relevant supervisory
                authority there is an automatic recognition of adequacy.</p>
            <p class="mb-2">10.1.5 Exceptions</p>
            <p class="mb-2">
                In the absence of an adequacy decision, Privacy Shield membership, binding corporate rules and/or model
                contract clauses, a transfer of personal data to a third country or international organization shall
                only take place on one of the following conditions:
            </p>
            <ul>
                <li>the data subject has explicitly consented to the proposed transfer, after having been informed of
                    the possible risks of such transfers for the data subject due to the absence of an adequacy decision
                    and appropriate safeguards;</li>
                <li>the transfer is necessary for the performance of a contract between the data subject and the
                    controller or the implementation of pre-contractual measures taken at the data subject’s request;
                </li>
                <li>the transfer is necessary for the conclusion or performance of a contract concluded in the interest
                    of the data subject between the controller and another natural or legal person;</li>
                <li>the transfer is necessary for important reasons of public interest;</li>
                <li>the transfer is necessary for the establishment, exercise or defense of legal claims; and/or</li>
                <li>the transfer is necessary in order to protect the vital interests of the data subject or of other
                    persons, where the data subject is physically or legally incapable of giving consent.</li>
            </ul>

            <h2 class="text-xl mb-3 font-bold">11. Data and Purpose Review</h2>
            <p class="mb-2">11.1 Contentreads has established a data inventory and data flow process as part of its
                approach to address risks and opportunities throughout its GDPR compliance project. This inventory is
                disclosable.</p>
            <p class="mb-2">11.2 This inventory shall be:</p>
            <p class="mb-2">11.2.1 Referenced and updated as necessary at any change in technology, process or other
                transformation to determine any impact on the lawful processing of personal data to assist in
                Contentreads obligations under Data Protection Impact Assessments</p>
            <p class="mb-2">11.2.2 Referenced</p>
            <p class="mb-2">11.3 This inventory is used in the construction of notifications (Articles 13 and 14)</p>
            <p class="mb-2">
                11.4 Contentreads data inventory and data flow determines business processes that use personal data;

            </p>
            <ul>
                <li>source of personal data;</li>
                <li>volume of data subjects;</li>
                <li>description of each item of personal data;</li>
                <li>processing activity;</li>
                <li>maintains the inventory of data categories of personal data processed;</li>
                <li>documents the purpose(s) for which each category of personal data is used;</li>
                <li>recipients, and potential recipients, of the personal data;</li>
                <li>the role of the Organisation Name throughout the data flow;</li>
                <li>key systems and repositories;</li>
                <li>any data transfers; and</li>
                <li>all retention and disposal requirements.</li>
            </ul>

            <h2 class="text-xl mb-3 font-bold">Appendix – Definitions</h2>
            <h2 class="text-xl mb-3 font-bold">
                Definitions used by Contentreads (drawn from the GDPR):</h2>
            <p class="mb-2">Material scope (Article 2) – the GDPR applies to the processing of personal data wholly or
                partly by automated means (i.e. by computer) and to the processing other than by automated means of
                personal data (i.e. paper records) that form part of a filing system or are intended to form part of a
                filing system.</p>
            <p class="mb-2">Territorial scope (Article 3) – the GDPR will apply to all controllers that are established
                in the EU (European Union) who process the personal data of data subjects, in the context of that
                establishment. It will also apply to controllers outside of the EU that process personal data to offer
                goods and services or monitor the behavior of data subjects who are resident in the EU.</p>
            <h2 class="text-xl mb-3 font-bold">Article 4 definitions:</h2>
            <p class="mb-2">Establishment – the main establishment of the controller in the EU will be the place in
                which the controller makes the main decisions as to the purpose and means of its data processing
                activities. The main establishment of a processor in the EU will be its administrative center. If a
                controller is based outside the EU, it will have to appoint a representative in the jurisdiction in
                which the controller operates to act on behalf of the controller and deal with supervisory authorities.
            </p>
            <p class="mb-2">Personal data – any information relating to an identified or identifiable natural person
                (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly,
                by reference to an identifier such as a name, an identification number, location data, an online
                identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic,
                cultural or social identity of that natural person.</p>
            <p class="mb-2">Special categories of personal data – personal data revealing racial or ethnic origin,
                political opinions, religious or philosophical beliefs, or trade-union membership, and the processing of
                genetic data, biometric data for uniquely identifying a natural person, data concerning health or data
                concerning a natural person’s sex life or sexual orientation.</p>
            <p class="mb-2">Data controller – the natural or legal person, public authority, agency or other body which,
                alone or jointly with others, determines the purposes and means of the processing of personal data;
                where the purposes and means of such processing are determined by Union or Member State law, the
                controller or the specific criteria for its nomination may be provided for by Union or Member State law.
            </p>
            <p class="mb-2">Data subject – any living individual who is the subject of personal data held by an
                organization.</p>
            <p class="mb-2">Child – the GDPR defines a child as anyone under the age of 16 years old, although this may
                be lowered to 13 by Member State law. The processing of personal data of a child is only lawful if
                parental or custodian consent has been obtained. The controller shall make reasonable efforts to verify
                in such cases that consent is given or authorized by the holder of parental responsibility over the
                child.</p>
            <p class="mb-2">Third party – a natural or legal person, public authority, agency or body other than the
                data subject, controller, processor and persons who, under the direct authority of the controller or
                processor, are authorized to process personal data.</p>
            <p class="mb-2">Filing system – any structured set of personal data which are accessible according to
                specific criteria, whether centralized, decentralized or dispersed on a functional or geographical
                basis.</p>
            <section #requestFormsection>

                <div class="border border-gray max-w-screen-xl">
                    <h2 class="text-brand-gray text-2xl p-5 font-bold">If you would like to examine, modify, or delete
                        your
                        data, please fill out the form below.</h2>
                    <div class="p-5 bg-blue-300 text-blue-600">
                        We don't collect any personal information for unregistered users. Any information for analytics
                        is
                        anonymized for unregistered users. You can <a href="login" class="underline">sign in here</a>
                        <br /> Please let us know if you still have a question using
                        <a (click)="toggleNeedHelpModal()" class="underline cursor-pointer modal-open">this form.</a>
                        <div ng-if="vm.isLogged" class="gdpr__empty"></div>
                    </div>

                    <form #requestFormPage="ngForm" class="form  p-5" name="requestForm">
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-first-name">
                                    First Name
                                </label>
                                <input disabled="{{!isLoggedIn}}" #firstname="ngModel" type="text"
                                    name="firstName" [(ngModel)]="requestForm.FirstName"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    placeholder="Your first name so we can be polite when we email you!" />



                                <!--<p class="text-red-500 text-xs italic">Please fill out this field.</p>-->
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-last-name">
                                    Last Name
                                </label>
                                <input type="text" name="lastName" #lastname="ngModel" disabled="{{!isLoggedIn}}"
                                    value="{{lastname}}" [(ngModel)]="requestForm.LastName"
                                    class=" appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    ng-class="{'form__control--invalid': requestForm.lastName.$touched && requestForm.lastName.$invalid}"
                                    ng-model="vm.request.lastName"
                                    placeholder="Your last name so we can be polite when we email you!" />


                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-email">
                                    Email <span class="text-red-500">*</span>
                                </label>
                                <input disabled="{{!isLoggedIn}}" required #email="ngModel" ng-if="!vm.isLogged"
                                    type="email" name="email" [(ngModel)]="requestForm.Email" readonly="true"
                                    value="{{email}}"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    [ngClass]="{'red-border-class': firstname.errors && (firstname.dirty || firstname.touched)}" />
                                <div *ngIf="email.errors && (email.dirty || email.touched)">
                                    <div [hidden]="!email.errors.required" class="text-red-500 -mt-3">
                                        Please enter Email.
                                    </div>

                                </div>
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-request-type">
                                    Request Type <span class="text-red-500">*</span>
                                </label>
                                <div class="relative">
                                    <select disabled="{{!isLoggedIn}}" required aria-selected="true"
                                        #requesttype="ngModel" required id="requesttype" autocomplete="off"
                                        name="requesttype" [(ngModel)]="requestForm.Type"
                                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-state"
                                        [ngClass]="{'red-border-class': requesttype.errors && (requesttype.dirty || requesttype.touched)}">
                                        <option value="">Please Select</option>
                                        <option>I would like to see the personal information</option>
                                        <option>I would like to modify the personal information</option>
                                        <option>I would like to delete the personal information</option>
                                        <option>Other</option>
                                    </select>
                                    <div *ngIf="requesttype.errors && (requesttype.dirty || requesttype.touched)">
                                        <div [hidden]="!requesttype.errors.required" class="text-red-500 -mt-3">
                                            Please Select a category.
                                        </div>
                                    </div>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20">
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-request-type">
                                    Message
                                </label>
                                <textarea name="message" #message="ngModel" required id="message" autocomplete="off"
                                    name="message" [(ngModel)]="requestForm.Message"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    disabled="{{!isLoggedIn}}" placeholder="Your message"
                                    [ngClass]="{'red-border-class': firstname.errors && (firstname.dirty || firstname.touched)}"></textarea>
                                <div *ngIf="message.errors && (message.dirty || message.touched)">
                                    <div [hidden]="!message.errors.required" class="text-red-500 -mt-3">
                                        Please enter Message.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <button type="reset"
                                class="cursor-pointer font-bold uppercase py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white mr-3">Cancel</button>
                            <button (click)="sendRequestData(requestFormPage)"
                                class="cursor-pointer bg-brand-green font-bold uppercase py-2 px-4 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                type="submit" [disabled]="!requestFormPage.valid">Save
                            </button>
                        </div>
                    </form>

                </div>
            </section>

        </div>
        <!-- Need Help Modal-->
        <div *ngIf="showNeedHelpModal"
            class="fixed text-gray-500 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-40 left-0 right-0 top-0 bottom-0"
            x-transition:enter="transition ease duration-300" x-transition:enter-start="opacity-0"
            x-transition:enter-end="opacity-100" x-transition:leave="transition ease duration-300"
            x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0">
            <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12  lg:w-4/12  mx-10"
                x-transition:enter="transition ease duration-100 transform"
                x-transition:enter-start="opacity-0 scale-90 translate-y-1"
                x-transition:enter-end="opacity-100 scale-100 translate-y-0"
                x-transition:leave="transition ease duration-100 transform"
                x-transition:leave-start="opacity-100 scale-100 translate-y-0"
                x-transition:leave-end="opacity-0 scale-90 translate-y-1">
                <div class="flex items-center w-full">
                    <div class="text-gray-900 font-medium text-lg">Need Help?</div> <svg (click)="toggleNeedHelpModal()"
                        class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
                <div class="py-4">
                    <form #needHelpForm="ngForm">
                        <div class="form-group">

                        </div>
                        <div class="form-group">
                            <div class="" *ngIf="!isLoggedIn">
                                <label for="emailFrom" class="text-md text-gray-600">From:</label>
                                <input type="email" #emailFrom="ngModel" required id="emailFrom" autocomplete="off"
                                    email="true" name="emailFrom" [(ngModel)]="needHelp.emailFrom"
                                    class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                    [ngClass]="{'red-border-class': emailFrom.errors && (emailFrom.dirty || emailFrom.touched)}"
                                    placeholder="Enter your Email">
                                <div *ngIf="emailFrom.errors && (emailFrom.dirty || emailFrom.touched)">
                                    <div [hidden]="!emailFrom.errors.required" class="text-red-500 -mt-3">
                                        Please enter email.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="comments" class="text-md text-gray-600">How can Contentreads help?</label>
                            <input type="text" #comments="ngModel" required id="comments" autocomplete="off"
                                name="comments" [(ngModel)]="needHelp.customText"
                                class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                [ngClass]="{'red-border-class': comments.errors && (comments.dirty || comments.touched)}"
                                placeholder="Please enter your support needs">
                            <div *ngIf="comments.errors && (comments.dirty || comments.touched)">
                                <div [hidden]="!comments.errors.required" class="text-red-500 -mt-3">
                                    Please enter Comments.
                                </div>
                            </div>
                        </div>


                        <div class="flex justify-between mt-5"> <button (click)="toggleNeedHelpModal()"
                                class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center">Cancel</button>
                            <button
                                class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                (click)="sendEmailForHelp(needHelpForm)" [disabled]="!needHelpForm.valid">Send
                                Email</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div *ngIf="showNeedHelpModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
    <app-pagefooter></app-pagefooter>