import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser'; 
import { analyticsWriteModel } from 'src/app/common/searchparams';
import { ApicallService } from 'src/app/service/apicall.service';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-joinpage',
  templateUrl: './joinpage.component.html',
  styleUrls: ['./joinpage.component.scss']
})
export class JoinpageComponent implements OnInit {
  domainname : any;
  userIp:any;
  public isLoggedIn;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname:string;
  constructor(private titleService: Title, private service:ApicallService,private seo:SeoService) { }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit(): void {
    this.domainname = this.service.getHostname();
    
    if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
    else
      this.isLoggedIn=false;

    if(this.isLoggedIn)
    {
      this.service.getUserIP().subscribe(data =>{      
      this.userIp = data['ip']; 

      this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
      this.analyticsWriteParam.stateUrl=this.componentname;
      this.analyticsWriteParam.stateName=this.componentname;
      this.analyticsWriteParam.domain=this.domainname;
      this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp, this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
      .subscribe(data=> { },error =>{ }); 

      }); 

      
    }
  }

}
