<link rel="stylesheet" [href]='sanitizer.bypassSecurityTrustResourceUrl(cssUrl)'> 
<nav class="fixed w-full z-50" x-data="{ mobileMenuOpen : false }">
    <div class="max-w-screen-2xl mx-auto px-2 lg:px-3">
        <div class="relative flex items-center justify-between h-24">
            <div class="text-white">
                <a (click)="setTitle('Home')" routerLink="/home" routerLinkActive="active">
                    <div *ngFor="let content of contentMicrosites">
                        <img class="block lg:hidden h-12 w-40" src="{{content.siteLogo}}" alt="{{domainname}}">
                    </div>
                </a>
                <a (click)="setTitle('Home')" routerLink="/home" routerLinkActive="active">
                    <div *ngFor="let content of contentMicrosites">
                        <img class="hidden lg:block h-12 w-auto" src="{{content.siteLogo}}" alt="{{domainname}}">
                    </div>
                </a>
            </div>
            <!-- Mobile menu button-->
            <button (click)="mobileMenuOpen = !mobileMenuOpen"
                class="d-sm-only inline-block md:hidden w-8 h-8 text-white p-1">
                <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>

            <div [ngClass]="mobileMenuOpen ? 'flex flex-auto absolute md:relative top-20 left-0 md:top-0 z-20 md:flex flex-col md:flex-row font-semibold w-full md:w-auto bg-white shadow-md rounded md:rounded-none md:shadow-none md:bg-transparent p-6  md:p-0' :'hidden flex-auto absolute md:relative top-16 left-0 md:top-0 z-20 md:flex flex-col md:flex-row  font-semibold w-full md:w-auto bg-white shadow-md rounded-lg md:rounded-none md:shadow-none md:bg-transparent p-6  md:p-0'"
                (clickOutside)="mobileMenuOpen = false" class="md:flex content-center md:flex-auto">
                <div class="w-full lg:mx-10 md:mx-5 flex">
                    <div *ngIf="showSearch" class="w-full self-center">
                        <form #filterForm="ngForm">
                            <div *ngFor="let mcontent of microSiteContent">
                                <div *ngIf="mcontent.section == 'SearchBox' && mcontent.isEnabled">
                                    <div class="relative lg:mx-10 text-gray-600">
                                        <input type="text" placeholder="{{mcontent.content}}"
                                            onfocus="this.placeholder = ''"
                                            class="header__search w-full bg-white h-10 px-3 pr-12 rounded text-sm focus:outline-none border md:border-0  border-gray-200 uppercase font-bold"
                                            required name="queryString" (keyup.enter)="SearchCase(filterForm)"
                                            [(ngModel)]="searchParamModel.queryString" />

                                        <button (click)="setTitle('Search Case Studies'); SearchCase(filterForm)"
                                            [disabled]="!filterForm.valid"
                                            [ngClass]="{'disabledbtn': !filterForm.valid }"
                                            class="header__search-btn absolute right-0 top-0 bg-brand-green h-full p-3 rounded-tr rounded-br"
                                            ng-reflect-ng-class="[object Object]" disabled="">
                                            <svg class="text-white h-4 w-4 fill-current"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
                                                x="0px" y="0px" viewBox="0 0 56.966 56.966"
                                                style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                                                width="512px" height="512px">
                                                <path
                                                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="md:flex flex-col md:flex-row">

                    <a (click)="setTitle('About')" routerLink="/about"
                        routerLinkActive="text-brand-green md:text-brand-green"
                        class="block p-3 text-lg font-semibold uppercase text-black md:text-white hover:text-brand-green">About</a>

                    <a *ngIf="showblog" (click)="setTitle('Blog')" routerLink="/blog/post"
                        routerLinkActive="text-brand-green md:text-brand-green"
                        class="block p-3 text-lg font-semibold uppercase text-black md:text-white hover:text-brand-green">Blog</a>


                    <a *ngIf="isLoggedIn" (click)="setTitle('Personal Sets')" routerLink="/mycontentree"
                        routerLinkActive="text-brand-green md:text-brand-green"
                        class="block p-3 text-lg font-semibold uppercase text-black md:text-white hover:text-brand-green">myContentreads</a>

                </div>
                <div class="md:flex flex-col md:flex-row">
                    <a *ngIf="!isLoggedIn" (click)="redirectSignIn('Login')"
                        routerLinkActive="text-brand-green md:text-brand-green active"
                        class="cursor-pointer block p-3 text-lg font-semibold uppercase text-black md:text-white hover:text-brand-green whitespace-nowrap mb-6 md:mb-0">
                        Log in
                    </a>
                    <span x-data="{ open: false }" class="relative">
                        <button *ngIf="isLoggedIn" x-on:click="open = true"
                            class="cursor-pointer bg-brand-gray text-lg font-semibold uppercase p-3 rounded text-white whitespace-nowrap">
                            My Account
                        </button>
                        <ul x-show="open" x-on:click.away="open = false"
                            class="bg-white text-gray-700 rounded shadow-lg absolute left-0 md:left-auto md:right-0 py-2 mt-2 w-60">
                            <li>
                                <a (click)="setTitle('Accounts Settings')" routerLink="/accountsettings"
                                    routerLinkActive="active"
                                    class="block hover:bg-gray-200 whitespace-no-wrap uppercase py-2 px-4">
                                    Account Settings
                                </a>
                            </li>
                            <li>
                                <a (click)="setTitle('Sign In'); onSignout()"
                                    class="block hover:bg-gray-200 whitespace-no-wrap uppercase py-2 px-4 cursor-pointer">
                                    Sign Out
                                </a>
                            </li>
                        </ul>
                    </span>
                    <a *ngIf="!isLoggedIn" (click)="redirectSignUp('Join')" 
                        routerLinkActive="text-white"
                        class="cursor-pointer bg-brand-green text-lg font-semibold uppercase py-3 px-7 rounded text-white md:ml-4">Join</a>
                </div>
            </div>

        </div>
    </div>
</nav>