import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MySolutionModel, ShareViaTwitterModel, SolutionSetModel } from '../models/mysolutionmodel';
import { ApicallService } from '../service/apicall.service';
import { analyticsWriteDownloadCaseStudyModel, analyticsWriteModel, analyticsWriteShareCaseStudyModel, CaseStudyViewSource, downloadCaseStudyModel, SocialMediaType } from '../common/searchparams';
import { FuncsService } from '../service/funcs.service';

declare var $: any;
@Component({
  selector: 'app-mysolutionset',
  templateUrl: './mysolutionset.component.html',
  styleUrls: ['./mysolutionset.component.scss']
})
export class MysolutionsetComponent implements OnInit {
  public solutionid;
  Count = [];
  allSimpleForMicrositesList : any;
  solutionsForMicrositesList: any;
  solutionsForMicrositesListCount:number;
  //case study page
  humanReadableId: any;
  titleNormalized: any;
  //for popup
  public mysolution = new MySolutionModel();
  public shareViaTwitterParam = new ShareViaTwitterModel();
  public strUrl;
  userIp:any;
  public casestudytitle:any;
  public fileLinktext:any;
  public result = [] as  any;  
  public emptyEmailList:boolean=true;
  public isLoggedIn;
  public isLoggedInViaLinkedin;
  public domainname:any;
  public cookieTimer;
  public formToken;
  public tokenCookieName = "downloadToken";
  public attempts = 60;
  public parts;
  public mainform ;
  public form ;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname:string;
  public solutionData = new SolutionSetModel();
  public caseStudyParam = new downloadCaseStudyModel();
  public analyticsWriteShareCaseStudyParam = new analyticsWriteShareCaseStudyModel();
  
  public analyticsWriteDownloadCaseStudyParam = new analyticsWriteDownloadCaseStudyModel();
  public candowload:boolean=false;
  constructor(private titleService:Title,private route:ActivatedRoute,private router:Router,private service:ApicallService,private toastr:ToastrService, public funcs: FuncsService) {
    this.titleService.setTitle("Contentreads");
    this.Count.length = 5;   
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit(): void {    
    this.route.paramMap.subscribe(data=>{       
      this.solutionid = data.get('id');        
      });     
      this.service.getUserIP().subscribe(data =>{      
        this.userIp = data['ip']; 
      });
      this.domainname = this.service.getHostname();
       
      //if session expired
      if(sessionStorage.getItem('sessionuserId')=="undefined")
        this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);
      else
      {
        if(sessionStorage.getItem('sessionuserId')!=null)
        this.isLoggedIn=true;
        else
        this.isLoggedIn=false; 
      }

      //if loggedin via linked in
      if( this.isLoggedIn && sessionStorage.getItem('sessionLoggedinViaLinkedin')!=null)
      this.isLoggedInViaLinkedin=true;
      else
      this.isLoggedInViaLinkedin=false;       

      this.result = []; 
      
      // api call for listing      
      this.service.getAllSimpleForMicrosites(sessionStorage.getItem('sessionuserId'))
      .subscribe(data=> {   
        this.allSimpleForMicrositesList=data;      
      }
      ,error =>{this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]); });  

      this.service.getSolutionsForMicrosites(this.solutionid,sessionStorage.getItem('sessionuserId'))
      .subscribe(data=> { 
        this.solutionsForMicrositesList=data;
        this.solutionsForMicrositesListCount=data.length;       
      }
      ,error =>{ this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);});  

      if(this.isLoggedIn)
      {
        this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
        this.analyticsWriteParam.stateUrl=this.componentname;
        this.analyticsWriteParam.stateName=this.componentname;
        this.analyticsWriteParam.domain=this.domainname;
        this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp, this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
        .subscribe(data=> { },error =>{ });
        
      }
  }
  hideSolutionSolutionList() {
    this.showShareSocial=false;
  }
  gotoCaseStudy(casestudyId)
  {
    //api to get humanReadableId and titleNormalized   
    this.service.getCaseStudyUrlParts(casestudyId)    
    .subscribe(data => {
          this.humanReadableId = data['humanReadableId'];
          this.titleNormalized =  data['titleNormalized'];           
          //checkExistence      
          this.service.checkExistence(this.humanReadableId) 
          .subscribe(data => {            
            this.router.navigate(['casestudy',this.titleNormalized+"_"+this.humanReadableId]); // on checkExistence success
          }
          ,(error) => { 
          });
    }
    ,(error) => {  
    });  
  }

  //share case study via email
  showShareCaseStudyViaEmailModal = false;
  toggleShareCaseStudyViaEmailModal(solution){
    this.mysolution.UserId=sessionStorage.getItem('sessionuserId')??"";
    this.casestudytitle=solution.caseStudyTitle;
    this.mysolution.CaseStudyId= solution.caseStudyId; 
    this.mysolution.Message='';
    this.showShareCaseStudyViaEmailModal = !this.showShareCaseStudyViaEmailModal;
  }  

  closeShareCaseStudyViaEmailModal(){  
    this.showShareCaseStudyViaEmailModal = false;
  }  

  shareCaseStudyViaEmail(shareEmail:NgForm)
  { 
    this.mysolution.EmailsTo=this.result;
    this.mysolution.Domain = this.domainname;
    this.mysolution.UserId = sessionStorage.getItem('sessionuserId')??'';
    this.service.shareCaseStudyViaEmail(this.mysolution)    
    .subscribe(data => {  
      this.showShareCaseStudyViaEmailModal = false;     
      this.toastr.success("Sent email successfully","Success",{timeOut:3000, progressBar:true});      
      
      //analytics write for Case Study Share Social Media
      this.analyticsWriteShareCaseStudyParam.csId=this.mysolution.CaseStudyId;
      this.analyticsWriteShareCaseStudyParam.source= CaseStudyViewSource.MyIndustolutions;
      this.analyticsWriteShareCaseStudyParam.domain=this.domainname;     
      this.analyticsWriteShareCaseStudyParam.userIP=this.userIp;
      this.analyticsWriteShareCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
      this.service.analyticsWriteCaseStudyEmailShare(this.analyticsWriteShareCaseStudyParam)
      .subscribe(data=> {
      },error =>{this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]); }); 

    }
    ,(error) => {  
      this.showShareCaseStudyViaEmailModal = false;    
      this.toastr.error("Failed","",{timeOut:3000,progressBar:true});       
    });  
    this.result = []; 
      this.emptyEmailList=true;
  }
  
  save(event) {
    this.result.push(event.target.value);
    event.target.value='';
    this.emptyEmailList=false;
  }

  removeEmail(data)
  {
    const index: number = this.result.indexOf(data);
    if (index !== -1) {
        this.result.splice(index, 1);
    } 
    if(this.result.length==0)
    this.emptyEmailList=true;
  }  
  
 
  //get link modal
  showGetLinkModal = false;
  toggleGetLinkModal(solution)
  { 
    this.casestudytitle=solution.caseStudyTitle;
    var filelink=solution.fileLink;
    var info = filelink.split("?");
    this.fileLinktext= window.location.protocol+"//"+window.location.hostname+"/downloadfile?"+info[1];
    this.showGetLinkModal = !this.showGetLinkModal;
  }

  closeGetLinkModalModal()
  {
    this.showGetLinkModal=false;
  }

  copyInputMessage(fileLink)
  {   
    fileLink.select();
    document.execCommand('copy');
    fileLink.setSelectionRange(0, 0);
  }

  //for edit
  showEditSolutionSetModal = false;
  toggleEditSolutionSetModal(solution)
  {
    this.showEditSolutionSetModal = !this.showEditSolutionSetModal;
    this.solutionData.caseStudyId= solution.caseStudyId;
    this.solutionData.caseStudyTitle= solution.caseStudyTitle;
    this.solutionData.description=solution.description;
    this.solutionData.fileLink=solution.fileLink;
    this.solutionData.fileViewPath=solution.fileViewPath;
    this.solutionData.hasExtraFeatures=solution.hasExtraFeatures;
    this.solutionData.humanReadableId=solution.humanReadableId;
    this.solutionData.id=solution.id;
    this.solutionData.imageUrl= solution.imageUrl;  
    this.solutionData.solutionSetId = solution.solutionSetId;
    this.solutionData.type=solution.type;
    this.solutionData.vendorNameNormalized=solution.vendorNameNormalized;
    this.solutionData.videoUrl=solution.videoUrl;   
    
  }

  closeEditSolutionSetModal()
  {
    this.showEditSolutionSetModal=false;
  }
  
  UpdateSolutionSet()
  {  
    //api call to update     
    this.service.updateMySolutionSet(this.solutionData)
    .subscribe(data =>
      { 
       this.toastr.success("Solution has been updated","",{timeOut:3000, progressBar:true});   
       setTimeout(function(){
         window.location.reload();
       }, 5000);
        
      },
      error => {              
        this.toastr.error("Case Study has already been added in this Personal Solution Set","",{timeOut:3000,progressBar:true});         
        }); 
    
    this.showEditSolutionSetModal=false;
  }

  closeShowEditSolutionSetModal()
  {
    this.showEditSolutionSetModal = !this.showEditSolutionSetModal;
  }

  DeleteSolutionSetModal(setid)
  { 
    //api call to delete 
    this.service.deleteMySolutionSet(setid)
    .subscribe(data =>
    {  
      this.router.navigate(['mysolutionset',this.solutionid]).then(() => {window.location.reload();});
    },
    error => {   
      this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);         
    }); 
    
    this.showEditSolutionSetModal=false;
    this.showEditSolutionSetModal = !this.showEditSolutionSetModal;
  }

  
  candownloadFile(casestudyId,caseStudyTitle)
  {
    this.service.canDownloadForMicrosite(sessionStorage.getItem('sessionuserId'),casestudyId)
        .subscribe(data => {
          let strStatus=data['body']; 
          if(strStatus==true)
          { 
            this.downloadCaseStudy(casestudyId,caseStudyTitle);                   
          }
          else{
            this.toastr.error("You've exceeded download limit for case studies","",{timeOut:3000,progressBar:true}); 
   
          }

        }
        ,error =>{this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);}); 
  }

  downloadCaseStudy(casestudyId,caseStudyTitle)
  {  
         
    this.caseStudyParam.caseStudyId= casestudyId;
    this.caseStudyParam.caseStudyTitle= caseStudyTitle;
    this.caseStudyParam.userId = sessionStorage.getItem('sessionuserId')??"";
    this.caseStudyParam.domain= this.domainname;
    this.caseStudyParam.type="0";
    this.caseStudyParam.downloadToken = ""; 
    this.promtToSaveFile(environment.apiURL+'/api/files/downloadforMicrosites');   

    //analytics write for download case study
    this.analyticsWriteDownloadCaseStudyParam.id=casestudyId;
    this.analyticsWriteDownloadCaseStudyParam.source= CaseStudyViewSource.MyIndustolutions;
    this.analyticsWriteDownloadCaseStudyParam.domain=this.domainname;    
    this.analyticsWriteDownloadCaseStudyParam.userIP=this.userIp;
    this.analyticsWriteDownloadCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
    this.service.analyticsWriteDownloadCaseStudy(this.analyticsWriteDownloadCaseStudyParam)
    .subscribe(data=> {
    },error =>{this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);}); 
  
  }

  promtToSaveFile(url) {
    this.mainform = this.createForm(url);
    let formToken = (new Date()).getTime().toString();
    this.mainform.appendChild(this.createInput("accessToken", sessionStorage.getItem('userToken')));
    this.mainform.appendChild(this.createInput("url", url));    
    this.mainform.appendChild(this.createInput("caseStudyId", this.caseStudyParam.caseStudyId));
    this.mainform.appendChild(this.createInput("downloadToken", ""));
    this.mainform.appendChild(this.createInput("type", this.caseStudyParam.type));
    this.mainform.appendChild(this.createInput("userId", this.caseStudyParam.userId));
    this.mainform.appendChild(this.createInput("domain", this.caseStudyParam.domain));      
    this.mainform.appendChild(this.createInput("caseStudyTitle", this.caseStudyParam.caseStudyTitle));    
    const nodenative = document.createElement('script');
    nodenative.src ='assets/jquerynative.js';
    nodenative.type = 'text/javascript';
    nodenative.async = false;  
    this.mainform.appendChild(nodenative);
    const node = document.createElement('script');
    node.src ='assets/load.js';
    node.type = 'text/javascript';
    node.async = false;  
    this.mainform.appendChild(node);
    setTimeout(() => {
      this.mainform.parentNode.removeChild(this.mainform);
    }, 1000); 
    return false;
  }

  createForm(url) {  
    this.form = document.createElement("form");
    var formname=this.caseStudyParam.caseStudyId; 
    this.form.name=formname; 
    this.form.id = formname;
    this.form.style.display = "none";
    this.form.action = url;
    this.form.method = "POST"; 
    document.body.appendChild(this.form);
    return this.form;
  }

  createInput(name, value) {
    var input = document.createElement("input");
    input.name = name;
    input.value = value;
    return input;
  }

  checkFileLoaded() {
    var token = this.getCookieValue(this.tokenCookieName);

    if ((token === this.formToken) || (this.attempts === 0)) {
      this.clearLoading();
        return;
    }
    this.attempts--;
  }

  getCookieValue(name) {
    this.parts = document.cookie.split(name + "=");
    return this.parts.length === 2 ? this.parts.pop().split(";").shift() : null;
  }

  clearLoading() {
  clearInterval(this.cookieTimer);
  this.expireCookie(this.tokenCookieName);
  this.attempts = 60;
  }

  expireCookie(cName) {
  document.cookie = encodeURIComponent(cName) + "=deleted; expires=" + new Date(0).toUTCString();
  }

  public showShareSocial=false; 
  btnShareClick()
  {  
    this.showShareSocial = !this.showShareSocial;
  }
}
