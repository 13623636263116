import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallService } from '../service/apicall.service';
import { PasswordResetModel } from '../models/usermodel';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms'

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  public token:any;
  public email:any;
  public domain:any;
  public UserId:any;
  public userdata = new PasswordResetModel();
  clicked = false;
  showResetForm=true;
  errorMessage="";
  constructor(private titleService: Title,private router:Router,private route:ActivatedRoute,private service:ApicallService,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {      
      this.token=params.token;
      this.email=params.email;
      this.domain=params.domain;

      this.service.getdataforresetPassword(this.token,this.email,this.domain)    
    .subscribe(data=> { 
      this.UserId=data.userId;
      if(data.errorMessage=="Link expired" || data.errorMessage=="Invalid token"){
        this.showResetForm=false;
        this.errorMessage=data.errorMessage;
        this.toastr.error(data.errorMessage,"",{timeOut:3000,progressBar:true});
      }
      
      }
      ,error =>{
        
       }); 

    });

  }
  resetPassword()
  { 
    this.userdata.UserId=this.UserId;
    this.service.resetPassword(this.userdata)    
    .subscribe(data=> { 
      this.toastr.success("Password updated successfully","",{timeOut:3000,progressBar:true});
      this.router.navigate(['/login']) ;
      }
      ,error =>{this.clicked=false;}); 
  }
  showResetConfirmModal = false;  
  toggleResetConfirmModal(){
    this.showResetConfirmModal = !this.showResetConfirmModal;
  }
   
}
