export class SolutionSetsModel {
    imageurl:string;   
    title:string; 
  }

  export class PersonalSolutionModel {    
    public CaseStudyId	: string; 
    public Name: string; 
    public UserId :string;
    public Domain :string;
    public SolutionSetId :string;
  }