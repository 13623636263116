<app-header></app-header>

<div class="bg-cover bg-center bg-brand-gray flex items-center m-h-550 w-full mx-auto py-36 relative setbg">
    <div class="absolute w-full h-full bg-blue-900 inset-0 bg-opacity-50"></div>
    <div class="z-10 w-full max-w-7xl mx-auto">
        <section class="text-white body-font overflow-hidden px-2 sm:px-6 lg:px-8">
            <div class="w-full">
                <h1 class="text-white text-2xl md:text-4xl font-bold mb-8 px-3 md:px-0 uppercase"
                    style="white-space: pre-line;">JOIN NOW FOR ACCESS TO SOLUTIONS ACROSS ALL INDUSTRIES</h1>
            </div>
        </section>
    </div>
</div>
<div class="bg-brand-gray text-white">
    <div class="md:flex w-full max-w-7xl mx-auto py-10 px-6 sm:px-6 lg:px-8 items-center text-center">
        <div class="text-left">

            <div class="text-md">Sign up to get access to the additional benefits of a myContentreads page. It’s free
                and only takes 20 seconds to get started.
            </div>
        </div>
        <a (click)="setTitle('Register')"
            class="inline-block border-2 border-white rounded-full uppercase text-sm font-bold hover:bg-brand-green text-white px-3 py-4 ml-4 mt-4 md:mt-0 whitespace-nowrap inline-block break-all"
            routerLink="/register" routerLinkActive="active">Join {{domainname}}</a>
    </div>
</div>

<div class="bg-white">
    <div class="max-w-screen-xl mx-auto relative py-10">
        <h1 class="text-center text-4xl font-bold mb-12 uppercase text-brand-gray">Benefits</h1>

        <div class="grid gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
            <div class="shadow-lg text-center p-10">
                <div
                    class="mx-auto inline-block rounded-full h-24 w-24 flex items-center justify-center bg-gray-200 p-3 mb-3">
                    <img src="../assets/images/benefits-1.svg">
                </div>
                <h4 class="uppercase text-md font-medium mb-3">organized</h4>
                <p>Easily save, organize and retrieve all the industry solutions you have discovered on
                    {{ domainname}}.</p>
            </div>
            <div class="shadow-lg text-center p-10">
                <div
                    class="mx-auto inline-block rounded-full h-24 w-24 flex items-center justify-center bg-gray-200 p-3 mb-3">
                    <img src="../assets/images/benefits-2.svg"></div>
                <h4 class="uppercase text-md font-medium mb-3">permanent</h4>
                <p>myContentreads is your free and permanent reference bank of solutions you curate and will use
                    throughout your career.</p>
            </div>
            <div class="shadow-lg text-center p-10">
                <div
                    class="mx-auto inline-block rounded-full h-24 w-24 flex items-center justify-center bg-gray-200 p-3 mb-3">
                    <img src="../assets/images/benefits-3.svg"></div>
                <h4 class="uppercase text-md font-medium mb-3">collaborative</h4>
                <p>Easily exchange industry solution ideas with other {{ domainname}} members. Share, collaborate
                    and learn from other industry experts.</p>
            </div>
        </div>
    </div>
</div>


<div class="bg-white">
    <div class="max-w-screen-xl mx-auto relative pt-10 pb-20 text-center px-6 sm:px-6 lg:px-8">
        <!-- <h3 class="text-3xl font-bold mb-3 text-gray-600">Trusting us with your information</h3>
            <p class="text-gray-500 mb-6">We personally hate signing up to websites only to then receive waves of unsolicited emails from vendors and the site itself. We will never allow that to happen to you as a member of {{domainname}}.</p> -->

        <a (click)="setTitle('Register')"
            class="bg-brand-green hover:bg-brand-gray rounded-full text-white mt-5 px-4 py-3 uppercase inline-block break-all"
            routerLink="/register" routerLinkActive="active">Join {{domainname}}</a>
    </div>
</div>


<app-pagefooter></app-pagefooter>