import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApicallService } from '../service/apicall.service';
import { addCsToMyIndustolutionsModel, analyticsWriteDownloadCaseStudyModel, analyticsWriteModel, CaseStudyViewSource, downloadCaseStudyModel, searchparams,pdfDownloadModel } from '../common/searchparams';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-downloadfile',
  templateUrl: './downloadfile.component.html',
  styleUrls: ['./downloadfile.component.scss']
})
export class DownloadfileComponent implements OnInit {
  public pdfDownloadparam = new pdfDownloadModel();
  public analyticsWriteDownloadCaseStudyParam = new analyticsWriteDownloadCaseStudyModel();
  domainname:any; 
  userIp:any;
  public mainform ;
  public mainformpdf;
  public formpdf;
  public form ;
  public id:string='';
  public isLoggedIn;
  public caseStudyParam = new downloadCaseStudyModel(); 
  public casestudytitle;
  constructor(private router:Router,private service:ApicallService,private toastr:ToastrService, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.id=params.id;
      }
    );  
    if(sessionStorage.getItem('sessionuserId')!=null)
	    this.isLoggedIn=true;
    else
	    this.isLoggedIn=false; 
    if(this.id!="" && this.isLoggedIn)
    {
      this.openCaseStudy(this.id);
    }
    else{
      window.location.href='/login;redirectUri='+location.pathname.substr(1)+"||id||"+this.id;
    }
  }
  openCaseStudy(caseStudyId)
  { 
      //api to get humanReadableId and titleNormalized   
      this.service.getCaseStudyUrlParts(caseStudyId)    
      .subscribe(data => {
                this.casestudytitle=  data['titleNormalized']; 
                this.candownloadFile(caseStudyId);
        }
        ,(error) => {           
        }); 
  }
  /* for download start*/
  candownloadFile(casestudyId)
  {
    this.service.canDownloadForMicrosite(sessionStorage.getItem('sessionuserId'),casestudyId)
        .subscribe(data => {
          let strStatus=data['body']; 
          if(strStatus==true)
          { 
            this.downloadCaseStudy(casestudyId);                   
          }
          else{
            this.toastr.error("You've exceeded download limit for case studies","",{timeOut:3000,progressBar:true}); 
   
          }

        }
        ,error =>{this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);}); 
  }

  downloadCaseStudy(casestudyId)
  {   
    //if session expired
    this.caseStudyParam.caseStudyId= casestudyId;
    this.caseStudyParam.caseStudyTitle=  this.casestudytitle ;
    this.caseStudyParam.userId = sessionStorage.getItem('sessionuserId')??"";
    this.caseStudyParam.domain= this.domainname;
    this.caseStudyParam.type="0";
    this.caseStudyParam.downloadToken = ""; 
    this.promtToSaveFile(environment.apiURL+'/api/files/downloadforMicrosites');

    //analytics write for download case study
    this.analyticsWriteDownloadCaseStudyParam.id=casestudyId;
    this.analyticsWriteDownloadCaseStudyParam.source= CaseStudyViewSource.Other;
    this.analyticsWriteDownloadCaseStudyParam.domain=this.domainname;    
    this.analyticsWriteDownloadCaseStudyParam.userIP=this.userIp;
    this.analyticsWriteDownloadCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
    this.service.analyticsWriteDownloadCaseStudy(this.analyticsWriteDownloadCaseStudyParam)
    .subscribe(data=> {
    },error =>{this.router.navigate(['/login']) ; }); 
     
    
  }
  promtToSaveFile(url) {   
    this.mainform = this.createForm(url);    
    this.mainform.appendChild(this.createInput("accessToken", sessionStorage.getItem('userToken')));
    this.mainform.appendChild(this.createInput("url", url));    
    this.mainform.appendChild(this.createInput("caseStudyId", this.caseStudyParam.caseStudyId));
    this.mainform.appendChild(this.createInput("downloadToken", ""));
    this.mainform.appendChild(this.createInput("type", this.caseStudyParam.type));
    this.mainform.appendChild(this.createInput("userId", this.caseStudyParam.userId));
    this.mainform.appendChild(this.createInput("domain", this.caseStudyParam.domain));      
    this.mainform.appendChild(this.createInput("caseStudyTitle", this.caseStudyParam.caseStudyTitle)); 
    const nodenative = document.createElement('script');
    nodenative.src ='assets/jquerynative.js';
    nodenative.type = 'text/javascript';
    nodenative.async = false;  
    this.mainform.appendChild(nodenative);
    const node = document.createElement('script');
    node.src ='assets/load.js';
    node.type = 'text/javascript';
    node.async = false;  
    this.mainform.appendChild(node);
    this.mainform.submit();
    this.mainform.parentNode.removeChild(this.mainform);
    return false;
  }
  
createForm(url) {  
  this.form = document.createElement("form");
  var formname=this.caseStudyParam.caseStudyId;
  this.form.name=formname; 
  this.form.id = formname;
  this.form.style.display = "none";
  this.form.action = url;
  this.form.method = "POST"; 
  document.body.appendChild(this.form);
  return this.form;
}

createInput(name, value) {
  var input = document.createElement("input");
  input.name = name;
  input.value = value;
  return input;
}

}


