<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10 w-full max-w-7xl mx-auto px-8 pt-8">
        <section class="text-white body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <h1 class="text-white text-5xl md:text-6xl font-semibold mb-4 break-all px-3 md:px-0 uppercase">
                    myContentreads Solution</h1>
                <h2 class="text-white text-3xl font-light uppercase mb-4">Find Proven Solutions To Your Operating
                    Challenges</h2>
            </div>
        </section>
    </div>
</div>

<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4">
            <ul class="flex list-none flex-wrap flex-row m-0 shadow">
                <li class="p-0">
                    <a class="text-gray-600 py-4 px-2 sm:px-6 block hover:text-brand-gray focus:outline-none cursor-pointer"
                        (click)="toggleTabs(1)"
                        [ngClass]="{'bg-white': openTab !== 1, 'text-brand-gray border-b-2 font-bold border-brand-green': openTab === 1}">Personal
                        Solution Sets</a>
                </li>
                <li class="p-0">
                    <a class="text-gray-600 py-4 px-2 sm:px-6 block hover:text-brand-gray focus:outline-none cursor-pointer"
                        (click)="toggleTabs(2)"
                        [ngClass]="{'bg-white': openTab !== 2, 'text-brand-gray border-b-2 font-bold border-brand-green': openTab === 2}">Downloaded
                        Solutions</a>
                </li>
            </ul>
            <div class="content py-5">
                <div class="tab-content tab-space">
                    <div class="" [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">
                        <div class="lg:flex">
                            <div class="mr-4 mb-4">
                                <a (click)="toggleCreateSolutionSetModal()"
                                    class="cursor-pointer modal-open text-xs text-black font-thin font-bold text-gray-500">
                                    <div
                                        class="box-content bg-gray-100 h-60 w-60 flex items-center justify-center shadow-md hover:shadow-2xl">
                                        <div>
                                            <div
                                                class=" mx-auto mt-4  w-20 h-20 p-3 border-2 border-brand-green rounded-full">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                                                    <defs>
                                                    </defs>
                                                    <title>add-vsc</title>
                                                    <g id="Слой_2" data-name="Слой 2">
                                                        <g id="Слой_1-2" data-name="Слой 1">
                                                            <line
                                                                class="fill-none stroke-1 stroke-current text-brand-green"
                                                                x1="9" x2="9" y2="18" />
                                                            <line
                                                                class="fill-none stroke-1 stroke-current text-brand-green"
                                                                y1="9" x2="18" y2="9" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div class="mt-3">Add a Solution Set</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="">
                                <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                                    <div *ngFor="let solutionset of solutionsetList">
                                        <div
                                            class="cursor-pointer box-content p-2 bg-gray-100 shadow-md hover:shadow-2xl relative">
                                            <div class="flex relative group justify-between w-56">
                                                <div class="block w-48 h-60 hover:shadow-xl cursor-pointer">
                                                    <a (click)="gotoMysolutionset(solutionset.id)">
                                                        <div class="grid gap-0 grid-cols-2">
                                                            <div *ngFor="let set of solutionset['solutionsImages']">
                                                                <div class="w-20 h-20 flex mb-1">
                                                                    <img class="" src="{{set}}" alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="hidden group-hover:block d-sm-only relative">
                                                    <div class="mx-auto">
                                                        <svg class="text-gray-400 hover:text-gray-600 cursor-pointer icon-svg w-6 h-6"
                                                            (click)="toggleEditSolutionSetModal(solutionset)"
                                                            fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-48 break-all pt-3">
                                                {{ (solutionset.name.length > 50) ? (solutionset.name | slice:0:50) + '...' : solutionset.name }}
                                            </div>
                                            <div
                                                class="rounded-full bg-brand-green text-white absolute -bottom-3 -right-3 z-10 h-10 w-10 flex items-center justify-center">
                                                {{solutionset.solutionsCount}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}">
                        <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
                            <div *ngFor="let case of downloadedCaseStudyList">
                                <div
                                    class="cursor-pointer box-content p-2 bg-gray-100 shadow-md hover:shadow-2xl relative">
                                    <a (click)="setTitle('Case Study'); gotoCaseStudy(case.fileName)">
                                        <img *ngIf="!case['videoUrl']" src="{{case.imageUrl}}" alt="Image"></a>
                                    <h4 class="w-48 break-all pt-3">
                                        {{ (case.simpleTitle.length > 50) ? (case.simpleTitle | slice:0:50) + '...' : case.simpleTitle }}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showCreateSolutionSetModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 md:mx-10">
        <div class="">
            <div class="flex items-start w-full">
                <p class="text-gray-900 font-medium text-lg ">Create Solution Set</p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    (click)="toggleCreateSolutionSetModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <div class="my-5 pt-4">
                <form #createSolutionSetForm="ngForm" class="w-full">
                    <div class="form-group">
                        <label for="Name" class=" block ">Name</label>
                        <input type="text" #Name="ngModel" required maxlength="50"
                            class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="Name" name="Name" [(ngModel)]="solutionData.Name"
                            [ngClass]="{'red-border-class': Name.errors && (Name.dirty || Name.touched)}">
                        <div *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="alert">
                            <div *ngIf="Name.errors?.required" class="text-red-500 -mt-3">Set name can not be empty.
                            </div>
                            <div *ngIf="Name.errors?.maxlength" class="text-red-500 -mt-3">Set name length can not be
                                more
                                than 50 characters.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Description" class=" block ">Description</label>
                        <input type="text" #Description="ngModel" maxlength="400"
                            class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="What's your Solution Set about?" name="Description"
                            [(ngModel)]="solutionData.Description"
                            [ngClass]="{'red-border-class': Description.errors && (Description.dirty || Description.touched)}">
                    </div>
                    <div class="flex justify-between mt-5">
                        <button
                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="toggleCreateSolutionSetModal()">Cancel
                        </button>
                        <button
                            class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            [disabled]="!createSolutionSetForm.valid"
                            [ngClass]="{'disabledbtn': !createSolutionSetForm.valid}" (click)="SaveSolutionSet()">Save
                            Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showCreateSolutionSetModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<!--Edit Solution Set-->
<div *ngIf="showEditSolutionSetModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 md:mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-start w-full">
                <p class="text-gray-900 font-medium text-lg ">Edit Solution Set </p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    (click)="closeEditSolutionSetModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <div class="my-5 pt-4">
                <form #editSolutionSetForm="ngForm" class="w-full">
                    <div class="form-group">
                        <label for="Name" class=" block ">Name</label>
                        <input type="text" #Name="ngModel" required maxlength="50"
                            class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="Name" name="Name" [(ngModel)]="solutionData.Name"
                            [ngClass]="{'red-border-class': Name.errors && (Name.dirty || Name.touched)}">

                        <div *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="alert">
                            <div *ngIf="Name.errors?.required" class="text-red-500 -mt-3">Set name can not be empty.
                            </div>
                            <div *ngIf="Name.errors?.maxlength" class="text-red-500 -mt-3">Set name length can not be
                                more
                                than 50 characters.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Description" class=" block ">Description</label>
                        <input type="text" #Description="ngModel" maxlength="400"
                            class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="What's your Solution Set about?" name="Description"
                            [(ngModel)]="solutionData.Description"
                            [ngClass]="{'red-border-class': Description.errors && (Description.dirty || Description.touched)}">
                        <div *ngIf="Description.errors && (Description.dirty || Description.touched)">
                            <div [hidden]="!Description.errors.required" class="text-red-500">
                                Set description length can not be more than 400 characters.
                            </div>
                        </div>
                    </div><br />
                    <div class="flex justify-between mt-5">
                        <button
                            class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="DeleteSolutionSetModal(solutionData.Id)">Delete</button>
                        <button
                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="closeShowEditSolutionSetModal()">Cancel</button>
                        <button
                            class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            [disabled]="!editSolutionSetForm.valid"
                            [ngClass]="{'disabledbtn': !editSolutionSetForm.valid}" (click)="UpdateSolutionSet()">Save
                            Changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showEditSolutionSetModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<app-pagefooter></app-pagefooter>