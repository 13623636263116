<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
      <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
      <div class="text-center z-10 w-full max-w-7xl mx-auto px-8 pt-8">
            <section class="text-white body-font overflow-hidden">
                  <div class="w-full px-1 py-5">
                       <h1 class="text-white text-2xl md:text-6xl font-semibold uppercase break-words">PRIVACY POLICY
                        </h1>
                  </div>
            </section>
      </div>
</div>
<div class="bg-gray-100">
      <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16">
            
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4">

          <p class="mb-2">Effective on: October 6, 2023</p>
          <h2 class="text-xl mb-3 font-bold">Introduction and Scope</h2>
          <p class="mb-2">Contentreads (a trading name of Contentgine, Inc), together with its affiliates and aliases described below, (collectively, “Contentreads”, “we”, “us”, “our”) takes the protection of personally identifiable information (“Personal Data”) very seriously. Contentreads provides business customers (“Customers”) with lead generation services, including content syndication, data analytics and email marketing services, as well as our Content Indication Platform (collectively, the “Lead Generation Services”). This Privacy Notice (the “Notice”) addresses how we collect, receive, use, store, share, transfer and process your Personal Data, as well as your rights in determining what we do with the information collected via our website and in connection with our Lead Generation Services (collectively, the “Services”). This Notice does not apply to Personal Data we collect by other means, such as Personal Data of our employees.</p>
          <h2 class="text-xl mb-3 font-bold">Controllership</h2>
          <p class="mb-2">Within the scope of this Notice, Contentreads (a trading name of Contentgine, Inc.), acts as a data controller for the Personal Data we process when you visit our websites or when you interact with us. Contentreads is acting as Data Processor for the Personal Data we process through our Services. For more information about this, visit <a href="https://contentreads.com/privacy">https://contentreads.com/privacy</a></p>
          <h2 class="text-xl mb-3 font-bold">Categories of Personal Data</h2>
          <div>
            In this privacy policy we refer to “Leads,” “Customer Representatives” and
            “Website Visitors”, as follows: (1) if you are a Lead, you are an individual about
            whom we process Personal Data in connection with the Lead Generation
            Services (for example, individuals who interact with content that we send in
            emails on behalf of our Customers or content available on our websites); (2) if
            you are a Customer Representative, you are an employee, contractor or other
            individual authorized by a Customer to use our Services and we provide you
            with Personal Data about the Leads; (3) if you are a Website Visitor, you are an
            individual who visits our website and you do not fall under the Lead or Customer
            Representative categories.
            If you are a Lead, we process the following types of Personal Data about you:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>biographical information, such as first and last name and date of birth; and contact information, such as email address, phone number, postal address, and country of residence postal;</li>
              <li>employment information, such as industry, job title, company name, seniority within company, department name, or company size;</li>
              <li>business contact information, such as company email address and postal address; and public LinkedIn profile;</li>
              <li>analytics information, including the type of content that you engage with when using the Services, or after clicking a link in an outreach email;</li>
              <li>device, internet and mobile information such as the hardware model, operating system version, unique device identifiers, browser type, language, IP address, wireless network, and mobile network information (including the mobile phone number);</li>
              <li>geo-location information when you use the Services; and/or</li>
              <li>records and copies of your correspondence (including e-mail addresses), if you contact us. This may include call recordings where we monitor and record such calls for staff training or quality assurance purposes.</li>
            </ul>
            If you are a Customer Representative, we process the following types of
            Personal Data about you:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>
                business contact information such as first and last name, email address,
                phone number, postal address;
              </li>
              <li>
                billing and payment information, including the address for billing, billing
                contact information and credit card information;
              </li>
              <li>
                device, internet and mobile information such as the hardware model,
                operating system version, unique device identifiers, browser type,
                language, IP address, wireless network, and mobile network information
                (including the mobile phone number);
              </li>
              <li>
                geo-location information when you use the Services; and/or records and
                copies of your correspondence (including e-mail addresses), if you
                contact us. This may include call recordings where we monitor and record
                such calls for staff training or quality assurance purposes.
              </li>
            </ul>
            If you are a Website Visitor, we process the following types of Personal Data
            about you:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>
                contact information such as first and last name, email address and phone
                number;
              </li>
              <li>
                device, internet and mobile information such as the hardware model,
                operating system version, unique device identifiers, browser type,
                language, IP address, wireless network, and mobile network information
                (including the mobile phone number);
              </li>
              <li>
                geo-location information when you use our website; and/or
              </li>
              <li>
                records and copies of your correspondence (including e-mail addresses),
                if you contact us. This may include call recordings where we monitor and
                record such calls for staff training or quality assurance purposes.
              </li>
            </ul>
            We do not process information that may be considered ‘special category’ or
            ‘sensitive’ Personal Data, such as health related information or information
            relating to racial origin or political affiliations.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">How We Receive Personal Data</h2>
          <div>
            If you are a Lead, we receive your Personal Data when:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>
                you provide it directly to us through our Services, or by clicking a link in a
                Contentreads outreach email;
              </li>
              <li>our Customers (including Customer Representatives) provide it to us;</li>
              <li>we receive it from other companies within our corporate group;</li>
              <li>our service providers provide it to us;</li>
              <li>
                we obtain lists from our business partners and data vendors with respect
                to individuals who might be interested in becoming customers of or are
                otherwise interested in our Customers;
              </li>
              <li>
                you provide it on publicly accessible sources, such as on LinkedIn or your
                company website; and/or
              </li>
              <li>
                such information is captured via cookies and similar technologies
                (collectively referred to as “cookies”), as outlined in the “Cookie” section
                below.
              </li>
            </ul>
            If you are a Customer Representative, we receive your Personal Data when:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>you provide it directly to us through our Services;</li>
              <li>our Customers provide it to us; and/or</li>
              <li>
                such information is captured via cookies and similar technologies
                (collectively referred to as “cookies”) as outlined in the “Cookie” section
                below.
              </li>
            </ul>
            If you are a Website Visitor, we receive your Personal Data when:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>you provide it directly to us through our website; and/or</li>
              <li>
                such information is captured via cookies and similar technologies
                (collectively referred to as “cookies”), as outlined in the “Cookie” section
                below.
              </li>
            </ul>
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Purposes of Processing</h2>
          <div>
            If you are a Lead, we process your Personal Data to:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>respond to your requests or questions;</li>
              <li>
                provide you with access to the Services and the content therein, including
                content that we send you on behalf of our Customers;
              </li>
              <li>
                create a record for our Customers about your interactions, which we may
                enhance with additional information about you (e.g. your name, job title,
                location and email address); and/or
              </li>
              <li>
                share your Personal Data with our Customers in connection with the Lead
                Generation Services, including to help them improve their business,
                target marketing campaigns and services through different strategies.
              </li>
            </ul>
            If you are a Customer Representative, we process your Personal Data to:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>respond to your requests or questions;</li>
              <li>
                process and complete transactions, including to obtain sales leads, and
                send you related information;
              </li>
              <li>provide technical and other support to you;</li>
              <li>
                send you promotional communications, such as providing you with
                information about services, features, surveys, newsletters, offers, and
                events; and providing other news or information about us, and the Lead
                Generation Services;
              </li>
              <li>
                enable our service providers to perform certain responsibilities and
                provide certain services in connection with the Lead Generation Services
                and our business and operations
              </li>
              <li>provide you with notices about your account;</li>
              <li>
                carry out our obligations and enforce our rights arising from any contracts
                entered into between you and us, including for billing and collection;
                and/or verify your identity and prevent fraud or other unauthorized or
                illegal activity.
              </li>
            </ul>
            If you are a Website Visitor, we process your Personal Data to:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>provide you with access to our website;</li>
              <li>respond to your requests or questions;</li>
              <li>provide technical and other support to you; and/or</li>
              <li>
                send you promotional communications, such as providing you with
                information about services, features, surveys, newsletters, offers, and
                events; and providing other news or information about us, and the Lead
                Generation Services.
              </li>
            </ul>
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Basis of Processing</h2>
          <div>
            We process your Personal Data on the basis of:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>
                your consent (you may withdraw your consent at any time by contacting
                us via the information in the “Contact Us” section below. This will not
                affect the lawfulness of our processing before you withdrew your consent.
                It will also not affect processing performed on other lawful grounds);
              </li>
              <li>
                if you are outside Canada, our legitimate interests in providing effective
                Services to our Customers and maintaining our business relationship;
              </li>
              <li>the need to comply with the law; or</li>
              <li>any other ground, as required or permitted by law.</li>
            </ul>
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">DATA PRIVACY FRAMEWORK</h2>
          <div>
            Contentreads complies with the EU-U.S. Data Privacy Framework (EU-U.S. DPF) and the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) as set forth by the U.S. Department of Commerce.  
<br/>
Contentreads has certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the processing of personal data received from the European Union in reliance on the EU-U.S. DPF. Contentreads has certified to the U.S. Department of Commerce that it adheres to the Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF Principles) with regard to the processing of personal data received from Switzerland. If there is any conflict between the terms in this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern. To learn more about the Data Privacy Framework (DPF) program, and to view our certification, please visit <a href="https://www.dataprivacyframework.gov/">https://www.dataprivacyframework.gov/</a>.
<br/>
Contentreads is responsible for the processing of personal data it receives, under the DPF, and subsequently transfers to a third party acting as an agent on its behalf. Contentreads complies with the DPF Principles for all onward transfers of personal data from the EU and Switzerland, including the onward transfer liability provisions.
<br/>
The Federal Trade Commission has jurisdiction over Contentreads’s compliance with the EU-U.S. DPF and Swiss-U.S. DPF.  In certain situations, Contentreads may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
<br/>
In compliance with the EU-U.S. DPF and the Swiss-U.S. DPF, Contentreads commits to refer unresolved complaints concerning our handling of personal data received in reliance on the EU-U.S. DPF and the Swiss-U.S. DPF to TRUSTe, an alternative dispute resolution provider based in the United States.  If you do not receive timely acknowledgment of your DPF Principles-related complaint from us, or if we have not addressed your DPF Principles-related complaint to your satisfaction, please visit <a href="https://feedback-form.truste.com/watchdog/">https://feedback-form.truste.com/watchdog/</a> request for more information or to file a complaint. These dispute resolution services are provided at no cost to you.
<br/>

For complaints regarding DPF compliance not resolved by any of the other DPF mechanisms, you have the possibility, under certain conditions, to invoke binding arbitration. Further information can be found on the official DPF website.
      
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Data Retention</h2>
          <div>
            We will retain your Personal Data for the period necessary for the purposes of
            processing listed in this notice unless a longer retention period is required by
            law or if it is reasonably necessary for us to comply with our legal obligations,
            or to resolve a dispute. If you stop using our Services, we will store your
            information in an aggregated and anonymized format; we may use the
            anonymized information indefinitely without further notice to you.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Sharing Personal Data with Third Parties</h2>
          <div>
            We share Personal Data with our affiliates and Customers, as well as with our
            service providers who process Personal Data on our behalf and who agree to
            use the Personal Data only to assist us in providing our Services to our
            Customers, or as required by law. We share Personal Data of Leads with our
            Customers as specified in the “Purposes of Processing” section above. We
            share Personal Data with our service providers in relation to the following
            services they provide to us:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>application hosting services;</li>
              <li>cloud storage services;</li>
              <li>business productivity services;</li>
              <li>communication services and software;</li>
              <li>email verification software;</li>
              <li>customer relationship management (CRM) software; and</li>
              <li>contact verification and data enrichment services</li>
            </ul>
            However, before transferring your Personal Data to these third parties, we will
            either ask for your explicit consent or require the third party to maintain at least
            the same level of privacy and security for your Personal Data that we do. We
            remain liable for the protection of your Personal Data that we transfer or have
            transferred to third parties through our designated data transfer mechanism,
            except to the extent that we are not responsible for the event that leads to any
            unauthorized or improper processing.
            <br>
            Personal Data may be stored or processed by us and/or our third party service
            providers outside of your jurisdiction, including to the United States. When this
            occurs, your information may be accessible without notice to you by the courts,
            law enforcement and national security authorities of that jurisdiction. We may
            also transfer Personal Data to third countries outside of the European Union,
            the EEA, the UK or Canada. The European Commission and/or the UK
            government may have determined that some of these third countries do not
            provide an adequate level of protection with respect to Personal Data. We will
            only transfer your Personal Data to third parties in these countries when there
            are appropriate safeguards in place. These include the Standard Contractual
            Clauses (“SCCs”) as approved by the European Commission or the equivalent
            mechanism approved by the UK government. Such mechanisms are formally
            integrated into our agreements with third parties when the processing is subject
            to EEA and/or UK data protection laws.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Other Disclosure of Your Personal Data</h2>
          <div>
            We disclose your Personal Data to the extent required by law, or if we have a good-faith belief that we need to disclose it in order to comply with official investigations or legal proceedings (whether initiated by governmental/law enforcement officials, or private parties). We may also disclose your Personal Data if we sell or transfer all or some of our company’s business interests, assets, or both, or in connection with a corporate restructuring. Finally, we may disclose your Personal Data to our subsidiaries or affiliates, but only if necessary for business purposes, as described in the section above.
            We reserve the right to use, transfer, sell, and share aggregated, anonymous data for any legal business purpose. Such data does not include any Personal Data. The purposes may include analyzing usage trends or seeking compatible advertisers, sponsors, and customers.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Direct Marketing</h2>
          <div>
            Where required by applicable law, we will only send you direct marketing communications, such as through email, including offers any news and exclusive offers, promotions, or events, where you have consented to do so.
            You may opt-out of receiving direct marketing communications at any time by contacting us or by using opt-out facilities provided in the direct marketing communications.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Cookies</h2>
          <div>
            A “cookie” is a small file stored on your device that contains information about your device. We may use cookies to provide basic relevant ads, website functionality, authentication (session management), usage analytics (web analytics), and to remember your settings, and generally improve our websites and services.

We use session and persistent cookies. Session cookies are deleted when you close your browser. Persistent cookies may remain even after you close your browser, but always have an expiration date. Please refer to the policies of these third parties to learn more about the way in which they collect and process information about you.
If you would prefer not to accept cookies, you can change the setup of your browser to reject all or some cookies. For more information, please visit <a href="https://www.aboutcookies.org/">https://www.aboutcookies.org/</a>.
<br/>
 Currently, various browsers offer a “do not track” or “DNT” option which sends a signal to websites visited by the user about the user’s browser DNT preference setting. We do not currently commit to responding to browsers’ DNT signals with respect to our website, in part, because no common industry standard for DNT has been adopted, including no consistent standard of interpreting user intent.
We and third parties on our digital properties may use cookies and similar tracking technologies to collect information and infer your interests for interest-based advertising purposes. If you would prefer to not receive personalized ads based on your browser or device usage, you may generally express your opt-out preference to no longer receive tailored advertisements. Please note that you will continue to see advertisements, but they will no longer be tailored to your interests.
<br/>
To opt-out of interest-based advertising by participating companies in the following consumer choice mechanisms, please visit: 
<br/>
-Digital Advertising Alliance (DAA)’s self-regulatory opt-out page (<a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>) and mobile application-based "AppChoices" download page (<a href="https://youradchoices.com/appchoices">https://youradchoices.com/appchoices</a>)
            <br/>
-European Interactive Digital Advertising Alliiance (EDAA)'s consumer opt-out page (<a href="http://youronlinechoices.eu">http://youronlinechoices.eu</a>)
            <br/>
-Network Advertising Initiative (NAI)’s self-regulatory opt-out page (<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>).
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Data Integrity & Security</h2>
          <div>
            We have implemented and will maintain technical, administrative, and physical
            measures that are reasonably designed to help protect Personal Data from
            unauthorized processing. This includes unauthorized access, disclosure,
            alteration, or destruction. Such measures, which may necessarily evolve over
            time as the availability and effectiveness of methods vary, include physical,
            organizational and technological safeguards.
            <br/>
            Still, no system can be guaranteed to be 100% secure. If you have questions about the security of your Personal Data, or if you have reason to believe that the Personal Data that we hold about you is no longer secure, please contact us immediately as described in this Privacy Notice.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Your California Privacy Rights</h2>
          <p class="mb-2">
            California Civil Code Section §1798.83 permits users of the Site that are California residents to request certain information regarding our disclosure of Personal Data to third parties for their direct marketing purposes. To make such a request, please send an e-mail to us at: privacy@contentreads.com or write to us at: Contentreads, 316 California Ave. #47, Reno, NV, 89509.
          </p>
          <p class="mb-2">

            If you are a California resident, you may have rights under the Consumer Privacy Act of 2018 and the related regulations (the “CCPA”) regarding your “Personal Information” (as defined in the CCPA.) This section does not apply to information exempted from the scope of the CCPA.
          </p>
          <p class="mb-2">
            The below table explains our practices in the preceding 12 months related to the categories and types of Personal Information that we collect about you and the categories of third parties that we disclosed and/or sold this information to. We do not have actual knowledge that we sell Personal Information of individuals under 16 years of age.
          </p>
          <div class="border border-slate-700">
            <div class="flex flex-col">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-x-auto">
                    <table class="min-w-full border-collapse table-auto w-full">
                      <thead>
                        <tr>
                          <th class="border-b dark:border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-left">
                            Category
                          </th>
                          <th class="border-b dark:border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-left">
                            Types of Information
                          </th>
                          <th class="border-b dark:border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-left">
                            Categories of recipients for a business purpose
                          </th>
                          <th class="border-b dark:border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-left">
                            Categories of recipients for selling
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white dark:bg-slate-800">
                        <tr>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Identifiers
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Name,
                            title, business email
                            address, business address,
                            business phone number,
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Our
                            affiliates, cloud storage
                            providers, payment processors,
                            security vendors, website hosting
                            vendors
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Customers (to
                            provide the Lead
                            Generation Services)
                          </td>
                        </tr>
                        <tr>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Personal
                            information
                            listed in the
                            California Customer
                            Records statute
                            (which may overlap
                            with other
                            categories)
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Name,
                            address, phone number
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Our
                            affiliates, cloud storage
                            providers, payment processors,
                            security vendors, website hosting
                            vendors
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Customers (to
                            provide the Lead
                            Generation Services)
                          </td>
                        </tr>
                        <tr>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Characteristics of
                            protected
                            classifications
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            None
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Our
                            affiliates, cloud storage
                            providers
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            None
                          </td>
                        </tr>
                        <tr>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Geolocation data
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Business
                            address
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Our
                            affiliates, cloud storage providers, payment processors,
                            security vendors, website hosting
                            vendors
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Customers (to provide the Lead
                            Generation Services)
                          </td>
                        </tr>
                        <tr>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Commercial
                            information
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            None
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            Our
                            affiliates, cloud storage
                            providers, payment processors,
                            security vendors, website hosting
                            vendors
                          </td>
                          <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 ">
                            None
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <p class="mb-2">
            As a California resident, you may have the rights listed below in relation to personal
            information that we
            have collected about you. However, these rights are not absolute, and in certain cases, we may
            decline your
            request as permitted by law.
          </p>
          <ul class="mb-2 ml-2 list-disc list-inside">
            <li>
              <u>Right to Know</u>. You have a right to request the following information about our
              collection, use and
              disclosure of your personal information over the prior 12 months, and ask that we provide
              you with
              a copy of the following:
              <ul class="mb-2 ml-4 list-disc list-inside">
                <li>
                  categories of and specific pieces of personal information we have collected
                  about you;
                </li>
                <li>
                  categories of sources from which we collect personal information;
                </li>
                <li>
                  the business of commercial purposes for collecting personal information;
                </li>
                <li>
                  categories of third parties to whom the personal information was disclosed for a
                  business
                  purpose; and
                </li>
                <li>
                  categories of personal information disclosed about you for a business purpose.
                </li>
              </ul>
            </li>
            <li>
              <u>Right to Delete</u>. You have a right to request that we delete personal information,
              subject to certain
              exceptions.
            </li>
            <li>
              <u>Right to Opt Out</u>. You have a right to opt out of the sale of your personal
              information to third
              parties. To opt out of the sale of your personal information, please click <a class="text-brand-green underline" href="/ccpa-privacy-policy-complaint">
                <u>
                  “Do Not
                  Sell My Info”
                </u>
              </a>
              link or reach out using one of the methods outlined below.
            </li>
          </ul>

          <p class="mb-2">
            To submit a request to exercise any of your California privacy rights, please contact
            us by emailing
            <a class="text-brand-green underline"
               href="mailto:privacy@Contentreads.com">privacy@Contentreads.com</a>.
          </p>
          <p class="mb-2">
            We may need to verify your identity before processing your request, and may ask you
            for limited
            information, such as your email address, government issued ID before providing a substantive
            response to
            the request. You may designate, in writing or through a power of attorney document, an
            authorized agent to
            make requests on your behalf to exercise your rights. Before accepting such a request from an
            agent, we will
            require that the agent provide proof you have authorized them to act on your behalf, and we may
            need you to
            verify your identity directly with us.
          </p>
          <p class="mb-2">
            We will not discriminate against you for exercising any of your rights under the
            CCPA.
          </p>



          <h2 class="text-xl mb-3 font-bold">Other Privacy Rights</h2>
          <div>
            Depending on where you live, you may have some or all of the rights listed
            below in relation to Personal Data that we have collected about you. However,
            these rights are not absolute, and in certain cases, we may decline your request
            as permitted by law. :
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>
                Confirmation of existence of processing: you may have the right to
                confirm that we process Personal Data about you;
              </li>
              <li>
                Access: you may have the right to access Personal Data we hold about
                you, how we use it, and who we share it with;
              </li>
              <li>
                Portability: you may have the right to receive a copy of the Personal Data
                we hold about you and to request that we transfer it to a third party, in
                certain circumstances and with certain exceptions;
              </li>
              <li>
                 Correction: you may have the right to correct any of your Personal Data we hold that is inaccurate;
              </li>
              <li>
                Anonymization: you may have the right to anonymization, blocking or
                elimination of Personal Data that is unnecessary, excessive or processed
                in a way that is noncompliant with the provisions of laws of certain
                jurisdictions;
              </li>
              <li>
                Erasure: you may have the right to delete the Personal Data we hold
                about you;
              </li>
              <li>
                Restriction of processing to storage only: you may have the right to
                require us to stop processing the Personal Data we hold about you, other
                than for storage purposes, in certain circumstances;
              </li>
              <li>
                Objection: you may have the right to object to our processing of your
                Personal Data. We will stop the relevant processing unless: (i) we have
                compelling legitimate grounds for the processing that override your
                interests, rights, or freedoms, or (ii) we need to continue processing your
                Personal Data to establish, exercise, or defend a legal claim;
              </li>
              <li>
                Information regarding entities with whom data shared: you may have
                the right to information regarding the public and private entities with whom
                we have shared use of your Personal Data;
              </li>
              <li>
                Information on consent: you may have the right to information on the
                possibility of not providing consent, and on the effects of consent denial;
              </li>
              <li>
                Objection to marketing: you can object to marketing at any time by
                opting-out using the unsubscribe/ opt-out function displayed in our
                communications to you; and
              </li>
              <li>
                Withdrawal of consent: where we rely on consent to process your
                Personal Data, you may have the right to withdraw this consent at any
                time by contacting us via the information in the “Contact Us” section
                below.
              </li>
            </ul>
            Some of the above rights only apply in certain circumstances, and all of these
            rights may be limited by law. For example, where fulfilling your request would
            adversely affect other individuals or our trade secrets or intellectual property,
            where there are overriding public interests or where we are required by law to
            retain your Personal Data.
            <br>
            We sometimes use computers to study your Personal Data. We might use this
            Personal Data so we can attempt to predict your buying behavior and interests.
            Depending on where you live, for decisions that may seriously impact you, you
            may have the right not to be subject to automatic decision-making or the right
            to request the revision of the decisions made solely on the basis of automatic
            processing of your Personal Data, including profiling. But in those cases, we
            will always explain to you when we might do this, why it is happening and the
            effect.
            <br>
            Contentreads has limited rights to access Personal Data our service providers submit to us. 
            Therefore, if you contact us with such a request, we will forward your request to that service provider 
            and provide any needed assistance as they respond to your request.

To exercise any of these rights or raise any other questions, please contact us by using the information in the “Contact Us” 
            section below.

          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Privacy of Children</h2>
          <p class="mb-2">The Services are not directed at, or intended for use by, children under the age of 13. If you believe we have inadvertently collected information about your child, please contact us and we will attempt to delete the information.</p>
          <br />
          <h2 class="text-xl mb-3 font-bold">U.S. Regulatory Oversight</h2>
          <div>
            Contentreads is subject to the investigatory and enforcement powers of the
            United States Federal Trade Commission.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">EEA and UK Supervisory Authority Oversight</h2>
          <div>
            If you are located in the EEA and/or the UK and we process your Personal Data
            subject to the data protection laws of these jurisdictions you have the right to
            lodge a complaint with a data protection regulator in the EEA country in which
            you live or work or where you think we have infringed data protection laws, or
            with the UK Information Commissioner’s Office, as applicable to you.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Affiliates and Newsletter Domains</h2>
          <div>
            Contentreads is affiliated with Intentgine, Inc.
            <br>
            Contentreads also operates under several aliases, which it refers to as its
            “Newsletter Domains”. The following Newsletter Domains are owned and
            operated by the companies in our corporate group:
            <ul class="mb-2 ml-2 list-disc list-inside">
              <li>
                <span>Analytics Platform Solutions &lt;analyticsplatformsolutions.com&gt;;</span>
              </li>
              <li>
                <span>B2B Demand Gen Solutions &lt;b2bdemandgensolutions.com&gt;;</span>
              </li>
              <li>
                <span>Blockchain Software Solutions &lt;Blockchainsoftwaresolutions.com&gt;;</span>
              </li>
              <li>
                <span>Cloud Info Hub &lt;cloudinfohub.com&gt;;</span>
              </li>
              <li>
                <span>Contentreads &lt;contentreads.com&gt;;</span>
              </li>
              <li>
                <span>Human Resources Briefings &lt;humanresourcesbriefings.com&gt;;</span>
              </li>
              <li>
                <span>Info Business Tech &lt;infobusinesstech.com&gt;;</span>
              </li>
              <li>
                <span>IT Local Gov &lt;itlocalgov.com&gt;;</span>
              </li>
              <li>
                <span>IT Security World &lt;it-securityworld.com&gt;;</span>
              </li>
              <li>
                <span>IT Solutions Report &lt;itsolutionsreport.com&gt;;</span>
              </li>
              <li>
                <span>Sales CRM Solutions &lt;salescrmsolutions.com&gt;;</span>
              </li>
              <li>
                <span>Tech Marketing Web &lt;techmarketingweb.com&gt;; and</span>
              </li>
              <li>
                <span>Video Conference Solutions &lt;vidconferencesolutions.com&gt;.</span>
              </li>
            </ul>
            Additional Newsletter Domains may be adopted from time-to-time, without
            notice to you.
          </div>
          <br />
          <h2 class="text-xl mb-3 font-bold">Changes to this Notice</h2>
          <p class="mb-2">We may update this Privacy Notice and its effective date to reflect changes to our data governance practices. If we propose to make any material changes, we will notify you by means of a notice on this page. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
          <h2 class="text-xl mb-3 font-bold">Contact Us</h2>
          <p class="mb-2">If you have any questions about this Notice or our processing of your Personal Data, please write to us by email at <a class="text-brand-green underline" href="mailto:privacy@contentreads.com">privacy@contentreads.com</a> or by postal mail at:</p>
          <p class="mb-2">
            Contentreads DBA Contentreads<br />
            Attn: Paul Hong, COO<br />
            316 California Ave #47 <br/>
            Reno NV 89509<br />
            United States
          </p>
          <p class="mb-2">Please allow up to four weeks for us to reply.</p>
<p class="mb-2">If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at <a href="https://feedback-form.truste.com/watchdog/request">https://feedback-form.truste.com/watchdog/request</a>. </p>
        </div>
        <div><a href="//privacy.truste.com/privacy-seal/validation?rid=0e3b0b36-678d-441c-80a8-327786ffe739" target="_blank"><img style="border: none" src="//privacy-policy.truste.com/privacy-seal/seal?rid=0e3b0b36-678d-441c-80a8-327786ffe739" alt="TRUSTe"/></a></div>
      </div>
</div>

<app-pagefooter></app-pagefooter>
