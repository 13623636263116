export class MySolutionModel {    
  public CaseStudyId: string;  
  public EmailsTo : Array<Emails>;  
  public Domain:string;
  public Message:string;
  public UserId :string;
}
export class Emails{
  public key:string;
  public value :string;
}

//for edit solution
export class SolutionSetModel {    
  public caseStudyId: string;   
  public caseStudyTitle: string;  
  public description	:string;  
  public fileLink	:string;
  public fileViewPath	:string;
  public hasExtraFeatures	:boolean;
  public humanReadableId : string;	
  public id	: string; 
  public imageUrl	: string; 
  public solutionSetId :string;
  public titleNormalized	 :string;
  public vendorNameNormalized	:string;  
  public vscId	:string;
  public vscLogoUrl	: string; 
  public type	: string; 
  public videoUrl	: string; 
}

export class ShareViaTwitterModel {  
  public id :string; 
  public title :string;
  public domain : string; 
}

// export class shareCaseStudyOnTwitterModel{
//   public id : string;
//   public title   : string;
//   public domain : string; 
// }