import { Component, OnInit } from '@angular/core';
import { ApicallService } from '../service/apicall.service';
import { Meta,MetaDefinition ,Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../shared/header/header.component';
import md from 'markdown-it';
import { BlogListModel } from '../models/blogmodel';
import {decode} from 'html-entities';
@Component({
  providers: [HeaderComponent],
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  microSiteContent:any;
  totalRecords:number = 0;
  page:number = 1;
  countPerPage = 6;
  domainName: any;
  private markdown;
  public blogList:Array<BlogListModel> = [];
  public constructor(private metaService:Meta, private titleService:Title,private route:ActivatedRoute,private router:Router,private service:ApicallService, private header:HeaderComponent ) { 
    this.titleService.setTitle("Blog");
  }

  ngOnInit(): void {
    
    this.markdown=md();
    this.domainName = this.service.getHostname();
    this.service.getBlogs(this.domainName)
    
    .subscribe(data=> {   
      this.microSiteContent=data;
      
      this.microSiteContent.forEach((blogContent, index)=> {
          var blogdata=new BlogListModel();
          blogdata.content=decode(this.markdown.render(this.replace_span_to_u(blogContent.content)));
         
          blogdata.backgroundImageUrl=blogContent.backgroundImageUrl;
          blogdata.title=blogContent.title;
          blogdata.titleNormalized=blogContent.titleNormalized;
          this.blogList.push(blogdata);
      });            
      this.totalRecords = data.length;         
      }
      ,error =>{ });  
      
  }
  replace_span_to_u(input) {
    return input.replace(/\<span style\=\"text-decoration\:underline\"\>(.*?)\<\/span\>/g,             function(matched, index) {
            return '<u>' + index + '</u>';
          })
    }
}
