<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<app-header></app-header> 
<div class=" bg-gray-300 grid w-full mx-auto text-left">
    <div class="text-left">
        <section class="text-gray-600 body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <div class="w-full mx-auto flex flex-wrap">
                    <div class="w-full  flex-wrap p-2 text-center text-2xl">
                        <h1><b>Solution Sets</b></h1>
                    </div>
                </div>               
                
            </div>
        </section>
    </div>
</div>
<app-pagefooter></app-pagefooter>