export class searchparams
{
    public additionalQuery:string;
    public queryString :string;
    public queryType:string;
    public getSponsored:boolean;
    public page:number;
    public domain:string;
}

export class downloadCaseStudyModel
{
    public caseStudyId:string;
    public downloadToken :string;
    public type :string; 
    public userId:string;
    public domain:string;
    public caseStudyTitle :string;
    public token:string;
}

export class analyticsWriteModel{
    public stateUrl:string;
    public stateName :string;
    public domain :string; 
}
export class analyticsWriteCaseStudyModel{
    public additionalQuery: string;
    public queryString : string;
    public queryType: string;
    public domain : string; 
    public userId:string;
    public userIP:string;
}

export class addCsToMyIndustolutionsModel{
    public id : string;
    public source : string;
    public domain : string; 
    public userId:string;
    public userIP:string;
}

export class pdfDownloadModel
{
    public caseStudyId:string;
    public userId:string;
    public domain:string;
    public caseStudyTitle :string;
    public humanReadableId:string;
    public token:string;
}

export class analyticsWriteShareCaseStudyModel{
    public csId : string;
    public source   : CaseStudyViewSource;
    public domain : string; 
    public type    : SocialMediaType;
    public userId:string;
    public userIP:string;
}

export class analyticsWriteDownloadCaseStudyModel{
    public id : string;
    public source   : CaseStudyViewSource;
    public domain : string; 
    public userId:string;
    public userIP:string;
}

export enum CaseStudyViewSource
{ 
    //Vendor Solution Center
    VSC = 1,
    //Solution Set
    PublicSolutionSet = 2,
    //Search Page
    SearchPage = 3,
    //Home Page
    HomePage = 4,
    Other = 5,
    //myIndustolutions
    MyIndustolutions = 6
}

export enum SocialMediaType
{
    Facebook = 1,
    Twitter = 2,
    LinkedIn = 3
}