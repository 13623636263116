<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10 w-full max-w-7xl mx-auto px-8 pt-8">
        <section class="text-white body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <h1 class="text-white text-2xl md:text-6xl font-semibold uppercase break-words">CCPA PRIVACY</h1>
            </div>
        </section>
    </div>
</div>
<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4">
            <section #requestFormsection>

                <div class="border border-gray max-w-screen-xl">
                    <h2 class="text-brand-gray text-2xl p-5 font-bold">Do Not Sell My Personal Information. <br><br>To access or request removal of information CONTENTgine has collected please submit the form below.</h2>


                    <form #requestFormPage="ngForm" class="form  p-5" name="requestForm">
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-first-name">
                                    First Name
                                </label>
                                <input #firstname="ngModel" type="text" name="firstName"
                                    [(ngModel)]="requestForm.FirstName" value="{{firstname}}"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    placeholder="Your first name so we can be polite when we email you!" />
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-last-name">
                                    Last Name
                                </label>
                                <input type="text" name="lastName" #lastname="ngModel" value="{{lastname}}"
                                    [(ngModel)]="requestForm.LastName"
                                    class=" appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    ng-class="{'form__control--invalid': requestForm.lastName.$touched && requestForm.lastName.$invalid}"
                                    ng-model="vm.request.lastName"
                                    placeholder="Your last name so we can be polite when we email you!" />


                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-email">
                                    Email <span class="text-red-500">*</span>
                                </label>
                                <input required #email="ngModel" type="email" name="email"
                                    [(ngModel)]="requestForm.Email" value="{{email}}"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    [ngClass]="{'red-border-class': firstname.errors && (firstname.dirty || firstname.touched)}"
                                    placeholder="Your email" />
                                <div *ngIf="email.errors && (email.dirty || email.touched)">
                                    <div [hidden]="!email.errors.required" class="text-red-500 -mt-3">
                                        Please enter Email.
                                    </div>

                                </div>
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-request-type">
                                    Request Type <span class="text-red-500">*</span>
                                </label>
                                <div class="relative">
                                    <select required aria-selected="true" #requesttype="ngModel" required
                                        id="requesttype" autocomplete="off" name="requesttype"
                                        [(ngModel)]="requestForm.Type"
                                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-state"
                                        [ngClass]="{'red-border-class': requesttype.errors && (requesttype.dirty || requesttype.touched)}">
                                        <option value="">Please Select</option>
                                        <option>Access Information</option>
                                        <option>Correct Information</option>
                                        <option>Delete Information</option>
                                        <option>Forbid Selling of Information</option>
                                    </select>
                                    <div *ngIf="requesttype.errors && (requesttype.dirty || requesttype.touched)">
                                        <div [hidden]="!requesttype.errors.required" class="text-red-500 -mt-3">
                                            Please Select a Request Type.
                                        </div>
                                    </div>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20">
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="grid-request-type">
                                    Message
                                </label>
                                <textarea name="message" #message="ngModel" required id="message" autocomplete="off"
                                    name="message" [(ngModel)]="requestForm.Message"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    placeholder="Your message"
                                    [ngClass]="{'red-border-class': firstname.errors && (firstname.dirty || firstname.touched)}"></textarea>
                                <div *ngIf="message.errors && (message.dirty || message.touched)">
                                    <div [hidden]="!message.errors.required" class="text-red-500 -mt-3">
                                        Please enter Message.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <button type="reset"
                                class="cursor-pointer font-bold uppercase py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white mr-3">Cancel</button>
                            <button (click)="sendRequestData(requestFormPage)"
                                class="cursor-pointer bg-brand-green font-bold uppercase py-2 px-4 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                type="submit" [disabled]="!requestFormPage.valid">Save
                            </button>
                        </div>
                    </form>

                </div>
            </section>
        </div>
    </div>
</div>
<app-pagefooter></app-pagefooter>
