<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10 w-full max-w-7xl mx-auto px-8 pt-8">
        <section class="text-white body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <h1 class="text-white text-2xl md:text-6xl font-semibold uppercase break-words">PLEASE UPLOAD YOUR SUGGESTED CASE STUDY</h1>

            </div>
        </section>
    </div>
</div>
<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4 m-h-500">
            <div class="">
                <h3 class="text-2xl font-light text-center py-5">Check the box below to upload a PDF case study suggestion</h3>
                <div class="text-center"><input type="checkbox" name="isAgreed" [ngModel]="isChecked"
                        (ngModelChange)="onIsAgreedChanged($event)"> 
                    By clicking the checkbox, you represent that you agree to Contentreads
                    <a href="/legalterms" target="_blank">Terms of Service</a> &amp;
                    <a href="/privacy" target="_blank">Privacy Policy</a>.
                </div>
                <div *ngIf="isChecked" class="mt-10">
                    <form method="POST" enctype="multipart/form-data">
                        <div class="container max-w-screen-sm mx-auto md:p-10  relative border border-dashed border-gray-600 text-center mb-3 pb-4" appDnd (fileDropped)="onFileDropped($event)" (click)="onBrowseFile()">
                            <input type="file" #fileDropRef accept="application/pdf" id="fileDropRef" multiple
                                (change)="readFile($event)" />
                            <img src="../../assets/images/ic-upload-file.svg" alt="" class="m-auto">
                            <h3 class="text-2xl font-semibold mb-3">Drag and drop file here<br>or</h3>
                            <label for="fileDropRef " class="bg-brand-green px-10 py-3 rounded-full text-white cursor-pointer">Browse for file</label>
                        </div>
                        <div class="progress_bar">
                            <div *ngIf="!isValid" role="alert" class="text-red-700 px-4 py-3 font-bold text-center">
                                Invalid File format, Upload PDF Files.
                            </div>
                            <div class="files-list">
                                <div class="single-file" *ngFor="let file of files; let i = index" id="{{i}}">
                                    <img src="../../assets/images/ic-file.svg" width="45px" alt="file">
                                    <div class="info">
                                        <h4 class="name">
                                            {{ file?.name }}
                                        </h4>
                                        <p class="size">
                                            {{ formatBytes(file?.size) }}
                                        </p>
                                        <app-progress [progress]="file?.progress"></app-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="m-auto text-center py-6">
                        <span [innerHtml]="htmlVariable"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Join Contentree Modal-->
<div *ngIf="showJoinContentreeModal"
    class="fixed text-gray-500 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-40 left-0 right-0 top-0 bottom-0"
    x-transition:enter="transition ease duration-300" x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100" x-transition:leave="transition ease duration-300"
    x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12  lg:w-4/12  mx-10"
        x-transition:enter="transition ease duration-100 transform"
        x-transition:enter-start="opacity-0 scale-90 translate-y-1"
        x-transition:enter-end="opacity-100 scale-100 translate-y-0"
        x-transition:leave="transition ease duration-100 transform"
        x-transition:leave-start="opacity-100 scale-100 translate-y-0"
        x-transition:leave-end="opacity-0 scale-90 translate-y-1">
        <div class="flex justify-end">
            <svg (click)="toggleJoinContentreeModal()" class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
            </svg>
        </div>
        <div class="text-gray-900 font-medium text-lg text-center">Join Contentreads and get access to the world's
            largest collection of B2B case studies.</div>
        <div class="py-4">
            <div class="flex justify-center pt-10">
                <div class="w-68  text-center pl-10 pr-10">
                    <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 91 104">
                        <g fill="none" fill-rule="evenodd" stroke="#587B93" stroke-width="1.3">
                            <path fill="#D01317"
                                d="M42.646 57.737c-2.218 0-4.022 1.782-4.022 3.973 0 2.19 1.804 3.973 4.022 3.973s4.022-1.782 4.022-3.973c0-2.19-1.804-3.973-4.022-3.973m0 8.152c-2.333 0-4.231-1.874-4.231-4.179 0-2.304 1.898-4.179 4.23-4.179 2.334 0 4.232 1.875 4.232 4.18 0 2.304-1.898 4.178-4.231 4.178" />
                            <path fill="#D01317"
                                d="M42.645 57.737a.103.103 0 0 1-.103-.103v-18.59a.103.103 0 0 1 .207 0v18.59a.103.103 0 0 1-.104.103" />
                            <path fill="#D01317"
                                d="M42.725 90.975c-16.364 0-29.678-13.15-29.678-29.314a29.154 29.154 0 0 1 6.636-18.476 29.841 29.841 0 0 1 16.61-10.147.105.105 0 0 1 .125.079.103.103 0 0 1-.08.122 29.63 29.63 0 0 0-16.493 10.076 28.948 28.948 0 0 0-6.59 18.346c0 16.05 13.22 29.108 29.47 29.108s29.47-13.057 29.47-29.108c0-6.684-2.35-13.212-6.62-18.382a29.627 29.627 0 0 0-16.558-10.06.103.103 0 0 1-.08-.123.105.105 0 0 1 .125-.078 29.837 29.837 0 0 1 16.675 10.131 29.148 29.148 0 0 1 6.666 18.512c0 16.164-13.313 29.314-29.678 29.314" />
                            <path fill="#D01317"
                                d="M42.72 103.047C19.717 103.047 1 84.56 1 61.838 1 39.116 19.716 20.63 42.72 20.63c5.24 0 10.35.948 15.189 2.816.053.02.08.08.059.133a.104.104 0 0 1-.135.059 41.779 41.779 0 0 0-15.112-2.802c-22.89 0-41.512 18.394-41.512 41.002 0 22.609 18.622 41.003 41.512 41.003s41.512-18.394 41.512-41.003c0-5.593-1.12-11.014-3.33-16.113a.103.103 0 0 1 .056-.136.104.104 0 0 1 .137.055 40.507 40.507 0 0 1 3.345 16.194c0 22.723-18.716 41.209-41.72 41.209" />
                            <path fill="#D01317"
                                d="M26.42 45.787a.105.105 0 0 1-.076-.033l-4.482-4.8a.102.102 0 0 1 .006-.145.105.105 0 0 1 .148.006l4.481 4.8a.102.102 0 0 1-.006.145.105.105 0 0 1-.07.027M58.871 45.787a.104.104 0 0 1-.072-.029.102.102 0 0 1-.003-.145l4.641-4.8a.105.105 0 0 1 .147-.003c.042.04.044.105.004.146l-4.641 4.8a.105.105 0 0 1-.076.031M65.593 61.814a.104.104 0 0 1-.104-.102c0-.057.046-.104.104-.105l6.705-.049h.001c.057 0 .104.046.105.103a.104.104 0 0 1-.104.104l-6.706.05zM19.698 61.814l-6.547-.049a.104.104 0 0 1-.104-.104c0-.057.047-.103.105-.103l6.547.05c.058 0 .104.047.104.104a.104.104 0 0 1-.105.102M21.94 82.54a.105.105 0 0 1-.072-.027.102.102 0 0 1-.005-.146l4.482-4.701a.106.106 0 0 1 .147-.005c.042.04.044.104.004.146l-4.481 4.701a.105.105 0 0 1-.076.033M42.724 90.975a.103.103 0 0 1-.103-.102l-.08-6.496c0-.057.046-.104.103-.104.056-.007.104.045.105.102l.079 6.496a.103.103 0 0 1-.103.104h-.001zM63.512 82.54a.105.105 0 0 1-.074-.03l-4.641-4.702a.102.102 0 0 1 .002-.146.106.106 0 0 1 .147.002l4.641 4.702c.04.04.04.106-.002.145a.104.104 0 0 1-.073.03M36.387 21.31a.103.103 0 0 1-.104-.104V10.824c0-.057.047-.103.104-.103.057 0 .103.046.103.103v10.382a.103.103 0 0 1-.103.103M48.903 21.287a.103.103 0 0 1-.103-.103v-10.36a.103.103 0 0 1 .207 0v10.36a.103.103 0 0 1-.104.103" />
                            <path fill="#D01317"
                                d="M35.61 1.206c-2.99 0-5.424 2.403-5.424 5.357V9.36c0 .75.619 1.361 1.379 1.361h22.024c.76 0 1.379-.61 1.379-1.361V6.659c0-3.007-2.477-5.453-5.52-5.453H35.608zm17.98 9.721H31.564a1.58 1.58 0 0 1-1.587-1.567V6.563C29.978 3.495 32.504 1 35.609 1h13.838c3.16 0 5.73 2.538 5.73 5.659v2.7a1.58 1.58 0 0 1-1.588 1.568zM11.687 34.684a.105.105 0 0 1-.073-.03L4.845 27.97a4.197 4.197 0 0 1-1.26-3.006c0-1.135.448-2.202 1.26-3.005a4.303 4.303 0 0 1 3.044-1.245c1.149 0 2.23.442 3.043 1.245l6.902 6.915c.04.04.04.106-.001.146a.106.106 0 0 1-.148-.002l-6.902-6.914a4.093 4.093 0 0 0-2.894-1.184c-1.094 0-2.122.42-2.896 1.184a3.994 3.994 0 0 0-1.2 2.86c0 1.08.427 2.096 1.2 2.86l6.768 6.685c.04.04.04.106 0 .146a.105.105 0 0 1-.074.03" />
                            <path fill="#D0161A"
                                d="M77.051 15.129c-7.022 0-12.736 5.643-12.736 12.58 0 6.936 5.714 12.579 12.736 12.579 7.023 0 12.736-5.643 12.736-12.58 0-6.936-5.713-12.58-12.736-12.58m-12.903 12.58c0-7.028 5.788-12.745 12.903-12.745 7.116 0 12.904 5.717 12.904 12.745s-5.788 12.746-12.904 12.746c-7.115 0-12.903-5.718-12.903-12.746z" />
                            <path fill="#E73838" stroke-linecap="round" stroke-linejoin="round"
                                d="M74.24 22.575c.342.102.646.261.911.477.266.216.48.492.645.827.165.335.247.738.247 1.209 0 .388-.062.75-.188 1.085a4.406 4.406 0 0 1-.505.96 7.629 7.629 0 0 1-.733.9c-.278.294-.57.594-.874.9l-3.642 3.67c.103-.01.222-.017.358-.022.135-.005.286-.008.453-.008h5.338c.049 0 .073.025.073.073v.088h-6.458v-.059c0-.029.008-.053.022-.072a.435.435 0 0 1 .037-.044l3.716-3.728c.305-.306.592-.605.862-.896.27-.291.507-.588.708-.889.202-.3.361-.611.48-.932a2.95 2.95 0 0 0 .176-1.026c0-.442-.076-.822-.228-1.14a2.238 2.238 0 0 0-.605-.783 2.487 2.487 0 0 0-.855-.451 3.352 3.352 0 0 0-.98-.146c-.384 0-.737.058-1.058.175-.322.117-.606.28-.852.491a2.686 2.686 0 0 0-.608.754 3.03 3.03 0 0 0-.328.969c-.015.077-.054.116-.118.116h-.074c.054-.408.166-.775.336-1.103a2.758 2.758 0 0 1 1.57-1.362 3.339 3.339 0 0 1 1.132-.185c.353 0 .701.05 1.043.152z" />
                            <path fill="#E73838"
                                d="M85.052 27.636c0-.874-.092-1.628-.276-2.264-.185-.636-.434-1.162-.749-1.577a3.024 3.024 0 0 0-1.102-.925 3.058 3.058 0 0 0-1.338-.302c-.472 0-.919.101-1.342.302-.422.202-.792.51-1.11.925-.316.415-.567.94-.751 1.577-.185.636-.277 1.39-.277 2.264 0 .874.092 1.63.277 2.265.184.636.435 1.16.752 1.573.317.413.687.718 1.11.917.422.2.87.299 1.341.299.472 0 .918-.1 1.338-.299a3 3 0 0 0 1.102-.917c.315-.413.564-.937.749-1.573.184-.636.276-1.39.276-2.265m.192 0c0 .894-.097 1.667-.291 2.32-.195.653-.458 1.193-.79 1.62-.331.427-.72.744-1.164.95-.445.207-.916.31-1.412.31-.496 0-.967-.103-1.412-.31a3.148 3.148 0 0 1-1.165-.95c-.331-.427-.594-.967-.788-1.62-.195-.653-.292-1.426-.292-2.32 0-.893.097-1.666.292-2.319.194-.653.457-1.194.788-1.624.332-.43.72-.748 1.165-.957a3.283 3.283 0 0 1 1.412-.313c.496 0 .967.104 1.412.313.445.209.833.528 1.165.957.331.43.594.971.789 1.624.194.653.29 1.426.29 2.32" />
                        </g>
                    </svg> It is free to register and takes 20 seconds
                </div>
            </div>
        </div>
        <div class="flex justify-between mt-5">
            <button (click)="toggleJoinContentreeModal()"
                class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer">Cancel</button>
            <a (click)="openRegister()" routerLinkActive="active"
                class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer">Sign
                Up</a>
        </div>
    </div>
</div>
<div *ngIf="showJoinContentreeModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<app-pagefooter></app-pagefooter>