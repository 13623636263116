import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title }     from '@angular/platform-browser'; 
import { Router, ActivatedRoute,ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { addCsToMyIndustolutionsModel, analyticsWriteCaseStudyModel, analyticsWriteDownloadCaseStudyModel, analyticsWriteModel, CaseStudyViewSource, downloadCaseStudyModel, searchparams } from '../common/searchparams';
import { PersonalSolutionModel } from '../models/solutionsetsmodel';
import { ApicallService } from '../service/apicall.service';
import { SeoService } from '../seo.service';
import { FuncsService } from '../service/funcs.service';
@Component({
  selector: 'app-casestudysearch',
  templateUrl: './casestudysearch.component.html',
  styleUrls: ['./casestudysearch.component.scss']
})
export class CasestudysearchComponent implements OnInit {
  public personalSolutionData = new PersonalSolutionModel();
  public constructor(private titleService: Title, private service:ApicallService, private router:Router, private route:ActivatedRoute,private toastr:ToastrService,private seo:SeoService, public funcs: FuncsService) { 
    this.titleService.setTitle("Search Case Studies");
  }
  public searchParamModel = new searchparams();
  public isLoggedIn;

  caseStudyCount:any;
  public searchText;
  public result = [] as  any;  
  listingResultCount:number;
  domainname:any;
  userIpAddress:any;
  casestudySearchResult : any;
  listingResult : any;
  public blobStorageUrl:string;
  exploredata:any;
  counterpart:any;

  notSponsoredResults:number;
  totalItemsForPagination :number;
  currentPage :number;

  //case study page
  humanReadableId: any;
  titleNormalized: any;
  microSiteContent:any;
  public analyticsWriteDownloadCaseStudyParam = new analyticsWriteDownloadCaseStudyModel
  public componentname:string;

  public cookieTimer;
  public formToken;
  public tokenCookieName = "downloadToken";
  public attempts = 60;
  public parts;
  public mainform ;
  public form ;
  //for case study download 
  public caseStudyParam = new downloadCaseStudyModel();

  public analyticsWriteParam = new analyticsWriteModel();
  public analyticsWriteCaseStudyParam = new analyticsWriteCaseStudyModel();
  public addCsToMyIndustolutionsParam = new addCsToMyIndustolutionsModel
  
  //list of solution set
  solutionSetList : any;
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "autoplay": true,
    "dots": true,
    "infinite": true,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]

  };

  ngOnInit(): void {
    this.route.paramMap.subscribe((params : ParamMap) =>{ 
    this.searchParamModel.additionalQuery=params.get('additionalQuery')??'';
    this.searchParamModel.queryString=params.get('queryString')??'';         
    this.searchParamModel.queryType=params.get('queryType')??'';
    this.searchParamModel.getSponsored= JSON.parse(params.get('getSponsored')??'');  
    this.searchParamModel.page=parseInt(params.get('page')??'');
    this.searchParamModel.domain = params.get('domain')??'';   
    this.domainname = this.service.getHostname();
    
 
  });  
          
     
     if(sessionStorage.getItem('sessionuserId')!=null)
     this.isLoggedIn=true;
     else
     this.isLoggedIn=false;

      // dropdown list (personal solution set)
      if(this.isLoggedIn)
      {
       this.loadSolutionSets();
      }

      
     
      //load page content
      this.loadcasestudy();
      this.service.getUserIP().subscribe(data =>{      
        this.userIpAddress = data['ip']; 
        if(this.isLoggedIn)
        {
          this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
          this.analyticsWriteParam.stateUrl=this.componentname;
          this.analyticsWriteParam.stateName=this.componentname;
          this.analyticsWriteParam.domain=this.domainname;
          //analyticsWrite      
          this.service.analyticsWrite(sessionStorage.getItem('sessionuserId')??'', this.userIpAddress,this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
          .subscribe(data=> { },error =>{ }); 
    
        }
      });      
      
     // for manage Case Study Search page content
     this.service.getMicrositeContents("Case Study Search",this.domainname)
     .subscribe(data=> {    
          this.microSiteContent=data;
      }
      ,error =>{ });  
    }
    

  showJoinContentreeModal = false;
  toggleJoinContentreeModal(){
    this.showJoinContentreeModal = !this.showJoinContentreeModal;
  }
  hideSolutionSolutionList(){
    this.showSolutionList=false;
    this.showShareSocial=false;
  }
  hideSolutionSolutionListForResults(){
    let targetElement = document.getElementsByName("divSolutionList");
    targetElement?.forEach((child) => { child.classList.add("hidden"); }) ;
    let targetElementSocialShare = document.getElementsByName("divSocialShare");
    targetElementSocialShare?.forEach((child) => { child.classList.add("hidden"); }) ;
  }
  
  SearchCase() {   
   
    this.searchParamModel.domain=this.domainname;
    this.searchParamModel.page=1;
    this.searchParamModel.getSponsored=true; 
    this.searchParamModel.additionalQuery="";
    //clear other filter 
    this.result = []; 
    this.titleService.setTitle('Search Case Studies');
    this.router.navigate(['/casestudysearch',{additionalQuery:this.searchParamModel.additionalQuery,queryString:this.searchParamModel.queryString,queryType:this.searchParamModel.queryType, getSponsored:this.searchParamModel.getSponsored,page: this.searchParamModel.page, domain: this.searchParamModel.domain}]).then(() => {window.location.reload();});
   }

   openCaseStudy(casestudyId)
   {  
      //api to get humanReadableId and titleNormalized   
      this.service.getCaseStudyUrlParts(casestudyId)    
      .subscribe(data => {
            this.humanReadableId = data['humanReadableId'];
            this.titleNormalized =  data['titleNormalized'];
            //checkExistence      
            this.service.checkExistence(this.humanReadableId) 
            .subscribe(data => {
              this.router.navigate(['casestudy',this.titleNormalized+"_"+this.humanReadableId]); // on checkExistence success
            }
            ,(error) => {               
            });       
      }
      ,(error) => {         
      }); 
    

   }

  
   openAdvancePopup=false;
   toggleAdvancePopup(){
    this.openAdvancePopup = !this.openAdvancePopup;
    
  } 
  
  showRefineSearchModal = false;
  toggleRefineSearchModal()
  {
    this.showRefineSearchModal = !this.showRefineSearchModal;
  }
  modal=false;
  toggleModal() {
    this.modal = !this.modal
  }
  openRegister()
  {
    window.location.href='/register?redirectUri='+location.pathname.substr(1); 
  }
  RefineSearchClick(refineForm:NgForm)
  {   
    this.result.push(refineForm.value.additionalQuery);    
    this.showRefineSearchModal = !this.showRefineSearchModal;    
    this.searchParamModel.additionalQuery = this.result.join('~~~'); 
    this.loadcasestudy();
  }
  resetClick()
  {
    
    this.result = []; 
    this.showRefineSearchModal = !this.showRefineSearchModal;
    this.searchParamModel.additionalQuery = this.result.join('~~~');  
    //load page
    this.loadcasestudy(); 
  }
  removeRefineSearchdiv(data)
  {
    const index: number = this.result.indexOf(data);
    if (index !== -1) {
        this.result.splice(index, 1);
    }     
    this.searchParamModel.additionalQuery = this.result.join('~~~'); 

    //load page
    this.loadcasestudy();
  }
  removeRefineSearch(data)
  {   
    const index: number = this.result.indexOf(data);
    if (index !== -1) {
        this.result.splice(index, 1);
    } 
    this.showRefineSearchModal = !this.showRefineSearchModal;
    this.searchParamModel.additionalQuery = this.result.join('~~~'); 
    
    //load page
    this.loadcasestudy();        
  }

  pageChanged(page: number)
  {
    this.currentPage= page;
    this.searchParamModel.page=page;
    this.loadcasestudy();      
  }

  loadcasestudy()
  {     
    this.searchParamModel.domain= this.domainname;   
    this.service.getcaseStudySearchForUser(this.searchParamModel)
    .subscribe(data => {
      this.searchText = data['query']; //for case study text
      this.caseStudyCount =  data['total']; //for case study result count
      this.casestudySearchResult = data['sponsoredCaseStudies']; //for case study results
      this.blobStorageUrl = data['blobStorageUrl'];
      this.listingResult = data['results']; //for listing part
      this.notSponsoredResults = data['notSponsoredResults'];
      this.listingResultCount=this.listingResult.length;
   });   
   
    //for count  and list
    this.service.getExploreData(this.domainname)
    .subscribe(data => {
      this.counterpart = data['solutionSetsCount'];
      this.exploredata =  data;
    });  
  let maxItemsToDisplayCount = 1000;
  this.totalItemsForPagination = this.notSponsoredResults>= maxItemsToDisplayCount ? this.notSponsoredResults : maxItemsToDisplayCount;
  this.currentPage = this.searchParamModel.page; 

 
    this.analyticsWriteCaseStudyParam.additionalQuery=this.searchParamModel.additionalQuery;
    this.analyticsWriteCaseStudyParam.queryString=this.searchParamModel.queryString;
    this.analyticsWriteCaseStudyParam.queryType=this.searchParamModel.queryType;
    this.analyticsWriteCaseStudyParam.domain=this.domainname;
    this.analyticsWriteCaseStudyParam.userIP = this.userIpAddress;
    if(this.isLoggedIn)
        {
    this.analyticsWriteCaseStudyParam.userId= sessionStorage.getItem('sessionuserId')??'';
        }

    //analytics write for case study search
    this.service.analyticsWriteCaseStudySearch(this.analyticsWriteCaseStudyParam)
    .subscribe(data=> { },error =>{ }); 
 

  }
  showSolutionList=false; 
  toggleSolutionList()
  {
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.toggleJoinContentreeModal();
    else
    {

      if(this.isLoggedIn)
      {  
      this.showSolutionList = !this.showSolutionList;
      }
      else
      {
        this.showSolutionList=false;
        this.toggleJoinContentreeModal();
      }
    }
  }
  
  loadSolutionSets()
  {      
    this.service.getSolutionSetList(sessionStorage.getItem('sessionuserId')) 
    .subscribe(data => {
      this.solutionSetList= data['body'];    
    }
    ,(error) => {                
    });  
  }

  addCaseStudyToSolutionSet(caseStudyId,solutionSetId)
  {
    this.personalSolutionData.UserId= sessionStorage.getItem('sessionuserId')??"";
    this.personalSolutionData.Domain=this.domainname;
    this.personalSolutionData.CaseStudyId=caseStudyId;
    this.personalSolutionData.SolutionSetId=solutionSetId;
    this.service.addCaseStudyToSolutionSetForMicrosites(this.personalSolutionData) 
    .subscribe(data => { 
     this.toastr.success("Your case study has been saved to the Solution Set","",{timeOut:3000,progressBar:true});                  
      
      if(this.isLoggedIn)
      {
        this.addCsToMyIndustolutionsParam.id=caseStudyId;
        this.addCsToMyIndustolutionsParam.source="3";
        this.addCsToMyIndustolutionsParam.domain=this.domainname;
        //analytics write for analyticsWriteCaseStudySearch
        this.service.addCsToMyIndustolutions(this.addCsToMyIndustolutionsParam)
        .subscribe(data=> { },error =>{ }); 
      }

    }
    ,(error) => {  
      if(error.error.length>0 || error.error.message!="undefined"){
        this.toastr.error("Case Study has already been added in this Personal Solution Set.","Error",{timeOut:3000, progressBar:true});
      }
      else if(error.error.message=="undefined"){
        this.toastr.error(error.error.message,"Error",{timeOut:3000, progressBar:true});
      }
      else{
        this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);
      }             
    });  
  }

  showCreatePersonalSolutionSetModal = false;
  openCreatePersonalSolutionSetModal(caseStudyId){    
    this.personalSolutionData.CaseStudyId=caseStudyId;
    this.showCreatePersonalSolutionSetModal = !this.showCreatePersonalSolutionSetModal;
  }
  
  closeCreatePersonalSolutionSetModal(){  
    this.showCreatePersonalSolutionSetModal = !this.showCreatePersonalSolutionSetModal;
  }

  createWithCaseStudyForMicrosites()
  { 
    let casestudyid; 
    this.personalSolutionData.UserId= sessionStorage.getItem('sessionuserId')??"";
    this.personalSolutionData.Domain=this.domainname;
    this.service.createWithCaseStudyForMicrosites(this.personalSolutionData) 
    .subscribe(data => { 
      casestudyid=data['body'].id;
      this.showCreatePersonalSolutionSetModal=false;
      this.toastr.success("Your Solution has been saved to the new Solution Set","",{timeOut:3000,progressBar:true});                        
      this.loadSolutionSets()
      if(this.isLoggedIn)
      {
        this.addCsToMyIndustolutionsParam.id=casestudyid;
        this.addCsToMyIndustolutionsParam.source="3";
        this.addCsToMyIndustolutionsParam.domain=this.domainname;
        this.addCsToMyIndustolutionsParam.userIP=this.userIpAddress;
        this.addCsToMyIndustolutionsParam.userId=sessionStorage.getItem('sessionuserId')??'';
        //analytics write for analyticsWriteCaseStudySearch
        this.service.addCsToMyIndustolutions(this.addCsToMyIndustolutionsParam)
        .subscribe(data=> { this.personalSolutionData.Name="";},error =>{ }); 
      }
    }
    ,(error) => {  
      if(error.error.length>0){
        this.toastr.error(error.error[0],"Error",{timeOut:3000, progressBar:true});
      }
      else if(error.error.message!="undefined"){
        this.toastr.error(error.error.message,"Error",{timeOut:3000, progressBar:true});
      }
      else{
        this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);
      }
    });  

  }

  //listing part
  listAddCaseStudy(id)
  {
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.toggleJoinContentreeModal();
    else
    {
      if(this.isLoggedIn)
      {  
        let targetElement = document.getElementById(id);
        targetElement?.classList.remove("hidden"); 
      }
      else
      {
        this.toggleJoinContentreeModal();
      }
    }
  }
  candownloadFile(casestudyId,caseStudyTitle)
  {
    if(this.isLoggedIn)
    {
    this.service.canDownloadForMicrosite(sessionStorage.getItem('sessionuserId'),casestudyId)
        .subscribe(data => {
          let strStatus=data['body']; 
          if(strStatus==true)
          { 
            this.downloadCaseStudy(casestudyId,caseStudyTitle);                   
          }
          else{
            this.toastr.error("You've exceeded download limit for case studies","",{timeOut:3000,progressBar:true}); 
   
          }

        }
        ,error =>{this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);});
      }
      else
      {
        this.toggleJoinContentreeModal();
      } 
  }
  downloadCaseStudy(casestudyId,caseStudyTitle)
  {
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.toggleJoinContentreeModal();
    else
    {
      if(this.isLoggedIn)
      {
        this.caseStudyParam.caseStudyId= casestudyId;
        this.caseStudyParam.caseStudyTitle= caseStudyTitle;
        this.caseStudyParam.userId = sessionStorage.getItem('sessionuserId')??"";
        this.caseStudyParam.domain= this.domainname;
        this.caseStudyParam.type="0";
        this.caseStudyParam.downloadToken = ""; 
        this.promtToSaveFile(environment.apiURL+'/api/files/downloadforMicrosites');

        //analytics write for download case study
        this.analyticsWriteDownloadCaseStudyParam.id=casestudyId;
        this.analyticsWriteDownloadCaseStudyParam.source= CaseStudyViewSource.SearchPage;
        this.analyticsWriteDownloadCaseStudyParam.domain=this.domainname;   
        this.analyticsWriteDownloadCaseStudyParam.userIP=this.userIpAddress;
        this.analyticsWriteDownloadCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';  
        this.service.analyticsWriteDownloadCaseStudy(this.analyticsWriteDownloadCaseStudyParam)
        .subscribe(data=> {
        },error =>{this.router.navigate(['/login']) ; }); 
        
      }
      else
      {
        this.toggleJoinContentreeModal();
      }
    }
  }

  promtToSaveFile(url) {
    this.mainform = this.createForm(url);
    let formToken = (new Date()).getTime().toString();
    this.mainform.appendChild(this.createInput("accessToken", sessionStorage.getItem('userToken')));
    this.mainform.appendChild(this.createInput("url", url));    
    this.mainform.appendChild(this.createInput("caseStudyId", this.caseStudyParam.caseStudyId));
    this.mainform.appendChild(this.createInput("downloadToken", ""));
    this.mainform.appendChild(this.createInput("type", this.caseStudyParam.type));
    this.mainform.appendChild(this.createInput("userId", this.caseStudyParam.userId));
    this.mainform.appendChild(this.createInput("domain", this.caseStudyParam.domain));      
    this.mainform.appendChild(this.createInput("caseStudyTitle", this.caseStudyParam.caseStudyTitle));    
    const nodenative = document.createElement('script');
    nodenative.src ='assets/jquerynative.js';
    nodenative.type = 'text/javascript';
    nodenative.async = false;  
    this.mainform.appendChild(nodenative);
    const node = document.createElement('script');
    node.src ='assets/load.js';
    node.type = 'text/javascript';
    node.async = false;  
    this.mainform.appendChild(node);
    setTimeout(() => {
      this.mainform.parentNode.removeChild(this.mainform);
    }, 1000); 
    return false;
  }

createForm(url) {  
    this.form = document.createElement("form");
  var formname=this.caseStudyParam.caseStudyId; 
   this.form.name=formname; 
   this.form.id = formname;
  this.form.style.display = "none";
  this.form.action = url;
  this.form.method = "POST"; 
  document.body.appendChild(this.form);
  return this.form;
}

createInput(name, value) {
  var input = document.createElement("input");
  input.name = name;
  input.value = value;
  return input;
}

checkFileLoaded() {
  var token = this.getCookieValue(this.tokenCookieName);

  if ((token === this.formToken) || (this.attempts === 0)) {
    this.clearLoading();
      return;
  }
  this.attempts--;
}

getCookieValue(name) {
  this.parts = document.cookie.split(name + "=");
  return this.parts.length === 2 ? this.parts.pop().split(";").shift() : null;
}
  clearLoading() {
  clearInterval(this.cookieTimer);
  this.expireCookie(this.tokenCookieName);
  this.attempts = 60;
  }

 expireCookie(cName) {
  document.cookie = encodeURIComponent(cName) + "=deleted; expires=" + new Date(0).toUTCString();
  }

  public showShareSocial=false; 
  btnShareClick()
  { 
    
    this.showShareSocial = !this.showShareSocial;
  }
  listshareClick(id){
    let targetElement = document.getElementById(id);
    targetElement?.classList.remove("hidden");  
  }
}
