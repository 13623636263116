import { Component } from '@angular/core';
import { Meta, Title }     from '@angular/platform-browser'; 
import { SeoService } from './seo.service';
import { ApicallService } from './service/apicall.service';
import { FeedbackModel } from './models/footermodel';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  seoDescription:any;
  seokeyword:any;
  domainname:any;
  isLoggedIn:any;
  defaultValue = '';
  public feedback = new FeedbackModel();
  public constructor(private titleService: Title,private service:ApicallService,private meta: Meta,private seo:SeoService, private toastr:ToastrService) {
   
   }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit(): void {
    this.domainname = this.service.getHostname();  
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.isLoggedIn=false;
    else
    {
      if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;
    }
    // for getting meta content     
    this.service.getAllContentMicrosites(this.domainname)
    .subscribe(data=>{
      this.seoDescription=data[0].seoDescription;    
      this.seokeyword = data[0].seoKeyword;  
      //update SEO meta data
      this.seo.generateTags({
        seoDescription: this.seoDescription,  
        seoKeyword: this.seokeyword ,  
        title: "CONTENTREADS IS THE LARGEST COLLECTION OF CASE STUDIES", 
        description:this.seoDescription,
        image: data[0].siteLogo,
        url:window.location.protocol+"//"+window.location.hostname+window.location.pathname
      })
  }); 

  

  }
 

  showNeedHelpModal = false;
  showMoreInfoModal = false;
  showSiteFeedbackModal = false;
  toggleNeedHelpModal(){
    this.showNeedHelpModal = !this.showNeedHelpModal;
  }
  toggleMoreInfoModal(){
    this.showMoreInfoModal = !this.showMoreInfoModal;
  } 
  toggleSiteFeedbackModal(){
    this.showSiteFeedbackModal = !this.showSiteFeedbackModal;
  }   

  sendFeedBack(form: NgForm){
    this.feedback.CurrentUrl = this.service.getHostname();
    if(this.isLoggedIn){
      this.feedback.UserId=sessionStorage.getItem('sessionuserId')??'';
    }   
    this.showSiteFeedbackModal =false;

    this.service.sendFeedBack(this.feedback)
    .subscribe(data =>
      {         
        this.toastr.success("Thanks for your feedback!","",{timeOut:3000,progressBar:true}); 
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        });  
        form.resetForm(); 
  }
}
