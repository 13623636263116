<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
</div>
<div class="bg-gray-100 py-6 flex flex-col justify-center sm:py-12 -mt-32">
    <div class="sm:max-w-xl sm:mx-auto">
        <div class="relative bg-white shadow-3xl rounded sm:p-20">
            <div class="max-w-md mx-auto w-96 p-3 md:p-0">
                <div class="divide-y divide-gray-200">
                    <div class="text-base leading-6 text-gray-700 sm:text-md sm:leading-7">
                        <h1 class=" text-3xl text-center mb-3">Reset Password</h1>
                        <div *ngIf="!showResetForm" class=" text-2xl text-center mb-3">{{errorMessage}}</div>
                        <div *ngIf="showResetForm">
                        <form #resetPasswordForm="ngForm">
                            <div class="form-group">
                                <label for="password" class=" block ">Password:</label>
                                <input type="password" name="password" #password="ngModel" required minlength="6"
                                    maxlength="100"
                                    class=" border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                    placeholder="Your password" name="password" [(ngModel)]="userdata.Password"
                                    [ngClass]="{'red-border-class': password.errors && (password.dirty || password.touched)}">
                                <div *ngIf="password.errors && (password.dirty || password.touched)">
                                    <div [hidden]="!password.errors.required" class="text-red-500 -mt-3">
                                        Please enter Password.
                                    </div>
                                    <div *ngIf="password.errors?.minlength || password.errors?.maxlength"
                                        class="text-red-500 -mt-3">Password length must be between 6 and 100 characters.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="confirmpassword" class=" block ">Confirm Password:</label>
                                <input appConfirmEqualValidator="password" type="password" #confirmpassword="ngModel"
                                    required minlength="6" maxlength="100"
                                    class=" border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                    placeholder="Your confirm password" name="confirmpassword"
                                    [(ngModel)]="userdata.ConfirmPassword"
                                    [ngClass]="{'red-border-class': confirmpassword.errors && (confirmpassword.dirty || confirmpassword.touched)}">
                                <div
                                    *ngIf="confirmpassword.errors && (confirmpassword.dirty || confirmpassword.touched)">
                                    <div [hidden]="!confirmpassword.errors.required" class="text-red-500 -mt-3">
                                        Please enter Confirm Password.
                                    </div>
                                    <div *ngIf="confirmpassword.errors?.minlength || confirmpassword.errors?.maxlength"
                                        class="text-red-500 -mt-3">Confirm Password length must be between 6 and 100
                                        characters.</div>
                                    <div *ngIf="confirmpassword.errors?.notEqual" class="text-red-500 -mt-3">Password
                                        and Confirm Password must match.</div>

                                </div>


                            </div>
                            <div class="text-center mb-3">
                                <button (click)="resetPassword();clicked=true"
                                    [disabled]="!resetPasswordForm.valid || clicked" (click)="toggleResetConfirmModal()"
                                    class="bg-green-500  rounded-lg text-white mt-5 p-3"
                                    [ngClass]="{'disabledbtn': !resetPasswordForm.valid}"><span class="p-4">
                                        Reset</span></button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-pagefooter></app-pagefooter>

