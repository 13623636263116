import { Component, OnInit } from '@angular/core';
import { RequestFormModel } from '../models/gdprrequestform';
import { NgForm } from '@angular/forms';
import { ApicallService } from '../service/apicall.service';
import { ToastrService } from 'ngx-toastr';
import { NeedHelpModel } from '../models/needhelp';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-gdpr-data-protection-policy',
  templateUrl: './gdpr-data-protection-policy.component.html',
  styleUrls: ['./gdpr-data-protection-policy.component.scss']
})
export class GdprDataProtectionPolicyComponent implements OnInit {
  showNeedHelpModal = false;
  public isLoggedIn=false;
  public requestForm = new RequestFormModel();
  public needHelp = new NeedHelpModel();
  domainname:any;
  supportemail:string;
  firstname:string;
  lastname:string;
  constructor(private service:ApicallService, private toastr:ToastrService) { }

  ngOnInit(): void {
    this.domainname = this.service.getHostname();
    this.supportemail=environment.helpemail;  
    if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;
      
      if(this.isLoggedIn){
        this.service.getAccountDetails(sessionStorage.getItem('sessionuserId'))
        .subscribe(data =>
        {
          this.requestForm.FirstName=data.firstName;
          this.requestForm.LastName=data.lastName;
          this.requestForm.Email=data.businessEmail??data.personalEmail;
          this.requestForm.Domain=this.domainname;
          this.firstname=data.firstName;
          this.lastname=data.lastName;
        }
        ,error => {  
           
        });  
      }
      
  }
  toggleNeedHelpModal(){
    this.showNeedHelpModal = !this.showNeedHelpModal;
  }
  sendRequestData(form: NgForm){
    this.service.sendPersonalDataRequest(this.requestForm)
    .subscribe(data =>
      {         
        this.toastr.success("Thanks for your feedback!","",{timeOut:3000,progressBar:true}); 
        form.controls["message"].reset();
        form.controls["requesttype"].reset();
        form.controls["firstName"].reset();
        form.controls["lastName"].reset();
        
        this.requestForm.FirstName=this.firstname;
        this.requestForm.LastName=this.lastname;
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        });  
        
  }
  
  sendEmailForHelp(form: NgForm){   
    if(this.isLoggedIn){
      this.needHelp.userid=sessionStorage.getItem('sessionuserId')??'';
    }   
    this.needHelp.isHelpMessage = true;
    this.needHelp.sourcePath = this.service.getHostname();
    this.needHelp.emailTo=this.supportemail; 
    this.needHelp.subject="Need Help?";
    this.showNeedHelpModal = false;

    this.service.sendNeedHelpMail(this.needHelp)
    .subscribe(data =>
      {         
        this.toastr.success("Your message has been sent to Contentreads.","",{timeOut:3000,progressBar:true}); 
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        }); 
        form.resetForm();  
  }
  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
