<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
</div>

<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-4 md:p-10 pt-4">
            <div *ngFor="let set of allSimpleForMicrositesList">
                <div *ngIf="set.id==this.solutionid">
                    <h2 class="md:flex items-center mb-10"> <span class="text-2xl">{{set.name}}</span>
                        <span
                            class="rounded-full bg-brand-green text-white h-10 w-10 flex items-center justify-center ml-3">{{solutionsForMicrositesListCount}}</span>
                        <span class="ml-auto font-semibold text-brand-gray">{{set.description}}</span>
                    </h2>
                </div>
            </div>
            <div class="grid gap-6 md:grid-cols-3 lg:grid-cols-5">

            <div *ngFor="let solution of solutionsForMicrositesList">
                <div class="w-64 min-h-80  p-3 flex">
                    <div class="cursor-pointer box-content p-2 bg-gray-100 shadow-md hover:shadow-2xl relative">
                        <div class="flex relative group justify-between" (mouseleave)="hideSolutionSolutionList()">
                            <div class="block w-48 h-60 hover:shadow-xl cursor-pointer" 
                            (click)="gotoCaseStudy(solution.caseStudyId)">
                                <img class="" src="{{solution.imageUrl}}" alt="Image" />
                            </div>

                            <div class="hidden group-hover:block d-sm-only relative">

                                <div class="mx-auto px-2">									                               
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" x="0" y="0"
                                            viewBox="0 0 6.3499999 6.3500002"
                                            class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                            fill="currentColor" stroke="currentColor" stroke-width="0.22" stroke-linecap="round" stroke-linejoin="round"
                                            (click)="candownloadFile(solution.caseStudyId,solution.caseStudyTitle)">
                                            <g transform="translate(0 -290.65)">
                                                <path
                                                    d="m3.1686225 290.91194a.26460982.26460982 0 0 0 -.2614833.26975v3.58015l-.5999628-.61754a.26460982.26460982 0 0 0 -.189138-.0822.26460982.26460982 0 0 0 -.189651.45114l1.0547169 1.08624a.26460982.26460982 0 0 0 .3787881 0l1.0609159-1.08418a.26460982.26460982 0 1 0 -.3772379-.37104l-.609264.62167v-3.58428a.26460982.26460982 0 0 0 -.2676839-.26975zm-2.66392012 5.29684a.26495232.26495232 0 0 0 .02532.52916h5.29115092a.264648.264648 0 1 0 0-.52916h-5.29115092a.26460982.26460982 0 0 0 -.02532 0z"
                                                    fill="currentColor" data-original="currentColor"></path>
                                            </g>
                                            <title>Download</title>
                                        </svg>
                                    <br />
                                    <svg class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6" (click)="toggleEditSolutionSetModal(solution)"
                                        fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.3"
                                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z">
                                        </path>
                                        <title>Edit</title>
                                    </svg><br />
                                    <svg class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6" (click)="toggleGetLinkModal(solution)"
                                        fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                                        </path>
                                        <title>Get Link</title>
                                    </svg><br />
                                    <svg class="text-gray-400 hover:text-gray-600 cursor-pointer stroke-current w-6 h-6"
                                        width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"
                                        (click)="btnShareClick()">
                                        <polyline points="15 14 20 9 15 4" />
                                        <path d="M4 20v-7a4 4 0 0 1 4-4h12" />
                                        <title>Share</title>
                                    </svg>
                                </div>
                                
                                <div *ngIf="showShareSocial" class="bg-white absolute -left-10 top-0 px-2 pb-2">
                                        <svg class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                            (click)="toggleShareCaseStudyViaEmailModal(solution)" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <rect x="3" y="5" width="18" height="14" rx="2" />
                                            <polyline points="3 7 12 13 21 7" />
                                            <title>Share Via Email</title>
                                        </svg><br />
    
                                        <svg (click)="funcs.shareViaFacebook(solution.caseStudyId, 'MyIndustolutions')" width="24" height="24"
                                            aria-hidden="true" focusable="false" data-prefix="fab"
                                            data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 320 512"
                                            class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                            <path fill="currentColor"
                                                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                                class=""></path>
                                            <title>Facebook</title>
                                        </svg><br>
                                        <svg (click)="funcs.shareViaLinkedin(solution.caseStudyId, 'MyIndustolutions')" width="24" height="24"
                                            aria-hidden="true" focusable="false" data-prefix="fab"
                                            data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                            <path fill="currentColor"
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                                class=""></path>
                                            <title>Linked In</title>
                                        </svg><br>
                                        <svg (click)="funcs.shareViaTwitter(solution.caseStudyId,solution.caseStudyTitle, 'MyIndustolutions')"
                                            width="24" height="24" aria-hidden="true" focusable="false"
                                            data-prefix="fab" data-icon="twitter" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                            class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                            <path fill="currentColor"
                                                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                                class=""></path>
                                            <title>Twitter</title>
                                        </svg>
                                    </div>

                            </div>

                        </div>
                        <div class="w-48 break-all pt-3">
                            <span
                                class="font-light text-md">{{ (solution.caseStudyTitle.length > 50) ? (solution.caseStudyTitle | slice:0:50) + '...' : solution.caseStudyTitle }}</span>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        </div>
    </div>
</div>



<!-- share case study via email-->
<div *ngIf="showShareCaseStudyViaEmailModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div
        class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 md:mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-start w-full ">
                <p class="text-gray-900 font-medium text-lg">Share Solution '{{casestudytitle}}'</p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer" (click)="closeShareCaseStudyViaEmailModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <div class="my-5 pt-4">
            <form #shareCaseStudyForm="ngForm" class="w-full">
                <div class="">
                    <div class="form-group ">
                        <label for="EmailsTo" class=" block ">Send to : <span class=" text-red-500 ">*</span></label>
                        <div class="">
                            <div *ngFor="let r of result.reverse()">
                                <div class="flex rounded-md clsemaillist">
                                    <button (click)="removeEmail(r)" type="button" class="tagbox__tag-remove"><img
                                            src="../assets/images/delete-solution-set.svg"></button>
                                    <span>{{r}}</span>
                                </div>
                            </div> 
                            <input type="email" #txtEmail="ngModel" required class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                placeholder="Add email address" name="txtEmail"
                                [ngClass]="{'red-border-class': txtEmail.errors && (txtEmail.dirty || txtEmail.touched)}"
                                [email]="true" (keydown.enter)="save($event)" ngModel>
                        </div>
                        <div *ngIf="txtEmail.errors && (txtEmail.dirty || txtEmail.touched)">
                            <div *ngIf="txtEmail.errors.required && emptyEmailList" class="text-red-500 -mt-3">
                                Please specify email address
                            </div>
                            <div *ngIf="txtEmail.errors && (!txtEmail.errors.required)" class="text-red-500 -mt-3">
                                Please use valid email.
                            </div>
                        </div>

                    </div>
                    <div class="form-group">
                        <label for="Message" class=" block ">Personal Mesaage : <span
                                class=" text-red-500 ">*</span></label>
                        <textarea #Message="ngModel" required class="border w-full h-20 p-3 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="Personal mesaage" name="Message" [(ngModel)]="mysolution.Message"
                            [ngClass]="{'red-border-class': Message.errors && (Message.dirty || Message.touched)}"></textarea>

                        <div *ngIf="Message.errors && (Message.dirty || Message.touched)">
                            <div [hidden]="!Message.errors.required" class="text-red-500">
                                Checkout this interesting Solution on Contentreads!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end mt-5">
                    <button class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                        [disabled]="!shareCaseStudyForm.valid" [ngClass]="{'disabledbtn': !shareCaseStudyForm.valid }"
                        (click)="shareCaseStudyViaEmail(shareCaseStudyForm)">Send Email</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showShareCaseStudyViaEmailModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>


<!-- get link popup-->
<div *ngIf="showGetLinkModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div
        class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-start w-full ">
                <p class="text-gray-900 font-medium text-lg">Direct link to '{{casestudytitle}}'</p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer" (click)="closeGetLinkModalModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <div class="my-5 pt-4">
            <form class="w-full">
                <div class="pt-10 pb-10">
                    <div class="w-full flex">
                        <div class="w-5/6">
                            <input type="text" value="{{fileLinktext}}"
                                class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md" #fileLink>

                        </div>
                        <div class="w-1/6 pl-5">
                            <span class="input-group-btn">
                                <button (click)="copyInputMessage(fileLink)">
                                    <svg class="cursor-pointer iconblue w-6 h-10" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3">
                                        </path>
                                        <title>Copy to clipboard</title>
                                    </svg>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between mt-5">
                    <button class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                        (click)="closeGetLinkModalModal()">Close</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showGetLinkModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<!--Edit Solution Set-->
<div *ngIf="showEditSolutionSetModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div
        class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-center w-full">
                <p class="text-gray-900 font-medium text-lg ">Edit Solution </p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer" (click)="closeEditSolutionSetModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <!--Body-->
            <div class="my-5 pt-4">
                <form #editSolutionForm="ngForm" class="w-full">
                    <div class="w-full md:flex">
                        <div class="w-full md:w-2/3">
                            <div class="form-group">
                                <label for="SolutionSet" class=" block ">Solution Set</label>
                                <div class="relative mt-2 mb-3">
                                <select #solutionSetId="ngModel" aria-selected="true"
                                    class="appearance-none border w-full px-4 py-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                    name="solutionSetId" [(ngModel)]="solutionData.solutionSetId">
                                    <option *ngFor="let set of allSimpleForMicrositesList" [value]="set.id">
                                        {{set.name}} </option>
                                </select>   
                                <div
                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20">
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                </div> 
                            </div>
                            </div>

                            <div class="form-group">
                                <label for="description" class=" block ">Description</label>
                                <textarea #description="ngModel" class="border w-full h-20 p-3 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                                    placeholder="What's your Solution Set about?" name="description"
                                    [(ngModel)]="solutionData.description"></textarea>
                            </div>
                        </div>
                        <div class="w-full md:w-1/3 p-5">
                            <div class="form-group">
                                <img class="w-48 h-60" src="{{solutionData.imageUrl}}" alt="Image" />
                            </div>
                        </div>
                    </div>
                    <br />

                    <div class="md:flex pt-10 justify-between">
                        <button class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="DeleteSolutionSetModal(solutionData.id)">Delete Solution</button>
                        <button class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="closeShowEditSolutionSetModal()">Cancel</button>
                        <button class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="UpdateSolutionSet()">Save Changes</button>
                    </div>
                </form>
            </div>


        </div>
    </div>
</div>
<div *ngIf="showEditSolutionSetModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<app-pagefooter></app-pagefooter>