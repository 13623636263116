<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10 w-full max-w-screen-2xl mx-auto">
        <section class="text-white body-font">
            <div class="w-full px-1 py-5">
                <div class="md:py-8 w-full flex flex-wrap item-center">
                    <form #filterForm="ngForm" class="w-full">
                        <div class="w-full md:w-7/12 md:flex flex-none item-center mx-auto">
                            <div class="w-full bg-white flex items-center rounded shadow-xl relative dropdown">
                                <input required #queryString="ngModel" name="queryString"
                                    [(ngModel)]="searchParamModel.queryString" (keyup.enter)="SearchCase()"
                                    class="rounded w-full p-5 text-brand-gray uppercase font-bold leading-tight focus:outline-none w-56 ng-pristine ng-invalid ng-touched"
                                    value="{{searchText}}">
                                <div>
                                    <a (click)="setTitle('Search Case Studies'); SearchCase()">
                                        <button [disabled]="!filterForm.valid"
                                            [ngClass]="{'disabledbtn': !filterForm.valid }"
                                            class="cursor-pointer text-white bg-brand-green px-5 py-2 w-14 flex items-center justify-center rounded-tr rounded-br">
                                            <svg class="text-white h-12 w-12 fill-current"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
                                                x="0px" y="0px" viewBox="0 0 56.966 56.966" xml:space="preserve"
                                                width="512px" height="512px">
                                                <path
                                                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                                            </svg>
                                        </button></a>
                                </div>


                                <div *ngIf="openAdvancePopup"
                                    class="absolute w-full top-full mt-2 p-4 bg-white rounded shadow-xl z-20 text-black">

                                    <h3 class="text-gray-900 font-medium text-lg text-left mb-3">Advanced Search
                                    </h3>
                                    <div>
                                        <div class="mb-4">
                                            <input id="rdoAll" type="radio" class="hidden" #queryType="ngModel"
                                                name="queryType" [(ngModel)]="searchParamModel.queryType" value="all" />
                                            <label for="rdoAll" class="flex items-center cursor-pointer">
                                                <span
                                                    class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                                All these words </label>
                                        </div>
                                        <div class="mb-4">
                                            <input type="radio" class="hidden" #queryType="ngModel" name="queryType"
                                                [(ngModel)]="searchParamModel.queryType" id="rdoPhrase"
                                                value="phrase" />
                                            <label for="rdoPhrase" class="flex items-center cursor-pointer">
                                                <span
                                                    class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                                This exact word or phrase </label>
                                        </div>
                                        <div class="mb-4">
                                            <input type="radio" class="hidden" #queryType="ngModel" name="queryType"
                                                [(ngModel)]="searchParamModel.queryType" id="rdoAnyWord" value="any" />
                                            <label for="rdoAnyWord" class="flex items-center cursor-pointer">
                                                <span
                                                    class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                                With at least one of the words </label>
                                        </div>

                                    </div>

                                    <hr>
                                    <!--Footer-->

                                    <div class="flex justify-between p-4">
                                        <button
                                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer"
                                            (click)="toggleAdvancePopup()">Cancel</button>
                                        <button [disabled]="!filterForm.valid"
                                            class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer"
                                            [ngClass]="{'disabledbtn': !filterForm.valid }"
                                            (click)="toggleAdvancePopup();SearchCase()">Search</button>
                                    </div>


                                </div>
                            </div>

                            <div
                                class="whitespace-nowrap cursor-pointer p-3 flex flex-wrap content-center justify-center">
                                <div *ngFor="let mcontent of microSiteContent">
                                    <div *ngIf="mcontent.section == 'SearchPageAdvanced' && mcontent.isEnabled"
                                        class="font-semibold text-lg h-full flex flex-wrap content-center"
                                        (click)="toggleAdvancePopup()">
                                        {{mcontent.content}}</div>
                                </div>
                            </div>

                        </div>



                    </form>

                </div>

                <div class="md:w-2/4 flex item-center mx-auto flex justify-center text-xl">
                    <div *ngIf="caseStudyCount > 0">
                        <span *ngFor="let mcontent of microSiteContent">
                            <span *ngIf="mcontent.section == 'SearchResult' && mcontent.isEnabled" class="">
                                {{mcontent.content}} {{caseStudyCount}}
                            </span>

                            <span *ngIf="mcontent.section == 'RefineResult' && mcontent.isEnabled"
                                class="ml-4 cursor-pointer underline" (click)="toggleRefineSearchModal()">
                                {{mcontent.content}} </span>
                            <div *ngIf="mcontent.section == 'RefineResult' && mcontent.isEnabled" class="flex">
                                <div *ngFor="let searchresult of result.reverse()">
                                    <div class="bg-brand-green flex rounded-md p-1 text-xs my-1 mr-2">
                                        <button (click)="removeRefineSearchdiv(searchresult)" type="button"
                                            class="tagbox__tag-remove px-2 pt-1"><img
                                                src="../../assets/images/close_icon.svg"
                                                class="h-2 filter invert"></button>
                                        <span>{{searchresult}}</span>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-2 sm:px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-5 sm:p-10 m-h-400">
            <span *ngIf="caseStudyCount==0" class="p-3 bg-brand-gray text-white text-xl w-full block">No result
                found.</span>
            <div *ngIf="caseStudyCount > 0">
                <span *ngFor="let mcontent of microSiteContent">
                    <h1 *ngIf="mcontent.section == 'SearchPageFeaturedCaseStudyTitle' && mcontent.isEnabled"
                        class="text-3xl mb-4">{{mcontent.content}}</h1>
                </span>
            </div>


            <div *ngFor="let mcontent of microSiteContent">
                <div *ngIf="mcontent.section == 'SearchPageCaseStudyResult' && mcontent.isEnabled" class="">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem class="slide pb-10 focus:outline-none"
                            *ngFor="let case of casestudySearchResult">
                            <div class="cursor-pointer box-content relative border border-gray-200 rounded mx-4"
                                (mouseleave)="hideSolutionSolutionList()">
                                <div class="flex relative group justify-between">
                                    <div class="w-full hover:shadow-xl cursor-pointer rounded-t"
                                        (click)="setTitle('Case Study'); openCaseStudy(case.fileName)">
                                        <img *ngIf="!case['videoUrl']" src="{{blobStorageUrl}}/{{case.fileName}}_C.png"
                                            alt="Image" />
                                    </div>

                                    <div class="hidden group-hover:block ">

                                        <div class="p-2 pr-12 h-full bg-brand-green text-white border-gray-200 hover:shadow-xl cursor-pointer absolute top-0 left-0"
                                            (click)="setTitle('Case Study'); openCaseStudy(case.fileName)">
                                            {{ (case.contentIndexed.length > 145) ? (case.contentIndexed | slice:0:145) + '...' : case.contentIndexed }}
                                        </div>
                                    </div>
                                    <div class="hidden group-hover:block d-sm-only absolute right-0 top-0 bg-white">
                                        <div class="flex justify-end cursor-pointer p-2" title="Download"
                                            (click)="candownloadFile(case.fileName,case.title)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" x="0" y="0"
                                                viewBox="0 0 6.3499999 6.3500002"
                                                class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                                fill="currentColor" stroke="currentColor" stroke-width="0.22" stroke-linecap="round" stroke-linejoin="round">
                                                <g transform="translate(0 -290.65)">
                                                    <path
                                                        d="m3.1686225 290.91194a.26460982.26460982 0 0 0 -.2614833.26975v3.58015l-.5999628-.61754a.26460982.26460982 0 0 0 -.189138-.0822.26460982.26460982 0 0 0 -.189651.45114l1.0547169 1.08624a.26460982.26460982 0 0 0 .3787881 0l1.0609159-1.08418a.26460982.26460982 0 1 0 -.3772379-.37104l-.609264.62167v-3.58428a.26460982.26460982 0 0 0 -.2676839-.26975zm-2.66392012 5.29684a.26495232.26495232 0 0 0 .02532.52916h5.29115092a.264648.264648 0 1 0 0-.52916h-5.29115092a.26460982.26460982 0 0 0 -.02532 0z"
                                                        fill="currentColor" data-original="currentColor"></path>
                                                </g>
                                                <title>Download</title>
                                            </svg>
                                        </div>
                                        <div class="flex justify-end cursor-pointer p-2"
                                            title="Save to a Personal Solution Set" (click)="toggleSolutionList()">
                                            <svg class="text-gray-400 hover:text-gray-600 cursor-pointer stroke-current w-6 h-6"
                                                width="24" height="24" viewBox="0 0 24 24"
                                                fill="currentColor" stroke="currentColor" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                <title>{{mcontent.content}}</title>
                                            </svg>
                                        </div>
                                        <div class="flex justify-end cursor-pointer p-2" (click)="btnShareClick()">
                                            <svg class="text-gray-400 hover:text-gray-600 cursor-pointer stroke-current w-6 h-6"
                                                width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                                                <polyline points="15 14 20 9 15 4" />
                                                <path d="M4 20v-7a4 4 0 0 1 4-4h12" />
                                                <title>Share</title>
                                            </svg>
                                        </div>
                                        <div *ngIf="showShareSocial" class="bg-white absolute -left-10 top-0">
                                            <div class="flex justify-end cursor-pointer p-2">
                                                <svg (click)="funcs.shareViaFacebook(case.fileName,'SearchPage')"
                                                    width="24" height="24" aria-hidden="true" focusable="false"
                                                    data-prefix="fab" data-icon="facebook-f" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                    class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                                    <path fill="currentColor"
                                                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                                        class=""></path>
                                                    <title>Facebook</title>
                                                </svg>
                                            </div>
                                            <div class="flex justify-end cursor-pointer p-2">
                                                <svg (click)="funcs.shareViaLinkedin(case.fileName, 'SearchPage')"
                                                    width="24" height="24" aria-hidden="true" focusable="false"
                                                    data-prefix="fab" data-icon="linkedin-in" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                    class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                                    <path fill="currentColor"
                                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                                        class=""></path>
                                                    <title>Linked In</title>
                                                </svg>
                                            </div>
                                            <div class="flex justify-end cursor-pointer p-2">
                                                <svg (click)="funcs.shareViaTwitter(case.fileName,case.title, 'SearchPage')"
                                                    width="24" height="24" aria-hidden="true" focusable="false"
                                                    data-prefix="fab" data-icon="twitter" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                    class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                                    <path fill="currentColor"
                                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                                        class=""></path>
                                                    <title>Twitter</title>
                                                </svg>
                                            </div>
                                        </div>
                                        <div *ngIf="showSolutionList"
                                            class="absolute top-0 right-full p-2 w-52 bg-gray-100 shadow-md hover:shadow-2xl z-10">
                                            <div class="text-xs ">
                                                <div>
                                                    <div class="cursor-pointer"> <a
                                                            class="block py-3 font-bold hover:text-brand-green"
                                                            (click)="openCreatePersonalSolutionSetModal(case.fileName)">Add
                                                            to a new Personal Solution Set </a></div>
                                                </div>
                                                <hr>
                                                <div class="overflow-y-auto h-64">
                                                    <div class="space-y-4 pb-3-" *ngFor="let set of solutionSetList">
                                                        <div class="hover:bg-gray-100 cursor-pointer"> <a
                                                                class="cursor-pointer block p-2 border-b border-gray-400 border-dashed hover:bg-gray-200"
                                                                (click)="addCaseStudyToSolutionSet(case.fileName,set.id)">{{set.name}}
                                                            </a></div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-48 break-all p-3 md:bg-transparent bg-white">
                                    <!-- titlecase -->
                                    <div><span class="text-md "
                                            [innerHTML]="(case.titleHighlighted.length > 50) ? (case.titleHighlighted | titlecase | slice:0:50) + '...' : case.titleHighlighted | titlecase "></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>



            <div *ngFor="let mcontent of microSiteContent">
                <div *ngIf="mcontent.section == 'SearchPageCaseStudyResult' && mcontent.isEnabled" class="">

                    <div
                        *ngFor="let list of listingResult | paginate: { itemsPerPage: 10, currentPage: currentPage, totalItems:caseStudyCount-6}">

                        <hr class="my-6">
                        <div class="bg-white rounded shadow-md mb-8 md:flex">
                            <div class="md:w-2/5 lg:w-1/5 md:pr-6">
                                <div class="cursor-pointer box-content relative"
                                    (mouseleave)="hideSolutionSolutionListForResults()">
                                    <div class="flex relative group">
                                        <div (click)="setTitle('Case Study'); openCaseStudy(list.fileName)">
                                            <img *ngIf="!list['videoUrl']"
                                                src="{{blobStorageUrl}}/{{list.fileName}}_C.png" alt="Image" />
                                        </div>
                                        <div class="hidden group-hover:block d-sm-only absolute right-0 top-0 bg-white">
                                            <div class="cursor-pointer p-2" title="Download"
                                                (click)="candownloadFile(list.fileName,list.title)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" x="0" y="0"
                                                    viewBox="0 0 6.3499999 6.3500002"
                                                    class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6"
                                                    fill="currentColor" stroke="currentColor" stroke-width="0.22" stroke-linecap="round" stroke-linejoin="round">
                                                    <g transform="translate(0 -290.65)">
                                                        <path
                                                            d="m3.1686225 290.91194a.26460982.26460982 0 0 0 -.2614833.26975v3.58015l-.5999628-.61754a.26460982.26460982 0 0 0 -.189138-.0822.26460982.26460982 0 0 0 -.189651.45114l1.0547169 1.08624a.26460982.26460982 0 0 0 .3787881 0l1.0609159-1.08418a.26460982.26460982 0 1 0 -.3772379-.37104l-.609264.62167v-3.58428a.26460982.26460982 0 0 0 -.2676839-.26975zm-2.66392012 5.29684a.26495232.26495232 0 0 0 .02532.52916h5.29115092a.264648.264648 0 1 0 0-.52916h-5.29115092a.26460982.26460982 0 0 0 -.02532 0z"
                                                            fill="currentColor" data-original="currentColor"></path>
                                                    </g>
                                                    <title>Download</title>
                                                </svg>
                                            </div>
                                            <div class="cursor-pointer p-2" title="Save to a Personal Solution Set"
                                                (click)="listAddCaseStudy(list.fileName)">
                                                <svg class="text-gray-400 hover:text-gray-600 cursor-pointer stroke-current w-6 h-6"
                                                    width="24" height="24" viewBox="0 0 24 24"
                                                    fill="currentColor" stroke="currentColor" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round">
                                                    <path d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                    <title>{{mcontent.content}}</title>
                                                </svg>
                                            </div>
                                            <div class="flex justify-end cursor-pointer p-2" (click)="listshareClick('s'+list.fileName)">
                                                <svg class="text-gray-400 hover:text-gray-600 cursor-pointer stroke-current w-6 h-6"
                                                    width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                                                    <polyline points="15 14 20 9 15 4" />
                                                    <path d="M4 20v-7a4 4 0 0 1 4-4h12" />
                                                    <title>Share</title>
                                                </svg>
                                            </div>
                                            <div id="s{{list.fileName}}" name="divSocialShare" class="hidden bg-white absolute -left-10 top-0">
                                                <div class="flex justify-end cursor-pointer p-2">
                                                    <svg (click)="funcs.shareViaFacebook(list.fileName, 'SearchPage')"
                                                        width="24" height="24" aria-hidden="true" focusable="false"
                                                        data-prefix="fab" data-icon="facebook-f" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                        class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                                        <path fill="currentColor"
                                                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                                            class=""></path>
                                                        <title>Facebook</title>
                                                    </svg>
                                                </div>
                                                <div class="flex justify-end cursor-pointer p-2">
                                                    <svg (click)="funcs.shareViaLinkedin(list.fileName, 'SearchPage')"
                                                        width="24" height="24" aria-hidden="true" focusable="false"
                                                        data-prefix="fab" data-icon="linkedin-in" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                        class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                                        <path fill="currentColor"
                                                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                                            class=""></path>
                                                        <title>Linked In</title>
                                                    </svg>
                                                </div>
                                                <div class="flex justify-end cursor-pointer p-2">
                                                    <svg (click)="funcs.shareViaTwitter(list.fileName,list.title, 'SearchPage')"
                                                        width="24" height="24" aria-hidden="true" focusable="false"
                                                        data-prefix="fab" data-icon="twitter" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                        class="text-gray-400 hover:text-gray-600 cursor-pointer w-6 h-6">
                                                        <path fill="currentColor"
                                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                                            class=""></path>
                                                        <title>Twitter</title>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div id="{{list.fileName}}" name="divSolutionList"
                                                class="hidden absolute top-0 right-full p-2 w-52 bg-gray-100 shadow-md hover:shadow-2xl z-10">
                                                <div class="text-xs">
                                                    <div class="text-xs">
                                                        <div>
                                                            <div class="cursor-pointer"> <a
                                                                    class="block py-3 font-bold hover:text-brand-green"
                                                                    (click)="openCreatePersonalSolutionSetModal(list.fileName)">Add
                                                                    to a
                                                                    new Personal Solution Set </a></div>
                                                        </div>
                                                        <hr>
                                                        <div class="overflow-y-auto h-48">
                                                            <div class="space-y-4 pb-3-"
                                                                *ngFor="let set of solutionSetList">
                                                                <div class="hover:bg-gray-100 cursor-pointer"> <a
                                                                        class="cursor-pointer block p-2 border-b border-gray-400 border-dashed hover:bg-gray-200"
                                                                        (click)="addCaseStudyToSolutionSet(list.fileName,set.id)">{{set.name}}
                                                                    </a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="md:w-3/5  lg:w-4/5">
                                <a (click)="setTitle('Case Study'); openCaseStudy(list.fileName)"
                                    class="cursor-pointer"><span
                                        class="text-brand-green font-semibold text-lg hover:underline"
                                        [innerHTML]="list.titleHighlighted"> </span></a>
                                <div *ngFor="let content of list['highlights']">
                                    <p class="mx-auto leading-relaxed" [innerHTML]="content"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="listingResultCount>0" class="text-center w-full mx-auto">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>

        </div>
    </div>
</div>




<!-- Join Contentree Modal-->
<div *ngIf="showJoinContentreeModal"
    class="fixed text-gray-500 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-40 left-0 right-0 top-0 bottom-0"
    x-transition:enter="transition ease duration-300" x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100" x-transition:leave="transition ease duration-300"
    x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12  lg:w-4/12  mx-10"
        x-transition:enter="transition ease duration-100 transform"
        x-transition:enter-start="opacity-0 scale-90 translate-y-1"
        x-transition:enter-end="opacity-100 scale-100 translate-y-0"
        x-transition:leave="transition ease duration-100 transform"
        x-transition:leave-start="opacity-100 scale-100 translate-y-0"
        x-transition:leave-end="opacity-0 scale-90 translate-y-1">
        <div class="flex justify-end">
            <svg (click)="toggleJoinContentreeModal()" class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
            </svg>
        </div>

        <div class="text-gray-900 font-medium text-lg text-center">Join Contentreads and get access to the world's
            largest collection of B2B case studies.</div>
        <div class="py-4">

            <div class="flex justify-center pt-10">

                <div class="w-68  text-center pl-10 pr-10">

                    <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 91 104">
                        <g fill="none" fill-rule="evenodd" stroke="#587B93" stroke-width="1.3">
                            <path fill="#D01317"
                                d="M42.646 57.737c-2.218 0-4.022 1.782-4.022 3.973 0 2.19 1.804 3.973 4.022 3.973s4.022-1.782 4.022-3.973c0-2.19-1.804-3.973-4.022-3.973m0 8.152c-2.333 0-4.231-1.874-4.231-4.179 0-2.304 1.898-4.179 4.23-4.179 2.334 0 4.232 1.875 4.232 4.18 0 2.304-1.898 4.178-4.231 4.178" />
                            <path fill="#D01317"
                                d="M42.645 57.737a.103.103 0 0 1-.103-.103v-18.59a.103.103 0 0 1 .207 0v18.59a.103.103 0 0 1-.104.103" />
                            <path fill="#D01317"
                                d="M42.725 90.975c-16.364 0-29.678-13.15-29.678-29.314a29.154 29.154 0 0 1 6.636-18.476 29.841 29.841 0 0 1 16.61-10.147.105.105 0 0 1 .125.079.103.103 0 0 1-.08.122 29.63 29.63 0 0 0-16.493 10.076 28.948 28.948 0 0 0-6.59 18.346c0 16.05 13.22 29.108 29.47 29.108s29.47-13.057 29.47-29.108c0-6.684-2.35-13.212-6.62-18.382a29.627 29.627 0 0 0-16.558-10.06.103.103 0 0 1-.08-.123.105.105 0 0 1 .125-.078 29.837 29.837 0 0 1 16.675 10.131 29.148 29.148 0 0 1 6.666 18.512c0 16.164-13.313 29.314-29.678 29.314" />
                            <path fill="#D01317"
                                d="M42.72 103.047C19.717 103.047 1 84.56 1 61.838 1 39.116 19.716 20.63 42.72 20.63c5.24 0 10.35.948 15.189 2.816.053.02.08.08.059.133a.104.104 0 0 1-.135.059 41.779 41.779 0 0 0-15.112-2.802c-22.89 0-41.512 18.394-41.512 41.002 0 22.609 18.622 41.003 41.512 41.003s41.512-18.394 41.512-41.003c0-5.593-1.12-11.014-3.33-16.113a.103.103 0 0 1 .056-.136.104.104 0 0 1 .137.055 40.507 40.507 0 0 1 3.345 16.194c0 22.723-18.716 41.209-41.72 41.209" />
                            <path fill="#D01317"
                                d="M26.42 45.787a.105.105 0 0 1-.076-.033l-4.482-4.8a.102.102 0 0 1 .006-.145.105.105 0 0 1 .148.006l4.481 4.8a.102.102 0 0 1-.006.145.105.105 0 0 1-.07.027M58.871 45.787a.104.104 0 0 1-.072-.029.102.102 0 0 1-.003-.145l4.641-4.8a.105.105 0 0 1 .147-.003c.042.04.044.105.004.146l-4.641 4.8a.105.105 0 0 1-.076.031M65.593 61.814a.104.104 0 0 1-.104-.102c0-.057.046-.104.104-.105l6.705-.049h.001c.057 0 .104.046.105.103a.104.104 0 0 1-.104.104l-6.706.05zM19.698 61.814l-6.547-.049a.104.104 0 0 1-.104-.104c0-.057.047-.103.105-.103l6.547.05c.058 0 .104.047.104.104a.104.104 0 0 1-.105.102M21.94 82.54a.105.105 0 0 1-.072-.027.102.102 0 0 1-.005-.146l4.482-4.701a.106.106 0 0 1 .147-.005c.042.04.044.104.004.146l-4.481 4.701a.105.105 0 0 1-.076.033M42.724 90.975a.103.103 0 0 1-.103-.102l-.08-6.496c0-.057.046-.104.103-.104.056-.007.104.045.105.102l.079 6.496a.103.103 0 0 1-.103.104h-.001zM63.512 82.54a.105.105 0 0 1-.074-.03l-4.641-4.702a.102.102 0 0 1 .002-.146.106.106 0 0 1 .147.002l4.641 4.702c.04.04.04.106-.002.145a.104.104 0 0 1-.073.03M36.387 21.31a.103.103 0 0 1-.104-.104V10.824c0-.057.047-.103.104-.103.057 0 .103.046.103.103v10.382a.103.103 0 0 1-.103.103M48.903 21.287a.103.103 0 0 1-.103-.103v-10.36a.103.103 0 0 1 .207 0v10.36a.103.103 0 0 1-.104.103" />
                            <path fill="#D01317"
                                d="M35.61 1.206c-2.99 0-5.424 2.403-5.424 5.357V9.36c0 .75.619 1.361 1.379 1.361h22.024c.76 0 1.379-.61 1.379-1.361V6.659c0-3.007-2.477-5.453-5.52-5.453H35.608zm17.98 9.721H31.564a1.58 1.58 0 0 1-1.587-1.567V6.563C29.978 3.495 32.504 1 35.609 1h13.838c3.16 0 5.73 2.538 5.73 5.659v2.7a1.58 1.58 0 0 1-1.588 1.568zM11.687 34.684a.105.105 0 0 1-.073-.03L4.845 27.97a4.197 4.197 0 0 1-1.26-3.006c0-1.135.448-2.202 1.26-3.005a4.303 4.303 0 0 1 3.044-1.245c1.149 0 2.23.442 3.043 1.245l6.902 6.915c.04.04.04.106-.001.146a.106.106 0 0 1-.148-.002l-6.902-6.914a4.093 4.093 0 0 0-2.894-1.184c-1.094 0-2.122.42-2.896 1.184a3.994 3.994 0 0 0-1.2 2.86c0 1.08.427 2.096 1.2 2.86l6.768 6.685c.04.04.04.106 0 .146a.105.105 0 0 1-.074.03" />
                            <path fill="#D0161A"
                                d="M77.051 15.129c-7.022 0-12.736 5.643-12.736 12.58 0 6.936 5.714 12.579 12.736 12.579 7.023 0 12.736-5.643 12.736-12.58 0-6.936-5.713-12.58-12.736-12.58m-12.903 12.58c0-7.028 5.788-12.745 12.903-12.745 7.116 0 12.904 5.717 12.904 12.745s-5.788 12.746-12.904 12.746c-7.115 0-12.903-5.718-12.903-12.746z" />
                            <path fill="#E73838" stroke-linecap="round" stroke-linejoin="round"
                                d="M74.24 22.575c.342.102.646.261.911.477.266.216.48.492.645.827.165.335.247.738.247 1.209 0 .388-.062.75-.188 1.085a4.406 4.406 0 0 1-.505.96 7.629 7.629 0 0 1-.733.9c-.278.294-.57.594-.874.9l-3.642 3.67c.103-.01.222-.017.358-.022.135-.005.286-.008.453-.008h5.338c.049 0 .073.025.073.073v.088h-6.458v-.059c0-.029.008-.053.022-.072a.435.435 0 0 1 .037-.044l3.716-3.728c.305-.306.592-.605.862-.896.27-.291.507-.588.708-.889.202-.3.361-.611.48-.932a2.95 2.95 0 0 0 .176-1.026c0-.442-.076-.822-.228-1.14a2.238 2.238 0 0 0-.605-.783 2.487 2.487 0 0 0-.855-.451 3.352 3.352 0 0 0-.98-.146c-.384 0-.737.058-1.058.175-.322.117-.606.28-.852.491a2.686 2.686 0 0 0-.608.754 3.03 3.03 0 0 0-.328.969c-.015.077-.054.116-.118.116h-.074c.054-.408.166-.775.336-1.103a2.758 2.758 0 0 1 1.57-1.362 3.339 3.339 0 0 1 1.132-.185c.353 0 .701.05 1.043.152z" />
                            <path fill="#E73838"
                                d="M85.052 27.636c0-.874-.092-1.628-.276-2.264-.185-.636-.434-1.162-.749-1.577a3.024 3.024 0 0 0-1.102-.925 3.058 3.058 0 0 0-1.338-.302c-.472 0-.919.101-1.342.302-.422.202-.792.51-1.11.925-.316.415-.567.94-.751 1.577-.185.636-.277 1.39-.277 2.264 0 .874.092 1.63.277 2.265.184.636.435 1.16.752 1.573.317.413.687.718 1.11.917.422.2.87.299 1.341.299.472 0 .918-.1 1.338-.299a3 3 0 0 0 1.102-.917c.315-.413.564-.937.749-1.573.184-.636.276-1.39.276-2.265m.192 0c0 .894-.097 1.667-.291 2.32-.195.653-.458 1.193-.79 1.62-.331.427-.72.744-1.164.95-.445.207-.916.31-1.412.31-.496 0-.967-.103-1.412-.31a3.148 3.148 0 0 1-1.165-.95c-.331-.427-.594-.967-.788-1.62-.195-.653-.292-1.426-.292-2.32 0-.893.097-1.666.292-2.319.194-.653.457-1.194.788-1.624.332-.43.72-.748 1.165-.957a3.283 3.283 0 0 1 1.412-.313c.496 0 .967.104 1.412.313.445.209.833.528 1.165.957.331.43.594.971.789 1.624.194.653.29 1.426.29 2.32" />
                        </g>
                    </svg> It is free to register and takes 20 seconds
                </div>

            </div>
        </div>
        <div class="flex justify-between mt-5">
            <button (click)="toggleJoinContentreeModal()"
                class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer">Cancel</button>
            <a (click)="openRegister()" routerLinkActive="active"
                class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer">Sign
                Up</a>
        </div>
    </div>
</div>
<div *ngIf="showJoinContentreeModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<!-- refine your result modal-->
<div id="data2" *ngIf="showRefineSearchModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">

            <div class="flex items-center w-full">
                <div class="text-gray-900 font-medium text-lg">Search Within Results:</div>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    (click)="toggleRefineSearchModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>

            <div class="pt-4">
                <form #refineForm="ngForm">
                    <div class="">
                        <input type="text" required #additionalQuery="ngModel" name="additionalQuery" autocomplete="off"
                            class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="Type to search" ngModel (keydown.enter)="RefineSearchClick(refineForm)">
                    </div>
                    <div class="flex">
                        <div *ngFor="let r of result.reverse()">
                            <div class="bg-gray-200 flex rounded-md p-1 text-xs mt-2 mr-2">
                                <button (click)="removeRefineSearch(r)" type="button"
                                    class="tagbox__tag-remove px-2 pt-1"><img src="../../assets/images/close_icon.svg"
                                        class="h-2"></button>
                                <span>{{r}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between mt-5">
                        <button
                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                            (click)="resetClick()">Reset</button>
                        <button type="submit" [disabled]="!refineForm.valid"
                            class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                            [ngClass]="{'disabledbtn': !refineForm.valid }"
                            (click)="RefineSearchClick(refineForm)">Search</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showRefineSearchModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<!--Create a new Personal Solution Set-->
<div *ngIf="showCreatePersonalSolutionSetModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-center w-full">
                <p class="text-gray-900 font-medium text-lg ">Create a new Personal Solution Set</p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    (click)="closeCreatePersonalSolutionSetModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <!--Body-->
            <div class="my-5 pt-4">
                <form #createPersonalSolutionSetForm="ngForm" class="w-full">
                    <div class="form-group">
                        <label for="Name" class=" block ">Name</label>
                        <input type="text" #Name="ngModel" required
                            class="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md"
                            placeholder="Enter a name" name="Name" [(ngModel)]="personalSolutionData.Name"
                            [ngClass]="{'red-border-class': Name.errors && (Name.dirty || Name.touched)}">

                        <div *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="alert">
                            <div *ngIf="Name.errors?.required" class="text-red-500">Please enter a name</div>
                        </div>
                    </div>

                    <div class="flex justify-between mt-5">
                        <button
                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                            (click)="closeCreatePersonalSolutionSetModal()">Cancel
                        </button>
                        <button
                            class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                            [disabled]="!createPersonalSolutionSetForm.valid"
                            [ngClass]="{'disabledbtn': !createPersonalSolutionSetForm.valid}"
                            (click)="createWithCaseStudyForMicrosites()">Save solution to new solution set</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showCreatePersonalSolutionSetModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<app-pagefooter></app-pagefooter>