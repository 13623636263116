import { Injectable } from '@angular/core';
import { ApicallService } from './apicall.service';
import { addCsToMyIndustolutionsModel,analyticsWriteShareCaseStudyModel, analyticsWriteDownloadCaseStudyModel, analyticsWriteModel, CaseStudyViewSource, downloadCaseStudyModel, searchparams, SocialMediaType } from '../common/searchparams';

@Injectable({
  providedIn: 'root'
})

export class FuncsService {
  public caseStudyParam = new downloadCaseStudyModel();
  public analyticsWriteDownloadCaseStudyParam = new analyticsWriteDownloadCaseStudyModel();  
  public addCsToMyIndustolutionsParam = new addCsToMyIndustolutionsModel();
  public analyticsWriteShareCaseStudyParam = new analyticsWriteShareCaseStudyModel();
  humanReadableId: any;
  titleNormalized: any;
  source = [];
  public casestudyUrl;
  domainname:any; 
  userIp:any;
  constructor(private service:ApicallService) { }
  ngOnInit(): void {
    this.domainname = this.service.getHostname();     
  }

  shareViaFacebook(caseStudyId, pageName = 'MyIndustolutions')
   {      
    this.domainname = this.service.getHostname();
    this.source = this.getSource(pageName); 
     this.service.getCaseStudyUrlParts(caseStudyId)    
     .subscribe(data => {
       this.humanReadableId = data['humanReadableId'];
       this.titleNormalized =  data['titleNormalized'];    
       //checkExistence      
       this.service.checkExistence(this.humanReadableId) 
       .subscribe(data => { 
         this.casestudyUrl =this.service.getHostNameWithProtocol()+"/"+this.source['sourceName']+"/"+this.titleNormalized+"_"+this.humanReadableId;
         window.open("https://www.facebook.com/sharer/sharer.php?u="+this.casestudyUrl);
         
         //analytics write for Case Study Share Social Media
         this.analyticsWriteShareCaseStudyParam.csId=caseStudyId;
         this.analyticsWriteShareCaseStudyParam.source= this.source['sourceId'];
         this.analyticsWriteShareCaseStudyParam.domain=this.domainname;
         this.analyticsWriteShareCaseStudyParam.type=SocialMediaType.Facebook;
         this.analyticsWriteShareCaseStudyParam.userIP=this.userIp;
         this.analyticsWriteShareCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
         this.service.analyticsWriteCaseStudyShareSocialMedia(this.analyticsWriteShareCaseStudyParam)
         .subscribe(data=> {
         },error =>{ }); 
 
 
       }
       ,(error) => { }); 
     }
     ,(error) => {  
     });
     
   }


   shareViaLinkedin(caseStudyId, pageName = 'MyIndustolutions')
  { 
    this.domainname = this.service.getHostname();
    this.source = this.getSource(pageName); 
    this.service.getCaseStudyUrlParts(caseStudyId)    
    .subscribe(data => {
      this.humanReadableId = data['humanReadableId'];
      this.titleNormalized =  data['titleNormalized'];    
      //checkExistence      
      this.service.checkExistence(this.humanReadableId) 
      .subscribe(data => { 
        this.casestudyUrl =this.service.getHostNameWithProtocol()+"/"+this.source['sourceName']+"/"+this.titleNormalized+"_"+this.humanReadableId;
        window.open("https://www.linkedin.com/sharing/share-offsite/?url="+this.casestudyUrl);
        
         //analytics write for Case Study Share Social Media
         this.analyticsWriteShareCaseStudyParam.csId=caseStudyId;
         this.analyticsWriteShareCaseStudyParam.source= this.source['sourceId'];
         this.analyticsWriteShareCaseStudyParam.domain=this.domainname;
         this.analyticsWriteShareCaseStudyParam.type=SocialMediaType.LinkedIn;
         this.analyticsWriteShareCaseStudyParam.userIP=this.userIp;
         this.analyticsWriteShareCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
         this.service.analyticsWriteCaseStudyShareSocialMedia(this.analyticsWriteShareCaseStudyParam)
         .subscribe(data=> {
         },error =>{ }); 

      }
      ,(error) => { }); 
    }
    ,(error) => {  
    });
  }


   //share via twitter
   shareViaTwitter(caseStudyId,caseStudyTitle,pageName = 'MyIndustolutions')
   {  
    this.domainname = this.service.getHostname();
    this.source = this.getSource(pageName); 
     this.service.getCaseStudyUrlParts(caseStudyId)    
     .subscribe(data => {
       this.humanReadableId = data['humanReadableId'];
       this.titleNormalized =  data['titleNormalized'];    
       //checkExistence      
       this.service.checkExistence(this.humanReadableId) 
       .subscribe(data => { 
         this.casestudyUrl =this.service.getHostNameWithProtocol()+"/"+this.source['sourceName']+"/"+this.titleNormalized+"_"+this.humanReadableId;
         window.open("https://twitter.com/intent/tweet?text="+caseStudyTitle+"&url="+this.casestudyUrl);
         
          //analytics write for Case Study Share Social Media
          this.analyticsWriteShareCaseStudyParam.csId=caseStudyId;
          this.analyticsWriteShareCaseStudyParam.source= this.source['sourceId'];
          this.analyticsWriteShareCaseStudyParam.domain=this.domainname;
          this.analyticsWriteShareCaseStudyParam.type=SocialMediaType.Twitter;
          this.analyticsWriteShareCaseStudyParam.userIP=this.userIp;
         this.analyticsWriteShareCaseStudyParam.userId=sessionStorage.getItem('sessionuserId')??'';
          this.service.analyticsWriteCaseStudyShareSocialMedia(this.analyticsWriteShareCaseStudyParam)
          .subscribe(data=> {
          },error =>{ }); 
 
       }
       ,(error) => { }); 
     }
     ,(error) => {  
     });
   }

   getSource(pageName){           
        switch (pageName) {
          case 'MyIndustolutions':
            this.source['sourceId'] = CaseStudyViewSource.MyIndustolutions;
            this.source['sourceName'] = 'MyIndustolutions';
            break;
        case 'HomePage':             
            this.source['sourceId'] = CaseStudyViewSource.HomePage;
            this.source['sourceName'] = 'HomePage';  
            break;
        case 'VSC':             
            this.source['sourceId'] = CaseStudyViewSource.VSC;
            this.source['sourceName'] = 'VSC';  
            break;
        case 'PublicSolutionSet':             
            this.source['sourceId'] = CaseStudyViewSource.PublicSolutionSet;
            this.source['sourceName'] = 'PublicSolutionSet';  
            break;
        case 'Other':             
            this.source['sourceId'] = CaseStudyViewSource.Other;
            this.source['sourceName'] = 'Other';  
            break;
        case 'SearchPage':             
            this.source['sourceId'] = CaseStudyViewSource.SearchPage;
            this.source['sourceName'] = 'SearchPage';  
            break;
        }
        return  this.source;

   }


}