import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ApicallService } from '../service/apicall.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { addCsToMyIndustolutionsModel, analyticsWriteDownloadCaseStudyModel, analyticsWriteModel, CaseStudyViewSource, downloadCaseStudyModel, searchparams } from '../common/searchparams';
import { environment } from 'src/environments/environment';
import { PersonalSolutionModel } from '../models/solutionsetsmodel';
import { ToastrService } from 'ngx-toastr';
import { SeoService } from '../seo.service';
import { FuncsService } from '../service/funcs.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public searchParamModel = new searchparams();
  domainname: any;
  userIp: any;
  microSiteContent: any;
  whatweDoSubTitle:any;
  casestudyContent: any;
  statisticsCount: any;
  exploredata: any;
  counterpart: any;
  //case study page
  humanReadableId: any;
  titleNormalized: any;
  public isLoggedIn;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname: string;
  public cookieTimer;
  public formToken;
  public tokenCookieName = "downloadToken";
  public attempts = 60;
  public parts;
  public mainform;
  public form;
  public isVideo;
  //list of solution set
  solutionSetList: any;

  //for case study download 
  public caseStudyParam = new downloadCaseStudyModel();
  public analyticsWriteDownloadCaseStudyParam = new analyticsWriteDownloadCaseStudyModel();
  public personalSolutionData = new PersonalSolutionModel();
  public addCsToMyIndustolutionsParam = new addCsToMyIndustolutionsModel();
  public constructor(private titleService: Title, private service: ApicallService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private seo: SeoService, public funcs: FuncsService) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "autoplay": true,
    "dots": true,
    "infinite": true,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]

  };

  public logininfo: string;
  public linkedincode: any;
  public linkeduserid: any;

  ngOnInit(): void {
    this.domainname = this.service.getHostname();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.logininfo = params.get('data') ?? '';
    });
    // loggedin through linked in account  
    this.logininfo = decodeURIComponent(this.logininfo);
    if (this.logininfo != '') {
      var info = this.logininfo.split("//");
      
      sessionStorage.setItem('sessionuserId', info[0]);
      sessionStorage.setItem('userToken', info[1]);
      sessionStorage.setItem('sessionLoggedinViaLinkedin', 'loginvialinkedin');
      this.isLoggedIn = true;
     
      location.href=info[2].replace(/\|\|/g,"/");
    }
    
    this.searchParamModel.queryType = "all"

    if (sessionStorage.getItem('sessionuserId') != null)
      this.isLoggedIn = true;
    else
      this.isLoggedIn = false;


    //for getting case study content
    this.service.getCaseStudyData(this.domainname).subscribe(data => this.casestudyContent = data['data'], error => { });


    //for getting statistics count
    this.service.getstatisticsCount(this.domainname).subscribe(data => this.statisticsCount = data['statisticItems'], error => { });


    // api calls
    // for manage home page content
    this.service.getMicrositeContents("HomePage", this.domainname).subscribe(data => {
      this.microSiteContent = data;
      this.whatweDoSubTitle=this.domainname.split(".")[0];
      this.microSiteContent.forEach((currentValue, index)=> {
        if(currentValue.section=="WhatWeDoSubTitle" && currentValue.content!=''){
          this.whatweDoSubTitle=currentValue.content;
        }
      });
      
      
      if (data[4].section == "HeroSectionDescription") {
        sessionStorage.setItem('footercontent', data[4].content);
      }
    }, error => { });
    // dropdown list (personal solution set)
    if (this.isLoggedIn) {
      this.loadSolutionSets();
    }
    // for explore data (popup)   
    //for count  and list
    this.service.getExploreData(this.domainname)
      .subscribe(data => {
        this.counterpart = data['solutionSetsCount'];
        this.exploredata = data;
      }, error => { });



    if (this.isLoggedIn) {
      this.service.getUserIP().subscribe(data => {
        this.userIp = data['ip'];

        this.componentname = this.constructor.name.toLowerCase().replace("component", '');
        this.analyticsWriteParam.stateUrl = this.componentname;
        this.analyticsWriteParam.stateName = this.componentname;
        this.analyticsWriteParam.domain = this.domainname;
        this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp, this.analyticsWriteParam.stateUrl, this.analyticsWriteParam.stateName, this.analyticsWriteParam.domain)
          .subscribe(data => { }, error => { });

      });
    }

  }

  SearchCase(filterForm: NgForm) {
    this.searchParamModel.additionalQuery = '';
    this.searchParamModel.domain = this.domainname;
    this.searchParamModel.page = 1;
    this.searchParamModel.getSponsored = true;
    this.router.navigate(['/casestudysearch', { additionalQuery: this.searchParamModel.additionalQuery, queryString: this.searchParamModel.queryString, queryType: this.searchParamModel.queryType, getSponsored: this.searchParamModel.getSponsored, page: this.searchParamModel.page, domain: this.searchParamModel.domain }])

  }


  openAdvancePopup = false;
  toggleAdvancePopup() {
    this.openAdvancePopup = !this.openAdvancePopup;
  }

  gotoCaseStudy(casestudyId) {
    //api to get humanReadableId and titleNormalized   
    this.service.getCaseStudyUrlParts(casestudyId)
      .subscribe(data => {
        this.humanReadableId = data['humanReadableId'];
        this.titleNormalized = data['titleNormalized'];
        //checkExistence      
        this.service.checkExistence(this.humanReadableId)
          .subscribe(data => {
            this.router.navigate(['casestudy', this.titleNormalized + "_" + this.humanReadableId]); // on checkExistence success
          }
            , (error) => {
            });
      }
        , (error) => {
        });
  }

  showJoinContentreeModal = false;
  toggleJoinContentreeModal() {
    this.showJoinContentreeModal = !this.showJoinContentreeModal;
  }

  showSolutionList = false;
  hideSolutionSolutionList() {
    this.showSolutionList = false;
    this.showShareSocial=false;
  }
  toggleSolutionList() {

    //if session expired
    if (sessionStorage.getItem('sessionuserId') == "undefined")
      this.toggleJoinContentreeModal();
    else {

      if (this.isLoggedIn) {
        this.showSolutionList = !this.showSolutionList;

      }
      else {
        this.showSolutionList = false;
        this.toggleJoinContentreeModal();
      }
    }
  }
  candownloadFile(casestudyId, caseStudyTitle) {
    if (this.isLoggedIn) {

      this.service.canDownloadForMicrosite(sessionStorage.getItem('sessionuserId'), casestudyId)
        .subscribe(data => {
          let strStatus = data['body'];
          if (strStatus == true) {
            this.downloadCaseStudy(casestudyId, caseStudyTitle);
          }
          else {
            this.toastr.error("You've exceeded download limit for case studies", "", { timeOut: 3000, progressBar: true });

          }

        }
          , error => { this.router.navigate(['/login;redirectUri=' + location.pathname.substr(1)]); });
    }
    else {
      this.toggleJoinContentreeModal();
    }
  }
  downloadCaseStudy(casestudyId, caseStudyTitle) {

    //if session expired
    if (sessionStorage.getItem('sessionuserId') == "undefined")
      this.toggleJoinContentreeModal();
    else {
      if (this.isLoggedIn) {

        this.caseStudyParam.caseStudyId = casestudyId;
        this.caseStudyParam.caseStudyTitle = caseStudyTitle;
        this.caseStudyParam.userId = sessionStorage.getItem('sessionuserId') ?? "";
        this.caseStudyParam.domain = this.domainname;
        this.caseStudyParam.type = "0";
        this.caseStudyParam.downloadToken = "";
        this.promtToSaveFile(environment.apiURL + '/api/files/downloadforMicrosites');

        //analytics write for download case study
        this.analyticsWriteDownloadCaseStudyParam.id = casestudyId;
        this.analyticsWriteDownloadCaseStudyParam.source = CaseStudyViewSource.SearchPage;
        this.analyticsWriteDownloadCaseStudyParam.domain = this.domainname;
        this.analyticsWriteDownloadCaseStudyParam.userIP = this.userIp;
        this.analyticsWriteDownloadCaseStudyParam.userId = sessionStorage.getItem('sessionuserId') ?? '';
        this.service.analyticsWriteDownloadCaseStudy(this.analyticsWriteDownloadCaseStudyParam)
          .subscribe(data => {
          }, error => { this.router.navigate(['/login;redirectUri=' + location.pathname.substr(1)]); });

      }
      else {
        this.toggleJoinContentreeModal();
      }
    }
  }

  promtToSaveFile(url) {
    this.mainform = this.createForm(url);
    let formToken = (new Date()).getTime().toString();
    this.mainform.appendChild(this.createInput("accessToken", sessionStorage.getItem('userToken')));
    this.mainform.appendChild(this.createInput("url", url));
    this.mainform.appendChild(this.createInput("caseStudyId", this.caseStudyParam.caseStudyId));
    this.mainform.appendChild(this.createInput("downloadToken", ""));
    this.mainform.appendChild(this.createInput("type", this.caseStudyParam.type));
    this.mainform.appendChild(this.createInput("userId", this.caseStudyParam.userId));
    this.mainform.appendChild(this.createInput("domain", this.caseStudyParam.domain));
    this.mainform.appendChild(this.createInput("caseStudyTitle", this.caseStudyParam.caseStudyTitle));
    const nodenative = document.createElement('script');
    nodenative.src = 'assets/jquerynative.js';
    nodenative.type = 'text/javascript';
    nodenative.async = false;
    this.mainform.appendChild(nodenative);
    const node = document.createElement('script');
    node.src = 'assets/load.js';
    node.type = 'text/javascript';
    node.async = false;
    this.mainform.appendChild(node);
    setTimeout(() => {
      this.mainform.parentNode.removeChild(this.mainform);
    }, 1000);
    return false;
  }

  createForm(url) {
    this.form = document.createElement("form");
    var formname = this.caseStudyParam.caseStudyId;
    this.form.name = formname;
    this.form.id = formname;
    this.form.style.display = "none";
    this.form.action = url;
    this.form.method = "POST";
    document.body.appendChild(this.form);
    return this.form;
  }

  createInput(name, value) {
    var input = document.createElement("input");
    input.name = name;
    input.value = value;
    return input;
  }
  loadSolutionSets() {
    this.service.getSolutionSetList(sessionStorage.getItem('sessionuserId'))
      .subscribe(data => {
        this.solutionSetList = data['body'];
      }
        , (error) => {
        });
  }
  addCaseStudyToSolutionSet(caseStudyId, solutionSetId) {
    this.personalSolutionData.UserId = sessionStorage.getItem('sessionuserId') ?? "";
    this.personalSolutionData.Domain = this.domainname;
    this.personalSolutionData.CaseStudyId = caseStudyId;
    this.personalSolutionData.SolutionSetId = solutionSetId;
    this.service.addCaseStudyToSolutionSetForMicrosites(this.personalSolutionData)
      .subscribe(data => {
        this.toastr.success("Your case study has been saved to the Solution Set", "", { timeOut: 3000, progressBar: true });

        if (this.isLoggedIn) {
          this.addCsToMyIndustolutionsParam.id = caseStudyId;
          this.addCsToMyIndustolutionsParam.source = "3";
          this.addCsToMyIndustolutionsParam.domain = this.domainname;
          //analytics write for analyticsWriteCaseStudySearch
          this.service.addCsToMyIndustolutions(this.addCsToMyIndustolutionsParam)
            .subscribe(data => { }, error => { });
        }

      }
        , (error) => {
          if (error.error.length > 0 || error.error.message != "undefined") {
            this.toastr.error("Case Study has already been added in this Personal Solution Set.","Error",{timeOut:3000, progressBar:true});
      
          }
          else if (error.error.message== "undefined") {
            this.toastr.error(error.error.message, "Error", { timeOut: 3000, progressBar: true });
          }
          else {
            this.router.navigate(['/login']);
          }

        });
  }

  showCreatePersonalSolutionSetModal = false;
  openCreatePersonalSolutionSetModal(caseStudyId) {
    this.personalSolutionData.CaseStudyId = caseStudyId;
    this.showCreatePersonalSolutionSetModal = !this.showCreatePersonalSolutionSetModal;
  }

  closeCreatePersonalSolutionSetModal() {
    this.showCreatePersonalSolutionSetModal = !this.showCreatePersonalSolutionSetModal;

  }
  createWithCaseStudyForMicrosites() {
    let casestudyid;
    this.personalSolutionData.UserId = sessionStorage.getItem('sessionuserId') ?? "";
    this.personalSolutionData.Domain = this.domainname;
    this.service.createWithCaseStudyForMicrosites(this.personalSolutionData)
      .subscribe(data => {
        casestudyid = data['body'].id;
        this.showCreatePersonalSolutionSetModal = false;
        this.toastr.success("Your Solution has been saved to the new Solution Set", "", { timeOut: 3000, progressBar: true });
        this.loadSolutionSets();
        if (this.isLoggedIn) {
          this.addCsToMyIndustolutionsParam.id = casestudyid;
          this.addCsToMyIndustolutionsParam.source = "3";
          this.addCsToMyIndustolutionsParam.domain = this.domainname;
          this.addCsToMyIndustolutionsParam.userIP = this.userIp;
          this.addCsToMyIndustolutionsParam.userId = sessionStorage.getItem('sessionuserId') ?? '';
          //analytics write for analyticsWriteCaseStudySearch
          this.service.addCsToMyIndustolutions(this.addCsToMyIndustolutionsParam)
            .subscribe(data => { this.personalSolutionData.Name = ""; }, error => { });
        }
      }
        , (error) => {
          if (error.error.length > 0) {
            this.toastr.error(error.error[0], "Error", { timeOut: 3000, progressBar: true });
          }
          else if (error.error.message != "undefined") {
            this.toastr.error(error.error.message, "Error", { timeOut: 3000, progressBar: true });
          }
          else {
            this.router.navigate(['/login']);
          }
        });

  }

  //listing part
  showListingAddCaseStudy = false;
  listAddCaseStudy() {
    //if session expired
    if (sessionStorage.getItem('sessionuserId') == "undefined")
      this.toggleJoinContentreeModal();
    else {
      if (this.isLoggedIn) {
        this.showListingAddCaseStudy = !this.showListingAddCaseStudy;
      }
      else {
        this.showListingAddCaseStudy = false;
        this.toggleJoinContentreeModal();
      }
    }
  }

  public showShareSocial=false; 
  btnShareClick()
  {  
    this.showShareSocial = !this.showShareSocial;
  }
}
