<app-header></app-header>

<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10  w-full max-w-screen-2xl mx-auto">
        <section class="text-white body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <h1 class="text-white text-3xl font-semibold mb-4 uppercase">Contentreads</h1>
                <p class="w-full flex-wrap text-center text-md md:text-xl">The world's largest collection of B2B case
                    studies</p>
            </div>
        </section>
    </div>
</div>

<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto px-10 lg:px-3 relative -mt-16 pb-32">
        <div class="md:bg-white w-auto h-auto rounded md:shadow-lg p-0 md:p-10">
            <div class="flex flex-wrap -m-4">
                <!-- Blogs Listing Starts -->
                <div
                    *ngFor="let mcontent of blogList | paginate: { id:'listing_pagination', itemsPerPage : countPerPage, currentPage : page, totalItems : totalRecords}"
                     class="w-full sm:w-1/2 md:w-1/3 flex flex-col p-4">
                     <a href="/blog/post/{{mcontent.titleNormalized}}" class="group block"> 
                     <div class="bg-white rounded-lg shadow-lg overflow-hidden flex-1 flex flex-col">
                            <div>
                                <img src="{{mcontent.backgroundImageUrl}}" />
                            </div>
                            <div class="p-4 flex-1 flex flex-col">
                                <h3 class="mb-4 text-2xl">{{mcontent.title}}
                                </h3>
                                <div class="mb-4 text-sm flex-1 text_blog">
                                    <p innerHTML="{{(mcontent.content.length>250)? (mcontent.content | slice:0:250)+'..':(mcontent.content)}}"> </p>
                                </div>
                                <span class="border-t border-grey-light pt-2 text-xs text-grey uppercase no-underline read-more">Read
                                    more</span>
                            </div>
                        </div></a>  
                </div>
            </div>
            <div *ngIf="totalRecords>0" class="mt-10 mb-4 text-center">
                <pagination-controls id="listing_pagination" (pageChange)="page = $event"></pagination-controls>
            </div>            
            <div *ngIf="totalRecords==0" class="mt-10 mb-4 text-center">
                    <h2 class="text-2xl mb-10">No blog posts yet</h2>
            </div>
        </div>
    </div>
</div>


<app-pagefooter></app-pagefooter>