<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-24 relative setbg6 min-h-screen">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="flex flex-wrap z-10 max-w-screen-2xl mx-auto px-10 lg:px-3">
        <div class="w-full md:w-1/2 pr-3 lg:pr-20 flex flex-wrap content-center order-last md:order-first">
            <div class="md:flex items-start">
                <div class="w-20">
                    <img src="/assets/images/organized.svg" class="filter brightness-200 contrast-200" alt="" width="64"
                        height="64">
                </div>
                <div class="text-white md:pl-5 mt-3 md:mt-0">
                    <h3 class="uppercase font-bold text-xl">organized</h3>
                    <p class="mb-6 text-lg break-all">Easily save, organize and retrieve all the industry solutions you have
                        discovered on {{domainname}}.</p>
                </div>
            </div>
            <div class="md:flex items-start">
                <div class="w-20">
                    <img src="/assets/images/permanent-2.svg" class="filter brightness-200 contrast-200" alt=""
                        width="64" height="64">
                </div>
                <div class="text-white md:pl-5 mt-3 md:mt-0">
                    <h3 class="uppercase font-bold text-xl">permanent</h3>
                    <p class="mb-6 text-lg">myContentREADS is your free and permanent reference bank of solutions you
                        curate and will use throughout your career.</p>
                </div>
            </div>
            <div class="md:flex items-start">
                <div class="w-20">
                    <img src="/assets/images/dicovery-platform.svg" class="filter brightness-200 contrast-200" alt=""
                        width="64" height="64">
                </div>
                <div class="text-white md:pl-5 mt-3 md:mt-0">
                    <h3 class="uppercase font-bold text-xl">collaborative</h3>
                    <p class="mb-10 md:mb-0 text-lg break-all">Easily exchange industry solution ideas with other
                        {{domainname}} members. Share, collaborate and learn from other industry
                        experts.</p>
                </div>
            </div>
        </div>

        <div class="w-full md:w-1/2 divide-y divide-gray-200 text-white flex flex-wrap content-center mb-10 md:mb-0">
            <div
                class="text-base leading-6 text-gray-700 sm:text-md sm:leading-7 bg-gray-900 bg-opacity-50 p-4 lg:p-14 rounded w-full">
                <div class="text-center mb-10">
                    <button (click)="loginWithLinkedIn()"
                        class="bg-blue-600 hover:bg-blue-700 text-white font-semibold p-3 rounded  w-full justify-start">

                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                            class="fill-current w-5 h-5 mr-2 mt-1 absolute">
                            <path fill="currentColor"
                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                class=""></path>
                        </svg>
                        <span class="pl-2 uppercase text-sm md:text-xl">Log in with LinkedIn</span>
                    </button> </div>
                <form #loginForm="ngForm">
                    <div class="form-group mb-4">
                        <!-- <label for="UserNameOrEmail" class=" block ">Username or business email:</label> -->
                        <input type="text" #UserNameOrEmailRef="ngModel" required
                            class="border w-full h-12 px-3 py-5 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Your username or business email" name="UserNameOrEmail"
                            [(ngModel)]="userdata.UserNameOrEmail"
                            [ngClass]="{'red-border-class': UserNameOrEmailRef.errors && (UserNameOrEmailRef.dirty || UserNameOrEmailRef.touched)}">
                        <div
                            *ngIf="UserNameOrEmailRef.errors && (UserNameOrEmailRef.dirty || UserNameOrEmailRef.touched)">
                            <div [hidden]="!UserNameOrEmailRef.errors.required" class="text-red-500">
                                Please enter username or business email.
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <!-- <label for="Password" class=" block ">Password:</label> -->
                        <input type="password" #PasswordRef="ngModel" required
                            class="border w-full h-12 px-3 py-5 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Your Password" name="Password" [(ngModel)]="userdata.Password"
                            [ngClass]="{'red-border-class': PasswordRef.errors && (PasswordRef.dirty || PasswordRef.touched)}">
                        <div *ngIf="PasswordRef.errors && (PasswordRef.dirty || PasswordRef.touched)">
                            <div [hidden]="!PasswordRef.errors.required" class="text-red-500">
                                Please enter Password.
                            </div>
                        </div>
                    </div>
                    <div class="md:flex justify-between mb-4">
                        <label class="flex justify-start items-start mb-4 md:mb-0">
                            <div
                                class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-brand-green mt-1">
                                <input type="checkbox" class="opacity-0 absolute" #rememberMeRef="ngModel"
                                    name="rememberMe" [(ngModel)]="userdata.rememberMe" checked>
                                <svg class="fill-current hidden w-4 h-4 text-brand-green pointer-events-none"
                                    viewBox="0 0 20 20">
                                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" /></svg>
                            </div>
                            <div class="select-none text-brand-gray font-semibold text-lg">Keep me logged in</div>
                        </label>
                        <button type="button" (click)="toggleForgotPasswordModal()"
                            class='text-brand-gray font-semibold text-lg hover:underline pl-1 modal-open md:ml-10'>Forgot
                            password?</button>
                    </div>
                    <div *ngIf="!isvalid" role="alert"
                        class="border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        Invalid Username or Password
                    </div>
                    <div class="text-center mb-4">
                        <button type="submit" (click)="onLogin()" [disabled]="!loginForm.valid"
                            class="bg-brand-green rounded text-white p-3 block w-full"
                            [ngClass]="{'disabledbtn': !loginForm.valid }"><span
                                class="p-4 uppercase font-semibold text-xl">Log in</span></button>
                    </div>
                </form>
                <div class="">
                    <a (click)="setTitle('Register')"
                        class="inline-flex items-center hover:text-underline text-white font-semibold"
                        routerLink="/register" routerLinkActive="active"><span>Create Account </span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="filter invert ml-1" width="15" height="15"
                            viewBox="0 0 24 24">
                            <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" /></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- forgot password -->
<div *ngIf="showForgotPasswordModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-center w-full">
                <p class="text-gray-900 font-medium text-lg">Reset Password</p>
                <div class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    (click)="toggleForgotPasswordModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <!--Body-->
            <div class="my-5 pt-4">
                <form #forgotPassword="ngForm" class="w-full">
                    <div class="">
                        <div class="">
                            <label for="names" class="text-md text-gray-600">Email:</label>
                        </div>
                        <div class="">
                            <input type="text" #enteredEmailRef="ngModel" autocomplete="off" required
                                class="border w-full h-12 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                                placeholder="Your registered e-mail" name="enteredEmail" ngModel
                                [ngClass]="{'red-border-class': enteredEmailRef.errors && (enteredEmailRef.dirty || enteredEmailRef.touched)}">
                        </div>
                        <div *ngIf="enteredEmailRef.errors && (enteredEmailRef.dirty || enteredEmailRef.touched)">
                            <div [hidden]="!enteredEmailRef.errors.required" class="text-red-500">
                                Please enter registered email.
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end mt-5">
                        <button type="submit" (click)="onReset(forgotPassword)" [disabled]="!forgotPassword.valid"
                            class="bg-brand-green hover:bg-brand-gray text-white font-semibold py-2 px-4 rounded inline-flex items-center"
                            [ngClass]="{'disabledbtn': !forgotPassword.valid }">Reset</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showForgotPasswordModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<app-pagefooter></app-pagefooter>