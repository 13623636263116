export class CommentModel
  {  
    public message:string; 
    public userId:string;    
    public blogpostId :string;
  }
  export class BlogListModel
  {  
    public backgroundImageUrl:string; 
    public title:string;    
    public content :string;
    public titleNormalized:string;
  }

