// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,  
  title:'Local Environment Heading',
  apiURL: 'https://contentree-api.azurewebsites.net/',
  siteKey:'6LfUuDMcAAAAAHmUYZeOAOUZ7p51JWz45ceL6Bh8', //recaptcha key
  emailTo:"veronika.kotova@intentmacro.com;support@contentreads.com",
  linkedInClientId:"77qc0a23moy53q",
  linkedInOAuthUrl:"https://www.linkedin.com/oauth/v2/",
  TwitterApiConsumerKey :"JOvF27YqD22iP1nnCLJY54PeW",
  TwitterApiConsumerSecret:"ePZfn4jYH3BU1syGZ2QKGfcsBv3GJNLqZ3gxyuXtUbhfhPXthC",
  FaceBookApiKey : "294065321068136",
  helpemail:"support@contentreads.com" ,
  infoemail:"info@contentreads.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
