       
        <router-outlet>           
        </router-outlet>
        <div class="fixed bottom-6 right-6 h-12 w-12 text-center bg-gray-200 rounded-3xl">
            <button (click)="toggleSiteFeedbackModal()" class='text-black hover:underline modal-open'>
                <div class="w-8 h-8 mt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 14.25">
                        <title>Site feedback</title>
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path class="fill-none stroke-current stroke-1"
                                    d="M5.65,10.8v2.52L9.5,10.8h3.85A5.17,5.17,0,0,0,18.5,5.65h0A5.17,5.17,0,0,0,13.35.5H5.65A5.17,5.17,0,0,0,.5,5.65h0A5.17,5.17,0,0,0,5.65,10.8Z" />
                            </g>
                        </g>
                    </svg>
                </div>
            </button> 
        </div>
        
        <!-- Site feedback-->
        <div *ngIf="showSiteFeedbackModal"
        class="fixed text-gray-500 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-40 left-0 right-0 top-0 bottom-0"
        x-transition:enter="transition ease duration-300" x-transition:enter-start="opacity-0"
        x-transition:enter-end="opacity-100" x-transition:leave="transition ease duration-300"
        x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0">
        <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12  lg:w-4/12  mx-10"
            x-transition:enter="transition ease duration-100 transform"
            x-transition:enter-start="opacity-0 scale-90 translate-y-1"
            x-transition:enter-end="opacity-100 scale-100 translate-y-0"
            x-transition:leave="transition ease duration-100 transform"
            x-transition:leave-start="opacity-100 scale-100 translate-y-0"
            x-transition:leave-end="opacity-0 scale-90 translate-y-1">
            <div class="flex items-center w-full">
                <div class="text-gray-900 font-medium text-lg">Send us a feedback!</div>
                <svg (click)="toggleSiteFeedbackModal()"
                    class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18">
                    <path
                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                </svg>
            </div>
            <div class="py-4">

                <form #feedbackForm ="ngForm">
                    <div class="">
                        <div class="form-group">
                            <label for="category" class="text-md text-gray-600">What category would you like to
                                give web site feedback on?</label>                        
                        <div class="relative">
                            <select aria-selected="true"  #category="ngModel" required id="category" autocomplete="off" name="category"  [(ngModel)]= "feedback.Category" 
                            class="appearance-none border w-full px-4 py-2 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md" [ngClass]="{'red-border-class': category.errors && (category.dirty || category.touched)}">
                              <option value="" >Choose a category</option>
                          <option value="Site Navigation">Site Navigation</option>
                          <option value="Site Content">Site Content</option>
                          <option value="Site Design">Site Design</option>
                          <option value="Other">Other</option>
                          </select>
                          <div *ngIf="category.errors && (category.dirty || category.touched)">
                          <div [hidden]="!category.errors.required" class="text-red-500 -mt-3">
                              Please Select a category.
                           </div> 
                          </div>
                                <div
                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20">
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" >
                            <label for="rdoVSatisfied" class="text-md text-gray-600">Rate your level of satisfaction with
                                this web page today:</label>
                        
                            <div class="mt-1 mb-3">                                
                                <div class="mb-4">
                                    <input #rdoVSatisfied="ngModel" id="rdoVSatisfied" required type="radio" name="rdoVDissatisfied" class="hidden" value="Very Satisfied" [(ngModel)]= "feedback.Satisfaction" />
                                    <label for="rdoVSatisfied" class="flex items-center cursor-pointer"><span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>Very Satisfied</label>
                                </div>
                                <div class="mb-4">
                                    <input #rdoVSatisfied="ngModel" id="rdoSSatisfiedt" required type="radio" name="rdoVDissatisfied" class="hidden" value="Somewhat Satisfied" [(ngModel)]= "feedback.Satisfaction" />
                                    <label for="rdoSSatisfiedt" class="flex items-center cursor-pointer"><span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>Somewhat Satisfied</label>
                                </div>
                                <div class="mb-4">
                                    <input #rdoVSatisfied="ngModel" id="rdoSDissatisfied"  required type="radio" name="rdoVDissatisfied" class="hidden" value="Somewhat Dissatisfied" [(ngModel)]= "feedback.Satisfaction" />
                                    <label for="rdoSDissatisfied" class="flex items-center cursor-pointer"><span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>Somewhat Dissatisfied</label>
                                </div>
                                <div class="mb-4">
                                    <input  #rdoVSatisfied="ngModel" id="rdoVDissatisfied" required  type="radio" name="rdoVDissatisfied" class="hidden" value="Very Dissatisfied" [(ngModel)]= "feedback.Satisfaction" />
                                    <label for="rdoVDissatisfied" class="flex items-center cursor-pointer"><span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>Very Dissatisfied</label>
                                </div>
                                <div *ngIf="rdoVSatisfied.errors && (rdoVSatisfied.dirty || rdoVSatisfied.touched)">
                                    <div [hidden]="!rdoVSatisfied.errors.required" class="text-red-500 -mt-3">
                                        Please Select.
                                     </div> 
                                </div> 
                            </div>                            
                        </div>
                        <div class="form-group">                 
                            <div class="" *ngIf="!isLoggedIn"> 
                                <label for="emailFrom" class="text-md text-gray-600">From:</label>
                                <input type="email" #emailFrom="ngModel" required id="emailFrom" autocomplete="off" email="true" name="emailFrom"  [(ngModel)]= "feedback.EmailFrom" 
                                class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md" [ngClass]="{'red-border-class': emailFrom.errors && (emailFrom.dirty || emailFrom.touched)}"
                                placeholder="Enter your Email"> 
                                <div *ngIf="emailFrom.errors && (emailFrom.dirty || emailFrom.touched)">
                                    <div [hidden]="!emailFrom.errors.required" class="text-red-500 -mt-3">
                                        Please enter email.
                                     </div> 
                                     
                                </div>
                                </div>
                            </div>
                        <div  class="form-group">
                            <label for="rate" class="text-md text-gray-600">Rate your level of satisfaction with
                                this web page today:</label>                        
                                <textarea #comments="ngModel" required id="comments" autocomplete="off" name="comments"  [(ngModel)]= "feedback.Comments" 
                                class="border w-full h-45 p-3 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md resize" [ngClass]="{'red-border-class': comments.errors && (category.dirty || comments.touched)}" placeholder="Your comments..." ></textarea>
                                    <div *ngIf="comments.errors && (comments.dirty || comments.touched)">
                                            <div [hidden]="!comments.errors.required" class="text-red-500 -mt-3">
                                                Add your comments
                                            </div> 
                                </div>
                         </div> 
                       
                        </div>
                        <div class="flex justify-between mt-5">
                            <button (click)="toggleSiteFeedbackModal()"
                                class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center">Cancel</button>
                            <button class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                (click)="sendFeedBack(feedbackForm)" [disabled]="!feedbackForm.valid">Save</button>
                        </div>

                    
                </form>

            </div>
           
        </div>
    </div>
    <div *ngIf="showSiteFeedbackModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>        
