<app-header></app-header> 
<div class="bg-cover bg-center bg-brand-gray flex items-center w-full mx-auto py-24 relative setbg">
        <div class="absolute w-full h-full bg-blue-900 inset-0 bg-opacity-50"></div>
</div>

<div class="bg-gray-100">
    <div class="max-w-screen-2xl mx-auto relative -mt-16 pb-20">
        <div class="bg-white w-auto h-auto rounded-2xl shadow-lg p-4 md:p-10 pt-4">
            <div class="p-4 bg-white-200">Thank you for downloading the case study </div>

        </div>
    </div>
</div>


<app-pagefooter></app-pagefooter>
