import { Component, OnInit } from '@angular/core';
import { RequestFormModel } from '../models/gdprrequestform';
import { NgForm } from '@angular/forms';
import { ApicallService } from '../service/apicall.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ccpa-privacy-policy-complaint',
  templateUrl: './ccpa-privacy-policy-complaint.component.html',
  styleUrls: ['./ccpa-privacy-policy-complaint.component.scss']
})
export class CcpaPrivacyPolicyComplaintComponent implements OnInit {
  public isLoggedIn=false;
  public requestForm = new RequestFormModel();
  domainname:any;
  supportemail:string;
  firstname:string;
  lastname:string;

  constructor(private service:ApicallService, private toastr:ToastrService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;
      this.requestForm.Domain=this.service.getHostname();
      if(this.isLoggedIn){
        this.service.getAccountDetails(sessionStorage.getItem('sessionuserId'))
        .subscribe(data =>
        {
          this.requestForm.FirstName=data.firstName;
          this.requestForm.LastName=data.lastName;
          this.requestForm.Email=data.businessEmail??data.personalEmail;
          this.firstname=data.firstName;
          this.lastname=data.lastName;
        }
        ,error => {  
           
        });  
      }
  }
  sendRequestData(form: NgForm){
    this.service.SendPrivacyPolicyComplaint(this.requestForm)
    .subscribe(data =>
      {         
        this.toastr.success("Thanks for your Request!","",{timeOut:3000,progressBar:true}); 
        form.controls["message"].reset();
        form.controls["requesttype"].reset();
        form.controls["firstName"].reset();
        form.controls["lastName"].reset();
        
        this.requestForm.FirstName=this.firstname;
        this.requestForm.LastName=this.lastname;
        
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        });  
        
  }
  
  

}
