import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AccountsettingsComponent } from './accountsettings/accountsettings.component';
import { CasestudyComponent } from './casestudy/casestudy.component';
import { CasestudysearchComponent } from './casestudysearch/casestudysearch.component';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { JoinindustolutionsComponent } from './joinindustolutions/joinindustolutions.component';
import { LoginComponent } from './login/login.component';
import { MycontentreeComponent } from './mycontentree/mycontentree.component';
import { MysolutionsetComponent } from './mysolutionset/mysolutionset.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { RegisterComponent } from './register/register.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SolutionsetsComponent } from './solutionsets/solutionsets.component';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { DownloadfileComponent } from './downloadfile/downloadfile.component';
import { PrivacyComponent } from './privacy/privacy.component'; 
import { LegaltermsComponent } from './legalterms/legalterms.component';
import { SuggestcasestudyComponent } from './suggestcasestudy/suggestcasestudy.component';
import { GdprDataProtectionPolicyComponent } from './gdpr-data-protection-policy/gdpr-data-protection-policy.component'; 
import { BlogComponent } from './blog/blog.component'; 
import { BlogpostComponent } from './blogpost/blogpost.component'; 
import { CcpaPrivacyPolicyComplaintComponent } from './ccpa-privacy-policy-complaint/ccpa-privacy-policy-complaint.component'; 

const routes: Routes = [
  { path : '', redirectTo : '/home', pathMatch : 'full'}, // for default page 
  { path : 'about' , component: AboutComponent },
  { path : 'home', component : HomeComponent },
  { path : 'casestudysearch', component : CasestudysearchComponent},
  { path : 'solutionsets', component : SolutionsetsComponent },
  { path : 'joincontentreads', component : JoinindustolutionsComponent },
  { path : 'register', component : RegisterComponent },  
  { path : 'casestudy/:title', component : CasestudyComponent },  
  { path : 'verifyemail', component : VerifyemailComponent},
  { path : 'downloadfile', component : DownloadfileComponent},
  { path : 'privacy', component : PrivacyComponent},
  { path : 'legalterms', component : LegaltermsComponent},
  { path : 'resetpassword', component : ResetpasswordComponent},
  { path : 'login', component : LoginComponent }, 
  { path : 'accountsettings', component : AccountsettingsComponent }, 
  { path : 'mycontentree', component : MycontentreeComponent }, 
  { path : 'mysolutionset/:id', component : MysolutionsetComponent },
  { path : 'error/:errorcode', component : ErrorComponent },  
  { path : 'suggestcasestudy', component : SuggestcasestudyComponent},
  { path : 'gdpr-data-protection-policy', component : GdprDataProtectionPolicyComponent},
  { path : 'ccpa-privacy-policy-complaint', component : CcpaPrivacyPolicyComplaintComponent},
  { path : 'blog/post', component : BlogComponent},  
  { path : 'blog/post/:title', component : BlogpostComponent},
  { path: '**', component: PagenotfoundComponent }

];

@NgModule({  
  imports: [RouterModule.forRoot(routes,{initialNavigation: 'enabled',scrollPositionRestoration: 'enabled'})],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent,AboutComponent,CasestudysearchComponent, SolutionsetsComponent, JoinindustolutionsComponent,RegisterComponent,CasestudyComponent,LoginComponent,AccountsettingsComponent,MycontentreeComponent,MysolutionsetComponent,VerifyemailComponent,ErrorComponent,ResetpasswordComponent,PagenotfoundComponent,DownloadfileComponent,PrivacyComponent,LegaltermsComponent,SuggestcasestudyComponent,GdprDataProtectionPolicyComponent, CcpaPrivacyPolicyComplaintComponent]
