import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta) { }
  generateTags(config) {  
    if(config.seoDescription!="" && config.seoDescription!="undefined") {
      this.meta.updateTag({ name: 'description', content: config.seoDescription });  
    }  
    if(config.seoKeyword!="" && config.seoKeyword!="undefined") {
      this.meta.updateTag({ name: 'keywords', content: config.seoKeyword });  
    }
    if(config.url!="" && config.url!="undefined") {
      this.meta.updateTag({ property: 'og:url', content: config.url });
      this.meta.updateTag({ property: 'twitter:url', content: config.url });
    }
    
    if(config.title!="" && config.title!="undefined") {
      this.meta.updateTag({ property: 'og:title', content: config.title });
      this.meta.updateTag({ property: 'twitter:title', content: config.title });    
    }
    if(config.description!="" && config.description!="undefined") {
      this.meta.updateTag({ property: 'og:description', content: config.description });
      this.meta.updateTag({ property: 'twitter:description', content: config.description }); 
    } 
    if(config.image!="" && config.image!="undefined") { 
      this.meta.updateTag({ property: 'og:image', content: config.image });  
      this.meta.updateTag({ property: 'twitter:image', content: config.image });
    }
    this.meta.updateTag({ property: 'og:type', content: 'article' }); 
    this.meta.updateTag({ property: 'twitter:card', value: 'summary_large_image' });
    this.meta.updateTag({ property: 'twitter:site', value: '@contentreads' });
     
  }
}
