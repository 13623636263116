import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApicallService } from '../service/apicall.service';

@Component({
  selector: 'app-solutionsets',
  templateUrl: './solutionsets.component.html',
  styleUrls: ['./solutionsets.component.scss']
})
export class SolutionsetsComponent implements OnInit {

  constructor(private titleService: Title, private router:Router,private service:ApicallService) { }
  
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit(): void {   
  } 

}
