<app-header></app-header> 
<div class="w-1/3 mx-auto">
    <div class="space-y-4">
        <div class="text-3xl font-semibold">403</div>
        <div class="text-2xl">FORBIDDEN</div>
        <div>You have no access rights for this action.</div>
        <div>            
            <button class="cursor-pointer border-2 blueborder px-4 p-3 rounded-lg bluetext"  (click)="gotoHome()">Back to homepage</button>           
            <button class="cursor-pointer px-4 btnblue p-3 ml-3 rounded-lg text-white" onclick="history.back();">Previous page</button>                                
        </div>
    </div>
    <br/>
    <img src="../assets/images/error.svg">
</div>
<app-pagefooter></app-pagefooter>