import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { analyticsWriteModel } from '../common/searchparams';
import { SolutionModel } from '../models/solutionmodel';
import { ApicallService } from '../service/apicall.service';

@Component({
  selector: 'app-mycontentree',
  templateUrl: './mycontentree.component.html',
  styleUrls: ['./mycontentree.component.scss']
})
export class MycontentreeComponent implements OnInit {
  public solutionData = new SolutionModel();
  solutionsetList : any;
  downloadedCaseStudyList:any;
  domainname:any;
  userIp:any;
  public isLoggedIn;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname:string;
  public solutionsetsCount:number=0;
  public solutionsCount:number=0;;
   //case study page
 humanReadableId: any;
 titleNormalized: any;

  constructor(private titleService: Title,private service:ApicallService,private router:Router, private toastr:ToastrService) { }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  ngOnInit(): void {
    
    this.domainname = this.service.getHostname();
     
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
    this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);

    this.service.getSolutionDetails(sessionStorage.getItem('sessionuserId'))
    .subscribe(data =>
    { 
      this.solutionsetList=data;     
      this.solutionsetsCount=this.solutionsetList.length;
      for(let j=0;j<data.length;j++){  
        this.solutionsCount+= this.solutionsetList[j].solutionsCount;  
      }  

    },
    error => 
    { 
      this.router.navigate(['/login']) ;        
    }); 
    this.service.getDownloadedCaseStudies(sessionStorage.getItem('sessionuserId'),0)
    .subscribe(data =>
    { 
      this.downloadedCaseStudyList=data;  
    },
    error => { 
      this.router.navigate(['/login']) ;      
    }); 

    if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
    else
      this.isLoggedIn=false;

    if(this.isLoggedIn)
    {
      this.service.getUserIP().subscribe(data =>{      
        this.userIp = data['ip']; 
        this.componentname= this.constructor.name.toLowerCase().replace("component",''); 
        this.analyticsWriteParam.stateUrl=this.componentname;
        this.analyticsWriteParam.stateName=this.componentname;
        this.analyticsWriteParam.domain=this.domainname;
        this.service.analyticsWrite(sessionStorage.getItem('sessionuserId'), this.userIp, this.analyticsWriteParam.stateUrl,this.analyticsWriteParam.stateName,this.analyticsWriteParam.domain)
        .subscribe(data=> { },error =>{ });     
      });
        
    }
  }
  openTab = 1;
  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

  
  showCreateSolutionSetModal = false;
  toggleCreateSolutionSetModal(){    
    this.showCreateSolutionSetModal = !this.showCreateSolutionSetModal;
  }
  SaveSolutionSet()
  {
    this.solutionData.UserId= sessionStorage.getItem('sessionuserId')??"";
    this.solutionData.Domain=this.domainname;
    this.service.createSolutionSet(this.solutionData)
    .subscribe(data =>
      {  
        this.router.navigate(['mycontentree']).then(() => {window.location.reload();});       
      },
      error => { 
        if(error.error.length>0){
          this.toastr.error(error.error[0],"Error",{timeOut:3000, progressBar:true});
        }
        else if(error.error.message!="undefined"){
          this.toastr.error(error.error.message,"Error",{timeOut:3000, progressBar:true});
        }
        else{
          this.router.navigate(['/login']) ;
        }
        }); 
    
    this.showCreateSolutionSetModal=false;
  }
  
  gotoCaseStudy(casestudyId)
  {
    //api to get humanReadableId and titleNormalized   
      this.service.getCaseStudyUrlParts(casestudyId)    
      .subscribe(data => {
            this.humanReadableId = data['humanReadableId'];
            this.titleNormalized =  data['titleNormalized'];           
            //checkExistence      
            this.service.checkExistence(this.humanReadableId) 
            .subscribe(data => {            
              this.router.navigate(['casestudy',this.titleNormalized+"_"+this.humanReadableId]); // on checkExistence success
            }
            ,(error) => {            
            });
       
      }
      ,(error) => {  
      });  
  }
  showEditSolutionSetModal = false;
  toggleEditSolutionSetModal(solutionset)
  {
    this.showEditSolutionSetModal = !this.showEditSolutionSetModal;
    this.solutionData.Id= solutionset.id;
    this.solutionData.Name=solutionset.name;
    this.solutionData.Description=solutionset.description;
    this.solutionData.SolutionsCount=solutionset.solutionsCount;
    this.solutionData.SolutionsImages=solutionset.solutionsImages;
    this.solutionData.Url=solutionset.url;
  }

  closeEditSolutionSetModal()
  {
    this.showEditSolutionSetModal=false;
  }

  UpdateSolutionSet()
  { 
    //api call to update 
    this.solutionData.UserId= sessionStorage.getItem('sessionuserId')??"";
    this.solutionData.Domain=this.domainname;
    this.service.updateSolutionSet(this.solutionData)
    .subscribe(data =>
      {         
        this.router.navigate(['mycontentree']).then(() => {window.location.reload();});
      },
      error => {        
        this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]); 
        }); 
    
    this.showEditSolutionSetModal=false;
  }
  closeShowEditSolutionSetModal()
  {
    this.showEditSolutionSetModal = !this.showEditSolutionSetModal;
  }


  DeleteSolutionSetModal(setid)
  {
      //api call to delete 
    this.service.deleteSolutionset(setid)
    .subscribe(data =>
      {
        this.router.navigate(['mycontentree']).then(() => {window.location.reload();});
      },
      error => { 
        this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);       
        }); 
    
    this.showEditSolutionSetModal=false;

    this.showEditSolutionSetModal = !this.showEditSolutionSetModal;
  }

  //go to my solution set
  gotoMysolutionset(solutionid)
  {   
    this.router.navigate(['mysolutionset',solutionid]);
  }
}
