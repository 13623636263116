import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title }     from '@angular/platform-browser'; 
import { Router,ActivatedRoute,ParamMap } from '@angular/router';
import { ForgotPasswordModel, LoginModel } from '../models/usermodel';
import { ApicallService } from '../service/apicall.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SeoService } from 'src/app/seo.service';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isvalid: boolean = true; 
  public userdata = new LoginModel(); 
  public forgotPasswordParam  = new ForgotPasswordModel();
  domainname:any;
  public constructor(private titleService: Title,private router:Router,private service:ApicallService,private toastr:ToastrService,private route:ActivatedRoute,private seo:SeoService ) { }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  public redirectURL:string;
  public casestudyURL:string="";
  public finalredirectURL:string="";
  ngOnInit(): void {
    this.domainname = this.service.getHostname();
    this.finalredirectURL=this.domainname;
    sessionStorage.clear();
    this.route.paramMap.subscribe((params : ParamMap) =>{ 

      this.redirectURL=params.get('redirectUri')??'';

      this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL;
     
      if(this.redirectURL!='')
      { 

        if(this.redirectURL=="casestudysearch")
        {
          var casestudysearchurl=window.location.href;
          var splittedurl = casestudysearchurl.split("casestudysearch"); 
          this.casestudyURL=decodeURIComponent(splittedurl[splittedurl.length-1]);
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL+this.casestudyURL.replace(/;/g,"%3B");
          
          
        }
        else if(this.redirectURL.indexOf("blog||post")!=-1){
  
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL.replace(/\|\|/g,"/");
        
        }
        else if(this.redirectURL.indexOf("downloadfile||id||")!=-1){
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL.replace("||id||","?id=");
        }
        else if(this.redirectURL.indexOf("casestudy||")!=-1){
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL.replace("||","/");
          
        }
        else{
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL;
          
        }
      }
      else{
        this.finalredirectURL=this.service.getHostNameWithProtocol()+"/home";
      }

    });      
  }

  showForgotPasswordModal = false;
  toggleForgotPasswordModal(){
    this.showForgotPasswordModal = !this.showForgotPasswordModal;
  }
  onLogin()
  {   
    this.userdata.domain=this.domainname;  
    this.service.loginUser(this.userdata)
    .subscribe(
      data =>{   
        sessionStorage.setItem('sessionuserId', data['body'].userId); // on successful login set useid to session  
        sessionStorage.setItem('userToken', data['body'].userAccessToken);  
        this.toastr.success("logged in successfully","Success",{timeOut:3000, progressBar:true}); 
        setTimeout(() => {
          if(this.redirectURL!='')
          { 
            window.location.href=decodeURIComponent(this.finalredirectURL);  
            
          }
          else{
          this.titleService.setTitle('Home');       
          this.router.navigate(['/home']) ;
          }
        }, 5000); 
        
      }
      ,(error) => {   
        this.toastr.error("Login Failed. Invalid username or password","",{timeOut:3000,progressBar:true});       
      });           
  }  
  
  onReset(forgotPassword:NgForm)
  {  
    this.forgotPasswordParam.enteredEmail=encodeURIComponent(forgotPassword.value.enteredEmail);
    this.forgotPasswordParam.domain = this.domainname;
    this.service.forgotPassowrd(this.forgotPasswordParam)
    .subscribe(
      data =>{  ;
        this.showForgotPasswordModal = !this.showForgotPasswordModal;
        var responsemessage=data.body.responseText;
        
        if(data.body.success){
          responsemessage="Password reset link sent to your registered email address successfully";
          this.toastr.success(responsemessage,"",{timeOut:3000, progressBar:true});   
        }
        else{
          this.toastr.error(responsemessage,"",{timeOut:3000, progressBar:true});   
        }
      }
      ,(error) => {        
        this.toastr.error("Reset failed","",{timeOut:3000,progressBar:true}); 
      });  
  }
public newwindow;
  loginWithLinkedIn()
  {  
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=560,height=400,left=-1000,top=-1000`;  
    window.location.href = environment.linkedInOAuthUrl+"authorization?response_type=code&client_id="+environment.linkedInClientId+"&redirect_uri="+encodeURIComponent(environment.apiURL+"/api/linkedIn/SignInViaLinkedIn")+"&state="+this.finalredirectURL+"&scope=r_liteprofile,w_member_social,r_emailaddress";
  }
}
