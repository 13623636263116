import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApicallService } from 'src/app/service/apicall.service';
import { Router} from '@angular/router';
import { NeedHelpModel } from '../../models/needhelp';
import { LoginModel } from '../../models/usermodel';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pagefooter',
  templateUrl: './pagefooter.component.html',
  styleUrls: ['./pagefooter.component.scss']
})
export class PagefooterComponent implements OnInit {
  domainname:any;
  microSiteContent:any;
  contentMicrositesFooter:any;
  isLoggedIn:any;
  supportemail:string;
  infoemail:string;
  public userdata = new LoginModel();
  public needHelp = new NeedHelpModel();
  public needInfo = new NeedHelpModel();
  constructor(private titleService: Title, private service:ApicallService, private router:Router,private toastr:ToastrService) { }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  ngOnInit(): void {
    this.domainname = this.service.getHostname();
    this.supportemail=environment.helpemail;
    this.infoemail=environment.infoemail;
    // for getting site logo   
    if(sessionStorage.getItem('footercontent') !="" && sessionStorage.getItem('footercontent') !="undefined" && sessionStorage.getItem('footercontent')!=null)
    {
      this.microSiteContent=sessionStorage.getItem('footercontent')?.toString();
    }
    else{
      this.service.getMicrositeContentsForFooter("HomePage",this.domainname,true).subscribe(data=>
        {
          this.microSiteContent=data[0].content;
          sessionStorage.setItem('footercontent', this.microSiteContent); 
        }
        ,error => {});   
    }
    if(sessionStorage.getItem('sitelogo') !="" && sessionStorage.getItem('sitelogo') !="undefined" && sessionStorage.getItem('sitelogo') !=null)
    {
      this.contentMicrositesFooter=sessionStorage.getItem('sitelogo')?.toString();
    }
    else{
      this.service.getAllContentMicrosites(this.domainname)
      .subscribe(data=>{
        this.contentMicrositesFooter=data[0].siteLogo;
        sessionStorage.setItem('sitelogo',this.contentMicrositesFooter);      
      }); 
    }

    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.isLoggedIn=false;
    else
    {
      if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;
    }

  
  }
  
  showNeedHelpModal = false;
  showMoreInfoModal = false;   
  showJoinContentreeModal = false;

  toggleNeedHelpModal(){
    this.showNeedHelpModal = !this.showNeedHelpModal;
  }
  
  toggleMoreInfoModal(){
    this.showMoreInfoModal = !this.showMoreInfoModal;
  } 

  toggleJoinContentreeModal(){
    this.showJoinContentreeModal = !this.showJoinContentreeModal;
  }

  openCsSuggestion(){
    if(!this.isLoggedIn)
    {
      this.toggleJoinContentreeModal();
    }
    else{
    this.router.navigateByUrl('/suggestcasestudy');
    }
  }

  sendEmailForHelp(form: NgForm){   
    if(this.isLoggedIn){
      this.needHelp.userid=sessionStorage.getItem('sessionuserId')??'';
      this.service.getAccountDetails(sessionStorage.getItem('sessionuserId'))
    .subscribe(data =>
      {          
        this.needHelp.emailFrom = data['businessEmail'];  
      },
      error => {});
    }   
    this.needHelp.isHelpMessage = true;
    this.needHelp.sourcePath = this.service.getHostname();
    this.needHelp.emailTo=this.supportemail; 
    this.needHelp.subject="Need Help?";
    this.showNeedHelpModal = false;

    this.service.sendNeedHelpMail(this.needHelp)
    .subscribe(data =>
      {         
        this.toastr.success("Your message has been sent to Contentreads.","",{timeOut:3000,progressBar:true}); 
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        }); 
        form.resetForm();  
  }
  sendEmailForInfo(form: NgForm){ 
    if(this.isLoggedIn){
      this.needInfo.userid=sessionStorage.getItem('sessionuserId')??'';
      this.service.getAccountDetails(sessionStorage.getItem('sessionuserId'))
    .subscribe(data =>
      {          
        this.needInfo.emailFrom = data['businessEmail'];  
      },
      error => {});
    }   
    this.needInfo.isHelpMessage = true;
    this.needInfo.sourcePath = this.service.getHostname();
    this.needInfo.emailTo=this.infoemail; 
    this.needInfo.subject="Need Info?";
    this.showMoreInfoModal = false;

    this.service.sendNeedHelpMail(this.needInfo)
    .subscribe(data =>
      {         
        this.toastr.success("Your message has been sent to Contentreads.","",{timeOut:3000,progressBar:true}); 
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        }); 
        form.resetForm();  
  }

  
  
}
