import { Guid } from "guid-typescript";

export class UserModel {    
    public userId: Guid; 
    public username:string;   
    public password:string;   
    public email:string;
    public functionalAreaId?: string | null;
    public otherFunctionalArea?: string | null;
    public industryId?: string | null;
    public otherIndustry?: string | null;
    public IsAcrossSite :boolean;
    public ReturnUrl :string;
    public Country?: string | null;
    public Domain?: string | null;
    public resendEmail?:string | null;
    public IsFromMicrosite :boolean;    
  }

  export class LoginModel
  {
    public domain : string;  
    public UserNameOrEmail:string;   
    public Password:string; 
    public rememberMe:boolean; 
    constructor(){
      this.rememberMe = false;     
    }
  }
  export class PasswordResetModel
  {  
    public Password:string; 
    public ConfirmPassword:string; 
    public UserId:string;
  }

  export class AccountSettingModel {   
    public domain : string;       
    public businessEmail: string;
    public companyName: string | null;
    public country: string | null; 
    public firstName :string | null;
    public fullName: string | null;
    public functionalAreaId?: string | null;
    public functionalAreas : Array<FunctionalArea>;  
    public userid: string | null;  
    public industries: Array<Industry>; 
    public industryId?: string | null;
    public isDeleteRequested:boolean|false;
    public isTemporaryPassword :boolean|false;
    public jobTitle :string | null;
    public lastName: string | null;
    public otherFunctionalArea: string | null;
    public otherIndustry: string | null;
    public personalEmail: string | null;
    public userName :string;
  }

  export class FunctionalArea{
    public  text:string;
    public value :string;
  }

  export class Industry{
    public  text:string;
    public value :string;
  }

  export class ChangePasswordModel { 
    public businessEmail:  string | null;
    public companyName: string | null;
    public confirmPassword: string;
    public country:  string | null;
    public firstName : string | null;
    public fullName: string | null;
    public functionalAreaId?: string | null;
    public functionalAreas?: string | null;
    public userid: string | null;
    public industries?: string | null;
    public industryId?: string | null;
    public isDeleteRequested:boolean|false;
    public isTemporaryPassword :boolean|false;
    public jobTitle :string | null;
    public lastName: string | null;
    public newPassword:string;
    public oldPassword:string; 
    public otherFunctionalArea: string | null;
    public otherIndustry: string | null;
    public personalEmail: string | null;
    public userName :string;
    public domain : string;      
  }

  export class ShareInfoModel {   
    public customText : string;     
    public emailTo :string;
    public isHelpMessage:boolean;
    public sourcePath:string;
    public subject : string;    
    public userid:string;
  }

  export class ForgotPasswordModel
  {
    public enteredEmail : string;     
    public domain :string;
  }
