<app-header></app-header>

<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-28 relative setbg4">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="text-center z-10  w-full max-w-screen-2xl mx-auto">
        <section class="text-white body-font overflow-hidden">
            <div class="w-full px-1 py-5">
                <h1 class="text-white text-3xl font-semibold mb-4 uppercase">{{blogTitle}}</h1>
            </div>
        </section>
    </div>
</div>

<div class="bg-gray-100">
    <div class="max-w-screen-lg mx-auto px-10 lg:px-3 relative -mt-16">
        <div class="bg-white w-auto h-auto rounded shadow-lg p-10">
            <div *ngIf="blogPost">
                <div class="mb-4 w-full text-center">
                    <img src="{{blogPost.backgroundImageUrl}}"  class="rounded max-w-full shadow-lg" />
                </div>
                <h3 class="mb-4 text-2xl">{{blogPost.title}}</h3>
                <div class="mb-4 text_blog break-all">
                    <div [innerHTML]="blogPostContent"></div>   
                </div>
            </div>
            <hr/>
            <h3 class="my-4 text-2xl">Comments</h3>
            <div *ngIf="showRequestDeleteModal" class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
                <div class="my-5 pt-4">
                <form #deleteAccount="ngForm" class="w-full">
                    <div class="">
                        Are you sure you want to delete your comment
                    </div>
                    <div class="flex justify-between mt-5">
                        <button
                            class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                            (click)="toggleRequestDeleteModal()">Cancel</button>
                        <button
                            class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center mb-1"
                            (click)="confirmDelete()">Delete Comment</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
            <div *ngIf="blogComments">
                <div *ngFor="let comment of blogComments">
                    <div class="mb-4 w-full">
                        by <b>{{comment.userName}} </b> on {{comment.createdOn | date: 'MM/dd/yyyy h:mm:ss a'}}
                        <button *ngIf="isLoggedIn" class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded inline-flex items-center mb-1"
                            (click)="toggleRequestDeleteModal(comment.id)" title="Delete your comment">X</button>
                        <div class="py-2"> {{comment.message}} </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isLoggedIn">            
            <form #blogForm ="ngForm">
                <textarea #message="ngModel" required id="message" autocomplete="off" name="message"  [(ngModel)]= "blogComment.message" 
                class="border w-full h-45 p-3 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded-md resize" [ngClass]="{'red-border-class': message.errors && message.touched}" placeholder="Your comments..." ></textarea>
                    <div *ngIf="message.errors && (message.dirty || message.touched)">
                            <div [hidden]="!message.errors.required" class="text-red-500 -mt-3">
                                Add your comments
                            </div> 
                </div>
                <div class="form-group">
                <button type="button" (click)="postComments(blogForm)" class='bg-blue-600 hover:bg-blue-700 text-white font-semibold p-3 rounded justify-start'>Publish</button>
                </div>
            </form>
            </div>
            <div *ngIf="!isLoggedIn">
                <div class="py-2 text-brand-gray uppercase">Please login to add comments.</div>
            </div>
            <div class="my-4 border-t-2 border-brand-lightgray">
                    <ul class="flex justify-between">
                        <li class="cursor-pointer p-5 flex items-center hover:bg-gray-100" *ngIf="previousRecId"> 
                                <svg class="w-4 h-4 fill-current mt-1" viewBox="0 0 20 20">
                                    <path
                                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                        clip-rule="evenodd" fill-rule="evenodd"></path>
                                </svg> <a class="text-grey uppercase no-underline" href="/blog/post/{{previousRecId}}">Previous</a>
                        </li>                        
                        <li class="cursor-pointer p-5 flex items-center hover:bg-gray-100" *ngIf="nextRecId"> 
                            <a class="text-grey uppercase no-underline" href="/blog/post/{{nextRecId}}">Next</a> <svg class="w-4 h-4 fill-current mt-1" viewBox="0 0 20 20">
                                    <path
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd" fill-rule="evenodd"></path>
                                </svg>
                        </li>
                    </ul>
                </div>
        </div>
    </div>
</div>


<app-pagefooter></app-pagefooter>