import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallService } from '../service/apicall.service';
import { NgForm } from '@angular/forms';
import { CommentModel } from '../models/blogmodel';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../shared/header/header.component';
import md from 'markdown-it';
import {decode} from 'html-entities';

@Component({
  providers: [HeaderComponent],
  selector: 'app-blogpost',
  templateUrl: './blogpost.component.html',
  styleUrls: ['./blogpost.component.scss']
})
export class BlogpostComponent implements OnInit {
  public param;
  public blogPost;
  public blogs;
  public previousRecId;
  public nextRecId;
  public blogTitle;
  public blogComments;  
  public blogId;
  public commentId;
  domainName: any;
  blogPostContent: any;
  isLoggedIn:any;
  private markdown;
 
  public blogComment = new CommentModel();
  constructor(private route: ActivatedRoute, private service: ApicallService, private router:Router,private toastr:ToastrService, private header:HeaderComponent ) { }

  ngOnInit(): void {
    this.markdown=md();
   
    this.route.paramMap.subscribe(data => {
      this.param = data.get('title');      
      this.domainName = this.service.getHostname();
    });
    
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.isLoggedIn=false;
      
    else
    {
      if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;
    }

    this.service.getBlogPost(this.param,this.domainName)
      .subscribe(data => { 
        this.blogPost = data[0];
        this.blogPostContent=decode(this.markdown.render(this.replace_span_to_u(this.blogPost.content)));
        this.blogTitle=data[0].title;          
        this.blogId = data[0].id;
        this.listblogComments();
      }
        , error => { });        
        
  }
  listblogComments(){
    this.service.getBlogComments(this.blogId)
        .subscribe(data => {
          this.blogComments = data;                          
        }  , error => { });
  }
  postComments(form: NgForm){
    if(this.isLoggedIn){
      this.blogComment.userId=sessionStorage.getItem('sessionuserId')??'';
      this.blogComment.blogpostId=this.blogId;
    }     
      this.service.sendBlogComments(this.blogComment, this.blogId)
      .subscribe(data =>
        {         
          this.toastr.success("Your comments added","",{timeOut:3000,progressBar:true}); 
          this.listblogComments();  
        },
        error => {     
          this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
          }); 
          form.resetForm();
      }

      showRequestDeleteModal = false;
      toggleRequestDeleteModal(commentId=''){
        this.commentId = commentId;
        this.showRequestDeleteModal = !this.showRequestDeleteModal;
      }  

      confirmDelete(){  
        this.service.confirmDeleteComment(this.commentId,sessionStorage.getItem('sessionuserId')??null)
        .subscribe(data =>
          { 
            this.showRequestDeleteModal = false;
            this.toastr.warning("Comment Deleted","",{timeOut:3000,
            progressBar:true});
            this.listblogComments();
          },
          error => {   
            this.showRequestDeleteModal = false;   
            this.toastr.error("You dont have permission to delete this comment","",{timeOut:3000,progressBar:true});              
          });       

      }
      replace_span_to_u(input) {
        return input.replace(/\<span style\=\"text-decoration\:underline\"\>(.*?)\<\/span\>/g,             function(matched, index) {
                return '<u>' + index + '</u>';
              })
        }
      
      
}
