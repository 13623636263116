import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { ApicallService } from '../service/apicall.service';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.scss']
})
export class VerifyemailComponent implements OnInit {
  public token:any;
  public returnUrl:any;
  public domain:any;
  public result;
  constructor(private titleService: Title,private router:Router,private route:ActivatedRoute,private service:ApicallService) { }

  ngOnInit(): void {
   
    this.route.queryParams.subscribe(params => {      
      this.token=params.t;
      this.returnUrl=params.returnUrl;
      this.domain=params.domain;
    });
   
    this.service.verifyEmail(this.token,this.domain)    
    .subscribe(data=> {    
      if(data==null)    
        {
          this.titleService.setTitle('Login');       
          this.router.navigate(['/login']) ;
        }
        else{
          this.result=data;
        }
      }
      ,error =>{
      });  
  }

}
