import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfound',
  template: `<app-header></app-header>
    <div class='bg-gray-600 flex flex-col h-screen justify-center items-center pt-48'>
    <img src="../assets/images/error.svg" alt="404"/>
    <h2 class='m-auto text-white md:text-7xl xl:text-9xl font-bold'>Page Not Found</h2>
    </div>
  `,
  styles: [
  ]
})
export class PagenotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
