import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpParams,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { WINDOW } from '../window.providers';
import { IMicrositeContent } from '../common/micrositecontent';
import { ICaseStudyContent } from '../common/casestudycontent';
import { IStatisticsCountData } from '../common/statisticscountdata';
import { IAllContentMicrositesdata } from '../common/allcontentmicrositesdata';
import { IExploreDataList } from '../common/exploredatalist';
import { searchparams } from '../common/searchparams';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
}) 
export class ApicallService {
  constructor(private http:HttpClient,@Inject(WINDOW) private window: Window) { }
  //get host name
  getHostname() : string
  {
   return this.window.location.hostname;  
  }

  getHostNameWithProtocol() : string
  {
    return this.window.location.protocol+"//"+this.window.location.hostname;  
  }

  //for getting statistics count
  getstatisticsCount(domain:string) : Observable<IStatisticsCountData[]> 
  {     
    let apiParam= new HttpParams().set('domain',domain);  
    return this.http.get<IStatisticsCountData[]>(environment.apiURL+'/api/statistics/getHome',{params:apiParam});              
  }
 
  // for explore data (popup)
  getExploreData(domain:string) : Observable<IExploreDataList[]> 
  {     
    let apiParam= new HttpParams().set('domain',domain);  
    return this.http.get<IExploreDataList[]>(environment.apiURL+'/api/search/getExploreData',{params:apiParam});                     
  } 

  // for manage home page sections
  getMicrositeContents(pagename:string, domainname:string) : Observable<IMicrositeContent[]> 
  { 
    let apiParam= new HttpParams().set('pageName',pagename).set('domain',domainname);  
    return this.http.get<IMicrositeContent[]>(environment.apiURL+'/api/ManageMicrositeContents/GetMicrositeContents',{params:apiParam});     
  }

  // for manage footer page sections
  getMicrositeContentsForFooter(pagename:string, domainname:string,IsForFooter:boolean) : Observable<IMicrositeContent[]> 
  { 
    let apiParam= new HttpParams().set('pageName',pagename).set('domain',domainname).set('IsForFooter',IsForFooter.toString());  
    return this.http.get<IMicrositeContent[]>(environment.apiURL+'/api/ManageMicrositeContents/GetMicrositeContents',{params:apiParam});     
  }
  // for case study content
  getCaseStudyData(domainname:string) : Observable<ICaseStudyContent[]> 
  {   
    let apiParam= new HttpParams().set('domain',domainname);  
    return this.http.get<ICaseStudyContent[]>(environment.apiURL+'/api/ManageMicrositeContents/GetFeatured',{params:apiParam});   
  }
  // for site logo
  getAllContentMicrosites(domainname:string) : Observable<IAllContentMicrositesdata[]> 
  {  
    let apiParam= new HttpParams().set('domain',domainname);  
    return this.http.get<IAllContentMicrositesdata[]>(environment.apiURL+'/api/ManageMicrositeContents/GetAllContentMicrosites',{params:apiParam});      
  } 

  //case stusy search
  getcaseStudySearchForUser(paramlist:searchparams): Observable<any>{     
    let apiParam= new HttpParams().set('additionalQuery',paramlist.additionalQuery).set('queryString',paramlist.queryString).set('queryType',paramlist.queryType).set('getSponsored',paramlist.getSponsored.toString()).set('page',paramlist.page.toString()).set('domain',paramlist.domain.toString());
    return this.http.get(environment.apiURL+"/api/caseStudy/SearchForUser",{params:apiParam});   
  }

  // industry list
  getIndustries():Observable<any>
  {
    return this.http.get(environment.apiURL+"/api/Account/GetIndustries");  
  }

  // api to get functional area
  getFunctionalAreas():Observable<any>
  {
    return this.http.get(environment.apiURL+"/api/Account/GetFunctionalAreas"); 
  }

  // register user 
  registerUser(userdata):Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(userdata);    
    return this.http.post(environment.apiURL+"/api/Account/Register", body,{'headers':headers, observe: 'response'});
  }

  reRegisterUser(userdata):Observable<any>
  {   
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(userdata);
    return this.http.post(environment.apiURL+"/api/Account/resendConfirmation", body,{'headers':headers, observe: 'response'}); 
  }

  // for login 
  loginUser(userdata) :Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(userdata);   
    return this.http.post(environment.apiURL+"/api/AccountMicrosite/LoginForMicrosites", body,{'headers':headers, observe: 'response'}); 
  } 

  //forgot password 
  forgotPassowrd(forgotPasswordParam) :Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(forgotPasswordParam);   
    return this.http.post(environment.apiURL+"/api/Account/SendEmailForPasswordReset?enteredEmail="+forgotPasswordParam.enteredEmail+"&domain="+forgotPasswordParam.domain, body,{'headers':headers, observe: 'response'}); 
  } 

  //case study page
  getCaseStudyUrlParts(casestudyid):Observable<any>
  {   
    let apiParam= new HttpParams().set('id',casestudyid);    
    return this.http.get(environment.apiURL+"/api/caseStudy/GetCaseStudyUrlParts",{params:apiParam});   
  }

  checkExistence(humanreadableId):Observable<any>
  {
    let apiParam= new HttpParams().set('readableId',humanreadableId);    
    return this.http.get(environment.apiURL+"/api/caseStudy/checkExistence",{params:apiParam});
  }

   getCaseStudyView(humanreadableId,domain,userid):Observable<any>
  {
    let apiParam= new HttpParams().set('id',humanreadableId).set('domain',domain).set('userid',userid);    
    return this.http.get(environment.apiURL+"/api/caseStudy/GetCaseStudyViewForMicrosites",{params:apiParam});
  }
  canDownloadForMicrosite(userId,casestudyid):Observable<any>
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    let apiParam= new HttpParams().set('userId',userId).set('csId',casestudyid);            
    return this.http.get(environment.apiURL+"/api/files/CanDownloadForMicrosites",{'headers':headers, observe: 'response',params:apiParam})
  } 
	canDownloadAnonymousForMicrosite(casestudyid,token,domain):Observable<any>
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    let apiParam= new HttpParams().set('token',token).set('csId',casestudyid).set('domain',domain);            
    return this.http.get(environment.apiURL+"/api/files/CanDownloadForMicrosites",{'headers':headers, observe: 'response',params:apiParam})
  }
  //get Account Details
  getAccountDetails(userid):Observable<any>
  { 
    const headers = {'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    let apiParam= new HttpParams().set('userid',userid);         
    return this.http.get(environment.apiURL+"/api/Account/GetUpdateModelForMicrosite",{'headers':headers,params:apiParam});
  }  

  //update account details  ask abt api name and about request delete link
  updateAccountDetails(accountdetailsData):Observable<any> 
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    const body=JSON.stringify(accountdetailsData);    
    return this.http.put(environment.apiURL+"/api/Account/Put", body,{'headers':headers, observe: 'response'});      
  }

  //change password 
  changePassword(changepasswordData):Observable<any> 
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    const body=JSON.stringify(changepasswordData);    
    return this.http.post(environment.apiURL+"/api/Account/UpdatePassword", body,{'headers':headers, observe: 'response'});  
  }

  //add solution set
  createSolutionSet(solutiondata):Observable<any>
  {
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    const body=JSON.stringify(solutiondata);    
    return this.http.post(environment.apiURL+"/api/solutionSets/Create/", body,{'headers':headers, observe: 'response'});   
  }

  //get solution set  Details (full list)
  getSolutionDetails(userid):Observable<any>
  { 
    let apiParam= new HttpParams().set('userid',userid);         
    return this.http.get(environment.apiURL+"/api/solutionSets/AllForMicrosites",{params:apiParam});
  } 
  
  //GetDownloadedCaseStudies  
  getDownloadedCaseStudies(userid,skipid):Observable<any>
  {
    const headers = {'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;         
    let apiParam= new HttpParams().set('userid',userid).set('skip ',skipid);       
    return this.http.get(environment.apiURL+"/api/solutions/GetDownloadedCaseStudiesForMicrosite",{'headers':headers,params:apiParam});
  }
  
  //updae solution set
  updateSolutionSet(solutiondata):Observable<any>
  {   
    const headers = { 'content-type': 'application/json'} ;    
    const body=JSON.stringify(solutiondata);    
    return this.http.post(environment.apiURL+"/api/solutionSets/Update/", body,{'headers':headers, observe: 'response'});   
  }

  //solutionSets/Delete
  deleteSolutionset(id):Observable<any>
  {
   let apiParam= new HttpParams().set('id',id);         
   return this.http.delete(environment.apiURL+"/api/solutionSets/Delete",{params:apiParam});
  }

  //my solution set listing
  getAllSimpleForMicrosites(userid):Observable<any>
  { 
    let apiParam= new HttpParams().set('userid',userid);         
    return this.http.get(environment.apiURL+"/api/SolutionSets/AllSimpleForMicrosites",{params:apiParam});
  } 

  getSolutionsForMicrosites(id,userid):Observable<any>
  {  
    let apiParam= new HttpParams().set('id',id).set('userid',userid);         
    return this.http.get(environment.apiURL+"/api/SolutionSets/SolutionsForMicrosites",{params:apiParam});
  } 

  //delete account
  confirmDeleteRequest(userid,domain):Observable<any>
  {
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    let apiParam= new HttpParams().set('id',userid).set('domain',domain);          
   return this.http.get(environment.apiURL+"/api/AccountMicrosite/DeleteRequestForMicrosites",{'headers':headers, observe: 'response',params:apiParam})
  } 

  cancelDeleteAccountRequest(userid,domain):Observable<any>
  {
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    let apiParam= new HttpParams().set('id',userid).set('domain',domain);              
   return this.http.get(environment.apiURL+"/api/AccountMicrosite/CancelDeleteRequestForMicrosites",{'headers':headers, observe: 'response',params:apiParam})
  } 

  //my solution set download option
  downloadgetSolutionsForMicrosites(caseStudyId,downloadToken,type):Observable<any>
  {  
    let apiParam= new HttpParams().set('caseStudyId',caseStudyId).set('downloadToken',downloadToken).set('type',type);         
    return this.http.post(environment.apiURL+"/api/files/download",{params:apiParam});
  } 

  //share opinion
  sendRequestHelpInfoByEmail(infoData):Observable<any>
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')};        
    const body=JSON.stringify(infoData);    
    return this.http.post(environment.apiURL+"/api/home/RequestHelpInfoByEmailForMicrosites", body,{'headers':headers, observe: 'response'});   
  }

  //share via email
  shareCaseStudyViaEmail(mysolution):Observable<any>
  {     
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')};             
    const body=JSON.stringify(mysolution);    
    return this.http.post(environment.apiURL+"/api/CaseStudyActions/ShareCaseStudyViaEmailForMicrosites", body,{'headers':headers, observe: 'response'});   
  }

  //update my solution set
  updateMySolutionSet(solutiondata):Observable<any>
  { 
    const headers = {'content-type': 'application/json'} ;    
    const body=JSON.stringify(solutiondata);    
    return this.http.put(environment.apiURL+"/api/solutions/Put/", body,{'headers':headers, observe: 'response'});
  }

  //delete my solution set
  deleteMySolutionSet(id):Observable<any>
  { 
   let apiParam= new HttpParams().set('id',id);         
   return this.http.delete(environment.apiURL+"/api/solutions/Delete",{params:apiParam});
  } 
 
   //share via twitter (for not logged in)
  shareOnTwitterForMicrosites(shareViaTwitterParam):Observable<any>
  { 
    return this.http.get(environment.apiURL+"/api/twitter/ShareOnTwitterForMicrosites?id="+shareViaTwitterParam.id+"&title="+shareViaTwitterParam.title+"&domain="+shareViaTwitterParam.domain);
  }

  //Share SS Via LinkedIn
  ShareCSViaLinkedIn(shareBody):Observable<any>
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;    
    return this.http.post(environment.apiURL+"/api/linkedIn/ShareCSViaLinkedInForMicrosites",shareBody,{'headers':headers, observe: 'response'});   
  }

  //download option from case study search page
  downloadCaseStudy(caseStudyParam):Observable<any>
  {     
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    const body=JSON.stringify(caseStudyParam);      
    return this.http.post(environment.apiURL+"/api/files/downloadformicrosites", body,{'headers':headers, observe: 'response'}); 
  }

  //  case study search page - dropdown listing
  getSolutionSetList(userId):Observable<any>
  { 
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    let apiParam= new HttpParams().set('userId',userId);      
    return this.http.get(environment.apiURL+"/api/SolutionSets/GetUserSolutionSetsListForMicrosite",{'headers':headers, observe: 'response',params:apiParam})
  }  

  addCaseStudyToSolutionSetForMicrosites(personalSolutionData):Observable<any>
  {    
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')};                
    const body=JSON.stringify(personalSolutionData);    
    return this.http.post(environment.apiURL+"/api/SolutionSets/AddCaseStudyToSolutionSetForMicrosites", body,{'headers':headers, observe: 'response'});   
  }

  createWithCaseStudyForMicrosites(personalSolutionData):Observable<any>
  {    
    const headers = {'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')};               
    const body=JSON.stringify(personalSolutionData);    
    return this.http.post(environment.apiURL+"/api/SolutionSets/CreateWithCaseStudyForMicrosites", body,{'headers':headers, observe: 'response'});   
  }

  //analyticsWrite
  analyticsWrite(userId, userIp,stateUrl,stateName,domain)
  {  
    const headers = { 'content-type': 'application/json'} ;
    return this.http.post(environment.apiURL+"/api/analyticsWriteMicrosite/RegisteredUserMove?stateUrl="+encodeURIComponent(stateUrl)+"&userId="+encodeURIComponent(userId)+"&userIp="+encodeURIComponent(userIp)+"&stateName="+encodeURIComponent(stateName)+"&stateDomain="+encodeURIComponent(domain),{});
  }

  //analyticsWrite for case study search
  analyticsWriteCaseStudySearch(analyticsWriteCaseStudyParam)
  { 
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(analyticsWriteCaseStudyParam);  
    return this.http.post(environment.apiURL+"/api/analyticsWriteMicrosite/CaseStudySearch?additionalQuery", body,{'headers':headers, observe: 'response'}); 
  }
   //analyticsWrite for case study search - add new case study and case study to solution set
   addCsToMyIndustolutions(analyticsWriteCaseStudyParam)
   { 
     const headers = { 'content-type': 'application/json'} ;
     const body=JSON.stringify(analyticsWriteCaseStudyParam);  
     return this.http.post(environment.apiURL+"/api/analyticsWriteMicrosite/AddCsToMyIndustolutions?additionalQuery", body,{'headers':headers, observe: 'response'}); 
   }

  //analyticsWrite for share case study
  analyticsWriteCaseStudyShareSocialMedia(analyticsWriteShareCaseStudyParam)
  {  
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(analyticsWriteShareCaseStudyParam);     
    return this.http.post(environment.apiURL+"/api/analyticsWriteMicrosite/CaseStudyShareSocialMedia", body,{'headers':headers, observe: 'response'}); 
  }

  //analyticsWrite for Case Study Email Share
  analyticsWriteCaseStudyEmailShare(analyticsWriteShareCaseStudyParam)
  {    
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(analyticsWriteShareCaseStudyParam);     
    return this.http.post(environment.apiURL+"/api/analyticsWriteMicrosite/CaseStudyEmailShare", body,{'headers':headers, observe: 'response'}); 
  }
  
  //analyticsWrite for download case study
  analyticsWriteDownloadCaseStudy(analyticsWriteDownloadCaseStudyParam)
  { 
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
    const body=JSON.stringify(analyticsWriteDownloadCaseStudyParam);     
    return this.http.post(environment.apiURL+"/api/analyticsWriteMicrosite/DownloadCaseStudy", body,{'headers':headers, observe: 'response'});  
  }

  verifyEmail(token,domain):Observable<any>
  {    
    return this.http.get(environment.apiURL+"/api/AccountMicrosite/VerifyEmail?t="+token+"&domain="+domain);  
  }

  getdataforresetPassword(token,email,domain):Observable<any>
  { 
    return this.http.get(environment.apiURL+"/api/Account/ResetPasswordForMicrosites?email="+email+"&token="+token+"&domain="+domain);  
  }  
  resetPassword(userdata):Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;
    const body=JSON.stringify(userdata);    
    return this.http.post(environment.apiURL+"/api/Account/ResetPassword", body,{'headers':headers, observe: 'response'});
 }  
  getUserIP() :Observable<any>
  {
    return this.http.get("https://api.ipify.org/?format=json");  
  } 
  getAboutusContent(sitename) :Observable<any>
  {
    return this.http.get("includes/aboutuscontent/"+sitename+".html",{responseType: 'text'});  
  } 

  // register user 
  async suggestCaseStudy(filedata,domain)
  { 
    const formdata=new FormData();
    formdata.append("file",filedata,filedata.name);
    formdata.append("domain",domain);
    formdata.append("userid",sessionStorage.getItem('sessionuserId')??'')
    return await this.http.post(environment.apiURL+"/api/caseStudyRequest/CreateCaseStudyRequest",formdata).toPromise();
  }


  sendNeedHelpMail(data):Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;    
    const body=JSON.stringify(data);    
    return this.http.post(environment.apiURL+"/api/home/RequestHelpInfoByEmailForMicrosites", body,{'headers':headers, observe: 'response'});
  }

  sendFeedBack(data):Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;    
    const body=JSON.stringify(data);    
    return this.http.post(environment.apiURL+"/api/home/FeedbackForMicrosites", body,{'headers':headers, observe: 'response'});
  }
  sendPersonalDataRequest(data):Observable<any>
  { 
    const headers = { 'content-type': 'application/json','Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;    
    const body=JSON.stringify(data);    
    return this.http.post(environment.apiURL+"/api/AccountMicrosite/SendPersonalDataRequest", body,{'headers':headers, observe: 'response'});
  }

  SendPrivacyPolicyComplaint(data):Observable<any>
  { 
    const headers = { 'content-type': 'application/json','Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;    
    const body=JSON.stringify(data);    
    return this.http.post(environment.apiURL+"/api/AccountMicrosite/SendPrivacyPolicyComplaint", body,{'headers':headers, observe: 'response'});
  }

  getBlogs(domain):Observable<any>
  {    
    return this.http.get(environment.apiURL+"/api/micrositesblog/posts?$skip=0&$orderby=CreatedOn desc&domain="+domain); 
  }

  getBlogPost(title,domain):Observable<any>
  {        
    return this.http.get(environment.apiURL+"/api/micrositesblog/posts?$filter=TitleNormalized eq '"+title +"'&domain="+domain);  
  }  

  getBlogComments(blogId){    
    return this.http.get(environment.apiURL+"/api/micrositesblog/posts/"+blogId +"/comments");  
  }

  sendBlogComments(data, blogId):Observable<any>
  { 
    const headers = { 'content-type': 'application/json'} ;    
    const body=JSON.stringify(data);    
    return this.http.post(environment.apiURL+"/api/micrositesblog/posts/"+blogId +"/comments", body,{'headers':headers, observe: 'response'});
  }
  confirmDeleteComment(commentId,userId):Observable<any>
  { 
    const headers = {'Authorization': 'Bearer '+sessionStorage.getItem('userToken')} ;
         
    return this.http.delete(environment.apiURL+"/api/micrositesblog/comments/"+commentId+"/"+userId,{'headers':headers});
  }  

}