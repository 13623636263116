import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title }     from '@angular/platform-browser'; 
import { Router,ActivatedRoute } from '@angular/router';
import { FunctionalArea } from '../models/functionalarea';
import { IndustryModel } from '../models/industrymodel';
import { UserModel } from '../models/usermodel';
import { ApicallService } from '../service/apicall.service';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SeoService } from '../seo.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  public userdata = new UserModel();
  lstFunctionaArea : FunctionalArea[];
  lstIndustry : IndustryModel[];
  domainname:any; 
  showSignupForm =true;
  showreConfirmRegisterStep1=false;
  showreConfirmRegisterStep2=false;
  siteKey: string;
  supportemail:string;
  captchaValidated:boolean=false;
  clicked = false;
  finalredirectURL:string="";
  public redirectURL:string;
  constructor(private titleService: Title, private service:ApicallService,private router:Router, private _renderer:Renderer2, private _http:HttpClient,private toastr:ToastrService,private route:ActivatedRoute,private seo:SeoService) {      
     this.siteKey = environment.siteKey;
     this.supportemail=environment.helpemail;
  }
  
  public setTitle( newTitle: string) {
  this.titleService.setTitle( newTitle );
  if(this.redirectURL!== undefined)
  {
    if(this.redirectURL!='')
      {
        if(this.redirectURL.indexOf("casestudy/")!=-1){
          
          window.location.href='/login;redirectUri='+this.redirectURL.replace("/","||");  
        }
        else{
          window.location.href='/login;redirectUri='+this.redirectURL;
        }
      }
      else{
        this.router.navigate(['/login']);
      }
    }
    else{
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.redirectURL="";
    this.route.queryParams
      .subscribe(params => {
        this.redirectURL=params.redirectUri; 
      }
    ); 
    this.userdata.functionalAreaId="";
    this.userdata.industryId="";
    this.domainname = this.service.getHostname(); 
    sessionStorage.clear();
    this.service.getFunctionalAreas().subscribe(data =>{ this.lstFunctionaArea=data; });    
    this.service.getIndustries().subscribe(data =>{ this.lstIndustry=data; });  

    let script=this._renderer.createElement('script');
    script.defer=true;
    script.async=true;
    script.src="https://www.google.com/recaptcha/api.js";
    this._renderer.appendChild(document.body,script);
    this.finalredirectURL=this.service.getHostNameWithProtocol()+"/home";
    if(this.redirectURL!== undefined)
    {
      
      if(this.redirectURL!='')
      {
        if(this.redirectURL.indexOf("casestudy/")!=-1){
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL.replace("/","||"); 
        }
        else if(this.redirectURL.indexOf("casestudysearch;")!=-1)
        {
          var casestudysearchurl=window.location.href;
          var splittedurl = casestudysearchurl.split("casestudysearch"); 
          
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL.replace(/;/g,"%3B");//+decodeURIComponent(splittedurl[splittedurl.length-1]);
          
        }
        else
        {
          this.finalredirectURL=this.service.getHostNameWithProtocol()+"/"+this.redirectURL;
        }
        
      }
    }
  }

  showAccountConfirmModal = false;  
  toggleAccountConfirmModal(){
    this.showAccountConfirmModal = !this.showAccountConfirmModal;
    }  

  submitRegisterForm(registerForm: NgForm){
    if(registerForm.valid){
      this.showAccountConfirmModal = !this.showAccountConfirmModal;
    }
  }

  confirm(IsAcrossSite)
  {    
    this.userdata.IsAcrossSite=IsAcrossSite;
    this.userdata.ReturnUrl="/";    
    this.userdata.Domain=this.domainname; 
    this.userdata.Country=null;
    if(this.userdata.functionalAreaId?.toLowerCase()=="other")
    this.userdata.functionalAreaId=null;
    if(this.userdata.industryId?.toLowerCase()=="other")
    this.userdata.industryId=null;
    this.userdata.IsFromMicrosite=true;
    //call service for registration     
    this.service.registerUser(this.userdata)
    .subscribe(data =>
      {         
        this.userdata.userId= data.body;
        // if success then go to reregister part 
        this.showAccountConfirmModal = !this.showAccountConfirmModal; //  close popup      
        //show step1 part
        this.showSignupForm=false;
        this.showreConfirmRegisterStep1=true;
        this.showreConfirmRegisterStep2=false;
      },
      error => { 
          this.clicked=false;
          //this.showAccountConfirmModal = !this.showAccountConfirmModal;  
          this.toastr.error("Registration failed. The username/business email you have selected is already in use. Please use your Corporate/Business email. ","",{timeOut:3000,progressBar:true});          
        });   
  } 

  showotherFunctionalArea = false;  
  onOptionsSelected(event){
    let value = event.target.value;
    if(value.toLowerCase( )=="other")
    {
      this.showotherFunctionalArea = true;
    }
   else
   {
    this.showotherFunctionalArea = false;
   }
  }

  showotherIndustry = false;  
  onOptionsIndustrySelected(event){
    let value = event.target.value;
    if(value.toLowerCase( )=="other")
    {
      this.showotherIndustry = true;
    }
   else
   {
    this.showotherIndustry = false;
   }
  } 

  showConfirmRegisterStep1()
  {    
    this.showSignupForm=false;
    this.showreConfirmRegisterStep1=false;
    this.showreConfirmRegisterStep2=true;
  }
  sendReRegistration()
  {   
    this.userdata.Domain= this.domainname;
    this.service.reRegisterUser(this.userdata)
    .subscribe(data =>
      { 
        this.showSignupForm=false;
        this.showreConfirmRegisterStep1=true;
        this.showreConfirmRegisterStep2=false;
        this.toastr.success("Success","",{timeOut:3000,progressBar:true}); 
      },
      error => {     
        this.toastr.error("Failed","",{timeOut:3000,progressBar:true});              
        });   
  }

  cancelReRegistration()
  {    
    this.showSignupForm=false;
    this.showreConfirmRegisterStep1=true;
    this.showreConfirmRegisterStep2=false;
  }
  public resolved(captchaResponse): void {
    if(captchaResponse!=undefined)
      this.captchaValidated=true;  
    this._http.post('',{token:captchaResponse}).subscribe(res=>{     
    })
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaValidated=false;       
  }   
public newwindow;
  loginWithLinkedIn()
  {  
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=560,height=400,left=-1000,top=-1000`;    
    window.location.href = environment.linkedInOAuthUrl+"authorization?response_type=code&client_id="+environment.linkedInClientId+"&redirect_uri="+encodeURIComponent(environment.apiURL+"/api/linkedIn/SignInViaLinkedIn")+"&state="+this.finalredirectURL+"&scope=r_liteprofile,w_member_social,r_emailaddress";
  }
}
