<app-header></app-header>
<div class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-24 relative setbg6 min-h-screen"
    *ngIf="showSignupForm">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="flex flex-wrap z-10 max-w-screen-2xl mx-auto px-10 lg:px-3">
        <div class="w-full md:w-1/2 pr-3 lg:pr-20 flex flex-wrap content-center text-white order-last md:order-first">
            <h2 class="text-3xl uppercase font-semibold mb-6">Join Now for Access to Solutions Across All Industries
            </h2>
            <p class="text-lg mb-6">Sign up to get access to the additional benefits of a myContentreads page. It’s free
                and only takes 20 seconds to get started. </p>
            <div class="md:flex items-start">
                <div class="w-20">
                    <img src="/assets/images/organized.svg" class="filter brightness-200 contrast-200" alt="" width="64"
                        height="64">
                </div>
                <div class="text-white md:pl-5 mt-3 md:mt-0">
                    <h3 class="uppercase font-bold text-xl">organized</h3>
                    <p class="mb-6 text-lg break-all">Easily save, organize and retrieve all the industry solutions you have
                        discovered on {{domainname}}.</p>
                </div>
            </div>
            <div class="md:flex items-start">
                <div class="w-20">
                    <img src="/assets/images/permanent-2.svg" class="filter brightness-200 contrast-200" alt=""
                        width="64" height="64">
                </div>
                <div class="text-white md:pl-5 mt-3 md:mt-0">
                    <h3 class="uppercase font-bold text-xl">permanent</h3>
                    <p class="mb-6 text-lg">myContentreads is your free and permanent reference bank of solutions you
                        curate and will use throughout your career.</p>
                </div>
            </div>
            <div class="md:flex items-start">
                <div class="w-20">
                    <img src="/assets/images/dicovery-platform.svg" class="filter brightness-200 contrast-200" alt=""
                        width="64" height="64">
                </div>
                <div class="text-white md:pl-5 mt-3 md:mt-0">
                    <h3 class="uppercase font-bold text-xl">collaborative</h3>
                    <p class="mb-10 md:mb-0 text-lg break-all">Easily exchange industry solution ideas with other
                        {{domainname}} members. Share, collaborate and learn from other industry
                        experts.</p>
                </div>
            </div>
        </div>
        <div class="w-full md:w-1/2 divide-y divide-gray-200 text-white flex flex-wrap content-center mb-10 md:mb-0">
            <div
                class="text-base leading-6 text-brand-gray sm:text-md sm:leading-7 bg-gray-900 bg-opacity-50 p-4 lg:p-14 rounded w-full">
                <div class="text-center mb-10">
                    <button (click)="loginWithLinkedIn()"
                        class="bg-blue-600 hover:bg-blue-700 text-white font-semibold p-3 rounded  w-full justify-start">

                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                            class="fill-current w-5 h-5 mr-2 mt-1 absolute">
                            <path fill="currentColor"
                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                class=""></path>
                        </svg>
                        <span class="pl-2 uppercase text-sm md:text-xl">Join with LinkedIn</span>
                    </button> </div>
                <form #registerForm="ngForm">
                    <div class="form-group mb-4">
                        <!-- <label for="Username" class=" block ">Username:</label> -->
                        <input type="text" #username="ngModel" required maxlength="33" minlength="5"
                            class=" border w-full h-5 px-3 py-5 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Username" name="username" [(ngModel)]="userdata.username"
                            [ngClass]="{'red-border-class': username.errors && (username.dirty || username.touched || registerForm.submitted )}">
                        <div *ngIf="username.errors && (username.dirty || username.touched || registerForm.submitted)">
                            <div [hidden]="!username.errors.required" class="text-red-500">
                                Please enter Username.
                            </div>
                            <div *ngIf="username.errors?.minlength || username.errors?.maxlength" class="text-red-500">
                                User name length must be between 5 and 33 characters.</div>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <!-- <label for="password" class=" block ">Password:</label> -->
                        <input type="password" #password="ngModel" required minlength="6" maxlength="100"
                            class=" border w-full h-5 px-3 py-5  hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Password" name="password" [(ngModel)]="userdata.password"
                            [ngClass]="{'red-border-class': password.errors && (password.dirty || password.touched || registerForm.submitted)}">
                        <div *ngIf="password.errors && (password.dirty || password.touched || registerForm.submitted)">
                            <div [hidden]="!password.errors.required" class="text-red-500">
                                Please enter Password.
                            </div>
                            <div *ngIf="password.errors?.minlength || password.errors?.maxlength" class="text-red-500">
                                Password length must be between 6 and 100 characters.</div>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <!-- <label for="Email" class=" block ">Business E-mail:</label> -->
                        <input type="email" #email="ngModel" required
                            class=" border w-full h-5 px-3 py-5 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Corporate/Business email" name="email" [(ngModel)]="userdata.email"
                            email="true"
                            [ngClass]="{'red-border-class': email.errors && (email.dirty || email.touched || registerForm.submitted)}"
                            [email]="true">
                        <div *ngIf="email.errors && (email.dirty || email.touched || registerForm.submitted)">
                            <div [hidden]="!email.errors.required" class="text-red-500">
                                Please enter Email.
                            </div>
                            <div *ngIf="email.errors && (!email.errors.required)" class="text-red-500">
                                Please use your Corporate/Business email.
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <!-- <label for="Area" class=" block ">Functional Area:</label>   -->
                        <div class="relative">
                            <select #functionalAreaId="ngModel" (change)='onOptionsSelected($event)' required
                                aria-selected="true"
                                class="appearance-none border w-full px-4 py-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                                name="functionalAreaId" [(ngModel)]="userdata.functionalAreaId"
                                [ngClass]="{'red-border-class': functionalAreaId.errors && (functionalAreaId.dirty || functionalAreaId.touched || registerForm.submitted)}">
                                <option value="" class="text-brand-gray">Choose your Functional Area</option>
                                <option *ngFor="let areas of lstFunctionaArea" [value]="areas.value"> {{areas.text}}
                                </option>
                            </select>
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-brand-gray">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20">
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                        <div
                            *ngIf="functionalAreaId.errors && (functionalAreaId.dirty || functionalAreaId.touched || registerForm.submitted)">
                            <div [hidden]="!functionalAreaId.errors.required" class="text-red-500">
                                Please select Functional Area.
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showotherFunctionalArea==true" class="form-group mb-4">
                        <!-- <label for="Username" class=" block ">Other functional area:</label> -->
                        <input type="text" #otherFunctionalArea="ngModel" required
                            class=" border w-full h-5 px-3 py-5 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Your functional area" name="otherFunctionalArea"
                            [(ngModel)]="userdata.otherFunctionalArea"
                            [ngClass]="{'red-border-class': otherFunctionalArea.errors && (otherFunctionalArea.dirty || otherFunctionalArea.touched || registerForm.submitted)}">
                        <div
                            *ngIf="otherFunctionalArea.errors && (otherFunctionalArea.dirty || otherFunctionalArea.touched || registerForm.submitted)">
                            <div [hidden]="!otherFunctionalArea.errors.required" class="text-red-500">
                                Please enter Functional Area description.
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <!-- <label for="Industry" class=" block ">Industry:</label>    -->
                        <div class="relative">
                            <select #industryId="ngModel" (change)='onOptionsIndustrySelected($event)' required
                                aria-selected="true"
                                class="appearance-none border w-full px-4 py-2 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                                name="industryId" [(ngModel)]="userdata.industryId"
                                [ngClass]="{'red-border-class': industryId.errors && (industryId.dirty || industryId.touched || registerForm.submitted)}">
                                <option value="" class="text-brand-gray" selected>Choose your Industry</option>
                                <option *ngFor="let industry of lstIndustry" [value]="industry.value"> {{industry.text}}
                                </option>
                            </select>
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-brand-gray">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20">
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                        <div
                            *ngIf="industryId.errors && (industryId.dirty || industryId.touched || registerForm.submitted)">
                            <div [hidden]="!industryId.errors.required" class="text-red-500">
                                Please select Industry.
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showotherIndustry==true" class="form-group mb-4">
                        <!-- <label for="Username" class=" block ">Other industry:</label> -->
                        <input type="text" #otherIndustry="ngModel" required
                            class=" border w-full h-5 px-3 py-5 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded"
                            placeholder="Your industry" name="otherIndustry" [(ngModel)]="userdata.otherIndustry"
                            [ngClass]="{'red-border-class': otherIndustry.errors && (otherIndustry.dirty || otherIndustry.touched || registerForm.submitted)}">
                        <div
                            *ngIf="otherIndustry.errors && (otherIndustry.dirty || otherIndustry.touched || registerForm.submitted)">
                            <div [hidden]="!otherIndustry.errors.required" class="text-red-500">
                                Please enter Industry description.
                            </div>
                        </div>
                    </div>
                    <label class="flex justify-start items-start mb-4">
                        <div
                            class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-brand-green mt-2">
                            <input type="checkbox" class="opacity-0 absolute" #ageRef="ngModel" required name="chkAge"
                                ngModel>
                            <svg class="fill-current hidden w-4 h-4 text-brand-green pointer-events-none"
                                viewBox="0 0 20 20">
                                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" /></svg>
                        </div>
                        <div class="select-none text-brand-gray font-semibold">By clicking this checkbox you confirm
                            that you are 18 years
                            of age or older. </div>
                    </label>
                    <div *ngIf="ageRef.errors && (ageRef.dirty || ageRef.touched || registerForm.submitted)">
                        <div [hidden]="!ageRef.errors.required" class="text-red-500">
                            Sorry, Please confirm that you are 18 years of age or older.
                        </div>
                    </div>
                    <label class="flex justify-start items-start mb-4">
                        <div
                            class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-brand-green mt-2">
                            <input type="checkbox" class="opacity-0 absolute" #termsconditionRef="ngModel" required
                                name="chkTC" ngModel>
                            <svg class="fill-current hidden w-4 h-4 text-brand-green pointer-events-none"
                                viewBox="0 0 20 20">
                                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" /></svg>
                        </div>
                        <div class="select-none text-brand-gray font-semibold">By clicking this checkbox you agree to
                            Contentread's <a href="/legalterms" class="hover:underline px-2" target="_blank">Terms of
                                Service</a>&<a href="/privacy" class="hover:underline px-2" target="_blank">Privacy
                                Policy.</a> </div>
                    </label>
                    <div
                        *ngIf="termsconditionRef.errors && (termsconditionRef.dirty || termsconditionRef.touched || registerForm.submitted)">
                        <div [hidden]="!termsconditionRef.errors.required" class="text-red-500">
                            Sorry, Please select Terms of Service & Privacy Policy.
                        </div>
                    </div>

                    <div class="flex justify-center recaptcha">
                        <re-captcha (resolved)="resolved($event)" (error)="onError($event)" siteKey="{{siteKey}}">
                        </re-captcha>
                    </div>
                    <div class="text-center mb-4">
                        <button (click)="submitRegisterForm(registerForm)"
                            class="bg-brand-green  rounded text-white mt-5 p-3 block w-full uppercase font-semibold"><span
                                class="text-xl"> Send</span></button>
                    </div>
                </form>
                <div class="">
                    <a (click)="setTitle('Sign in')"
                        class="inline-flex items-center hover:text-underline text-white font-semibold cursor-pointer"
                        routerLinkActive="active"><span>Log In </span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="filter invert ml-1" width="15" height="15"
                            viewBox="0 0 24 24">
                            <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" /></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- second -->
<div *ngIf="showreConfirmRegisterStep1" id="step1"
    class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-24 relative setbg6">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="space-y-4 text-center sm:max-w-xl sm:mx-auto relative bg-white shadow-3xl sm:rounded p-10 sm:p-20">
        <h1 class=" text-3xl text-center mb-3">Sign up</h1>
        <div class="text-xl">Please check your email for the confirmation link.</div>
        <div class="text-md">If you did not yet receive your email confirmation, please check your spam, junk or
            secondary inboxes. Alternatively, click on "Resend confirmation" so that we can provide a new confirmation.
        </div>
        <div class="cursor-pointer bg-green-500  rounded-lg text-white mt-5 p-3" (click)="showConfirmRegisterStep1()">
            Resend Confirmation</div>
        <div>
            Still can't find it? Let us know by</div>
        <div><a class="text-brand-green" href="mailto:{{supportemail}}">{{supportemail}}</a></div>
    </div>
</div>
<!-- third step-->
<div *ngIf="showreConfirmRegisterStep2"
    class="bg-cover bg-center bg-brand-gray grid w-full mx-auto py-24 relative setbg6">
    <div class="absolute w-full h-full blue-background-overlay inset-0 opacity-80"></div>
    <div class="relative py-3 sm:max-w-xl sm:mx-auto">
        <div class="relative bg-white shadow-3xl sm:rounded p-10 sm:p-20">
            <div class="max-w-md mx-auto w-96">
                <div class="divide-y divide-gray-200">
                    <div class="text-base leading-6 text-brand-gray sm:text-md sm:leading-7">
                        <h1 class=" text-3xl text-center ">Sign up</h1>
                        <form #reRegisterForm="ngForm">
                            <div class="form-group">
                                <label for="resendEmail" class=" block ">Reenter E-mail:</label>
                                <input type="resendEmail" #resendEmail="ngModel" required
                                    class="border w-full h-5 px-3 py-5 mt-1 mb-3 hover:outline-none focus:outline-none focus:ring-1 focus:border-brand-green rounded clsresendemail"
                                    placeholder="Your business e-mail" name="resendEmail"
                                    [(ngModel)]="userdata.resendEmail"
                                    [ngClass]="{'red-border-class': resendEmail.errors && (resendEmail.dirty || resendEmail.touched)}">
                                <div *ngIf="resendEmail.errors && (resendEmail.dirty || resendEmail.touched)">
                                    <div [hidden]="!resendEmail.errors.required" class="text-red-500">
                                        Please enter valid Email.
                                    </div>
                                    <div *ngIf="!resendEmail.errors.required">
                                        <div [hidden]="!resendEmail.invalid && !resendEmail.errors.required"
                                            class="text-red-500">
                                            Invalid email.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between mt-5">
                                <button
                                    class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center"
                                    (click)="cancelReRegistration()">Cancel</button>
                                <button [disabled]="!reRegisterForm.valid" (click)="sendReRegistration()"
                                    class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                    [ngClass]="{'disabledbtn': !reRegisterForm.valid }">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- account-confirm-modal -->
<div *ngIf="showAccountConfirmModal"
    class="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div class="bg-white rounded-xl shadow-2xl p-6 w-10/12 md:w-6/12 lg:w-4/12 mx-10">
        <div class="">
            <!--Title-->
            <div class="flex items-center w-full">
                <p class="text-gray-900 font-medium text-lg">Confirm</p>
                <div class="ml-auto fill-current text-brand-gray w-6 h-6 cursor-pointer"
                    (click)="toggleAccountConfirmModal()">
                    <svg class="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                    </svg>
                </div>
            </div>
            <!--Body-->
            <div class="my-5 pt-4">
                <form method="POST" id="add_caretaker_form" class="w-full">
                    <div class="">
                        <p class=" text-gray-500">Do you want to create an account on Contentreads?</p>
                    </div>
                </form>
            </div>
            <!--Footer-->
            <div class="flex justify-between mt-5">
                <button [disabled]="clicked" type="button" (click)="confirm(false);clicked = true;"
                    class="bg-gray-200 hover:bg-gray-300 text-brand-gray font-bold py-2 px-4 rounded inline-flex items-center">
                    No</button>
                <button [disabled]="clicked" (click)="confirm(true);clicked = true;"
                    class="bg-brand-green hover:bg-brand-gray text-white font-bold py-2 px-4 rounded inline-flex items-center">
                    Yes</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showAccountConfirmModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>

<app-pagefooter></app-pagefooter>