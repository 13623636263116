import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule, Title,Meta  }  from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; 
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule,routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { WINDOW_PROVIDERS } from './window.providers';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeaderComponent } from './shared/header/header.component';
import { PagefooterComponent } from './shared/pagefooter/pagefooter.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { JoinpageComponent } from './include/joinpage/joinpage.component';
import { AccountsettingsComponent } from './accountsettings/accountsettings.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MycontentreeComponent } from './mycontentree/mycontentree.component';
import { MysolutionsetComponent } from './mysolutionset/mysolutionset.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule,ToastContainerModule  } from 'ngx-toastr';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { ErrorComponent } from './error/error.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { DownloadfileComponent } from './downloadfile/downloadfile.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LegaltermsComponent } from './legalterms/legalterms.component';
import { ConfirmEqualValidatorDirective } from './shared/confirm-equal-validator.directive';
import { SuggestcasestudyComponent } from './suggestcasestudy/suggestcasestudy.component';
import { DndDirective } from './direcitves/dnd.directive';
import { ProgressComponent } from './progress/progress.component';
import { GdprDataProtectionPolicyComponent } from './gdpr-data-protection-policy/gdpr-data-protection-policy.component';
import { BlogComponent } from './blog/blog.component';
import { BlogpostComponent } from './blogpost/blogpost.component';
import { CcpaPrivacyPolicyComplaintComponent } from './ccpa-privacy-policy-complaint/ccpa-privacy-policy-complaint.component';


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    HeaderComponent,        
    PagefooterComponent,    
    JoinpageComponent,
    AccountsettingsComponent,
    MycontentreeComponent,
    MysolutionsetComponent,
    VerifyemailComponent,
    ErrorComponent,
    ResetpasswordComponent,
    PagenotfoundComponent,
    DownloadfileComponent,
    PrivacyComponent,
    LegaltermsComponent,
    ConfirmEqualValidatorDirective,
    SuggestcasestudyComponent,
    DndDirective,
    ProgressComponent,
    GdprDataProtectionPolicyComponent,
    BlogComponent,
    BlogpostComponent,
    CcpaPrivacyPolicyComplaintComponent     
    
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    HttpClientModule,
    SlickCarouselModule,
    NgxPaginationModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({timeOut:3000, progressAnimation:'increasing', preventDuplicates:true, progressBar:true}),
    ToastContainerModule 
  ],
  providers: [WINDOW_PROVIDERS,Meta], 
  bootstrap: [AppComponent]
})
export class AppModule { }
