import { Component, OnInit,Inject } from '@angular/core';
import { Title,DomSanitizer  }     from '@angular/platform-browser'; 
import { Router } from '@angular/router';
import { ApicallService } from 'src/app/service/apicall.service';
import { DOCUMENT } from '@angular/common';
import { SeoService } from 'src/app/seo.service';
import { NgForm } from '@angular/forms';
import {searchparams } from '../../common/searchparams';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {    
  public searchParamModel = new searchparams();
  microSiteContent:any;
  domainname:any;
  contentMicrosites:any;
  isLoggedIn:any;
  showSearch:any;
  public faviconUrl;
  public mobileMenuOpen;
  seoDescription:any;
  seokeyword:any;
  showblog=false;
  cssUrl: string;
  public constructor(@Inject(DOCUMENT) private _document: HTMLDocument,private titleService: Title,private service:ApicallService,private router:Router,private seo:SeoService,public sanitizer:DomSanitizer ) { }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  public redirectSignIn( newTitle: string) {
    this.titleService.setTitle( newTitle );
    if(location.pathname.substr(1)!='login' && location.pathname.substr(1)!='register'){
      window.location.href='/login;redirectUri='+location.pathname.substr(1).replace(/\//g,"||");
    }
    else{
      window.location.href='/login;redirectUri=home';
    }
  }
  public redirectSignUp( newTitle: string) {
    this.titleService.setTitle( newTitle );
    if(location.pathname.substr(1)!='login' && location.pathname.substr(1)!='register'){
      window.location.href='/register?redirectUri='+location.pathname.substr(1).replace(/\//g,"||");
    }
    else{
      window.location.href='/register?redirectUri=home';
    }
  }
  ngOnInit(): void {
    this.domainname = this.service.getHostname();      
    this.showSearch =true;
    this.cssUrl=environment.apiURL+"/microsites/"+this.domainname.split('.',1)[0]+"/files/css/style.css";
    //if session expired
    if(sessionStorage.getItem('sessionuserId')=="undefined")
      this.isLoggedIn=false;
    else
    {
      if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;
    }

    if(location.pathname.substr(1)=='home' || location.pathname.substr(1).indexOf("home;")>-1 || location.pathname.substr(1).includes('casestudysearch')){
      this.showSearch =false;
    }
    // for getting site logo     
    this.service.getAllContentMicrosites(this.domainname)
    .subscribe(data=>{
      if(data[0].showBlog==1){
        this.showblog=true;
      }
      else{
        if(location.pathname.indexOf("blog/post")!=-1)
        {
          window.location.href="/**";
        }
      }
      this.contentMicrosites=data;       
      this.faviconUrl = data[0].siteFavIcon;
      this._document.getElementById('appFavicon')?.setAttribute('href', this.faviconUrl);
      sessionStorage.setItem('sitelogo',data[0].siteLogo);
  });   
  this.service.getMicrositeContents("HomePage",this.domainname).subscribe(data=>{
    this.microSiteContent=data; 
  });
  }


   onSignout()
   {
    //for clearing session    
    sessionStorage.removeItem('userEmail')
    sessionStorage.removeItem('sessionuserId')
    sessionStorage.removeItem('userToken')  
    sessionStorage.clear();
    this.router.navigate(['/login'])    
   } 

   SearchCase(filterForm:NgForm) {
    this.searchParamModel.additionalQuery='';
    this.searchParamModel.domain=this.domainname;
    this.searchParamModel.page=1;
    this.searchParamModel.getSponsored=true;    
    this.router.navigate(['/casestudysearch',{additionalQuery:this.searchParamModel.additionalQuery,queryString:this.searchParamModel.queryString,queryType:this.searchParamModel.queryType, getSponsored:this.searchParamModel.getSponsored,page: this.searchParamModel.page, domain: this.searchParamModel.domain}])          
  
   } 
}
