import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private titleService: Title,private router:Router,) { }

  ngOnInit(): void {
  }

  gotoHome()
  { 
    this.titleService.setTitle('Home');       
    this.router.navigate(['/home']) ;
  }
}
