import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { analyticsWriteModel } from 'src/app/common/searchparams';
import { ApicallService } from '../service/apicall.service';
import { SeoService } from '../seo.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SuggestCaseStudyModel } from '../models/suggestcasestudymodel';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-suggestcasestudy',
  templateUrl: './suggestcasestudy.component.html',
  styleUrls: ['./suggestcasestudy.component.scss']
})
export class SuggestcasestudyComponent implements OnInit {
  public apiurl;
  public filedata = new SuggestCaseStudyModel();
  domainname: any;
  userIp: any;
  hostname: string;
  selectedFile: File;
  htmlVariable: string = "";
  public isLoggedIn;
  public analyticsWriteParam = new analyticsWriteModel();
  public componentname: string;  
  public isChecked: any;
  public isValid: boolean = true;  
  public isSuccess: boolean = false;  
  public isFailed: boolean = false;  
  public fileCount:number=0;
  constructor(private seo: SeoService, private service: ApicallService, private http: HttpClient, private toastr:ToastrService,private router:Router) { }

  ngOnInit(): void {
    this.apiurl = environment.apiURL;
    this.domainname = this.service.getHostname();
    this.hostname = this.domainname;
    if(sessionStorage.getItem('sessionuserId')!=null)
      this.isLoggedIn=true;
      else
      this.isLoggedIn=false;  
      if(!this.isLoggedIn) {
        this.router.navigate(['/login;redirectUri='+location.pathname.substr(1)]);
      }
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  processedfiles: any[]=[];
  public onIsAgreedChanged(value: boolean) {
    this.isChecked = value;    
  }


  readFile(fileEvent: any) {
    this.isValid = true;     
    for (const item of fileEvent.target.files) {
      if (item.type.includes('pdf') === false) {
        this.isValid = false;
        break;
      }
    }
    this.prepareFilesList(fileEvent.target.files);    
  }

  onUpload(index) {
    this.selectedFile = this.files[index]; 
   
    return new Promise<void>((resolve) => {
      if(this.isLoggedIn )
      {

        if(this.processedfiles.indexOf(this.selectedFile)!=this.files.indexOf(this.selectedFile)){
        
        this.processedfiles.push(this.selectedFile);
        this.fileCount++;
        
        this.service.suggestCaseStudy(this.selectedFile, this.domainname).then(data =>
        {
          if(data)
          {
            this.isSuccess = true;            
            this.toastr.success("Thanks for uploading. You'll get an email after administrator approves the uploaded Solutions.","",{timeOut:3000,progressBar:true}); 
           
            this.htmlVariable =this.htmlVariable+'<div class="flex justify-center">'+this.selectedFile.name+'<span><img  src="../../assets/images/check.png" id="success" width="25px"  class="pl-1" style="display: initial;"></span></div>'; 
       
            
          }
          else{
            this.htmlVariable =this.htmlVariable+'<div class="flex justify-center">'+this.selectedFile.name+'<span><img class="pl-1" src="../../assets/images/cross.png" id="failure" width="25px"  style="display: initial;"></span></div>'; 
            $('.progress_bar').hide(1000);   
            this.isFailed = true;            
            this.toastr.error("Failed","",{timeOut:3000,progressBar:true});   
           
          }
          
          $('#'+index).hide(1000); 
          
        },
        error => { 
          $('.progress_bar').hide(1000);   this.htmlVariable =this.htmlVariable+'<div class="flex justify-center">'+this.selectedFile.name+'<span><img class="pl-1" src="../../assets/images/cross.png" id="failure" width="25px"  style="display: initial;"></span></div>'; //this.htmlVariable =this.selectedFile.name; 
          this.isFailed = true;          
          this.toastr.error("Failed","",{timeOut:3000,progressBar:true});             
          });
        }
      }
      else{
        this.toggleJoinContentreeModal();
      }
      resolve();
    });

  }


  showJoinContentreeModal = false;
  toggleJoinContentreeModal(){
    this.showJoinContentreeModal = !this.showJoinContentreeModal;
  }
  openRegister()
  {
    window.location.href='/register?redirectUri='+location.pathname.substr(1).replace("/","||") ; 
  }



  /**
   * on file drop handler
   */
  onFileDropped($event) {     
    this.isValid = true;  
    for (const item of $event) {
      if (item.type.includes('pdf') === false) {
        this.isValid = false;
        break;
      }
    }
    if(this.isValid){
      this.prepareFilesList($event);        
    }    
    
  }
  onBrowseFile(){    
    this.isSuccess = false;
    this.isFailed = false;    
    this.htmlVariable = "";
    $('.progress_bar').show();
    
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {   
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        
        this.onUpload(index);
        const progressInterval = setInterval(() => {
          if (typeof(this.files[index])!="undefined" && this.files[index].progress === 100) {
            this.files[index].progress=0;
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);            
            
          } else {
            if(typeof(this.files[index])!="undefined" ){
            this.files[index].progress += 5;  
            }          
          }
        }, 500);
        
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress=0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);   
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


}
