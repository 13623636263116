import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-legalterms',
  templateUrl: './legalterms.component.html',
  styleUrls: ['./legalterms.component.scss']
})
export class LegaltermsComponent implements OnInit {
  supportemail:string;
  constructor() { }

  ngOnInit(): void {
    this.supportemail=environment.helpemail;
  }

}
